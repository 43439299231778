import { JsonRpcProvider, FallbackProvider } from '@ethersproject/providers'
import { Address } from '../model'
import { BigNumber, Contract, FixedNumber } from 'ethers'
import { useEffect, useMemo } from 'react'
import { CallObject, MultichainCalls, useMultichainCalls } from './helper-hooks'
import TermVaultStrategyABI from '../../abi/vault/TermVaultStrategy.json'
import { TermVaultStrategy } from '../../abi-generated'
import GnosisSafeABI from '../../abi-external/GnosisSafe.json'
import { GnosisSafe } from '../../abi-generated'
import { captureException } from '@sentry/react'
import { bigToFixedNumber } from '../../helpers/conversions'
import { divide } from '../../helpers/math'

export function useVault(
  account: Address | undefined,
  strategyAddress: Address,
  chainId: string,
  proposerAddress: Address | undefined,
  assetDecimals: number | undefined,
  provider: JsonRpcProvider | FallbackProvider | undefined
):
  | {
      // current weighted average length
      weightedAverageLength: number | null | undefined
      // liquid reserve ratio
      liquidReserveRatio: FixedNumber | null | undefined
      // current price per share
      currentPricePerShare: FixedNumber | null | undefined
      // conversion ratio
      convertToAssetsRatio: FixedNumber | null | undefined
      // total asset value
      totalAssetValue: FixedNumber | null | undefined
      // performance fee
      performanceFee: FixedNumber | null | undefined
      // is vault paused
      isPaused: boolean | null | undefined
      // is vault shutdown
      isShutdown: boolean | null | undefined
      // is connected account the vault governor
      isGovernor: boolean | null | undefined
      // incoming management address
      pendingManagementAddress: Address | null | undefined
      // available deposit limit
      availableDepositLimit: FixedNumber | null | undefined
      // available withdraw limit
      availableWithdrawLimit: FixedNumber | null | undefined
      // total liquid balance
      totalLiquidBalance: FixedNumber | null | undefined
      // repo token holdings
      repoTokenHoldings:
        | { repoToken: Address; presentValue: BigNumber | undefined }[]
        | null
        | undefined
    }
  | null
  | undefined {
  const strategyContract = useMemo(() => {
    return new Contract(
      strategyAddress,
      TermVaultStrategyABI,
      provider
    ) as TermVaultStrategy
  }, [strategyAddress, provider])

  const proposerContract = useMemo(() => {
    return proposerAddress
      ? (new Contract(proposerAddress, GnosisSafeABI, provider) as GnosisSafe)
      : undefined
  }, [proposerAddress, provider])

  // Read contract calls

  // #region

  const vaultContractCalls: MultichainCalls = useMemo(() => {
    const calls = [
      {
        contract: strategyContract,
        method: 'pricePerShare',
        args: [],
      },
      {
        contract: strategyContract,
        method: 'totalAssetValue',
        args: [],
      },
      {
        contract: strategyContract,
        method: 'liquidReserveRatio',
        args: [],
      },
      {
        contract: strategyContract,
        method: 'simulateTransaction',
        args: ['0x0000000000000000000000000000000000000000', 0],
      },
      {
        contract: strategyContract,
        method: 'performanceFee',
        args: [],
      },
      {
        contract: strategyContract,
        method: 'paused',
        args: [],
      },
      {
        contract: strategyContract,
        method: 'isShutdown',
        args: [],
      },
      {
        contract: strategyContract,
        method: 'totalLiquidBalance',
        args: [],
      },
      {
        contract: strategyContract,
        method: 'repoTokenHoldings',
        args: [],
      },
      {
        contract: strategyContract,
        method: 'convertToAssets',
        args: [BigNumber.from(1000000)],
      },
      {
        contract: strategyContract,
        method: 'pendingManagement',
        args: [],
      },
    ]

    if (account) {
      calls.push(
        {
          contract: strategyContract,
          method: 'availableDepositLimit',
          args: [account],
        },
        {
          contract: strategyContract,
          method: 'availableWithdrawLimit',
          args: [account],
        }
      )
    }

    return {
      [chainId]: calls,
    }
  }, [account, chainId, strategyContract])

  const proposerContractCalls: MultichainCalls = useMemo(() => {
    let calls = [] as CallObject[]
    if (account && proposerContract) {
      calls = [
        {
          contract: proposerContract,
          method: 'isOwner',
          args: [account],
        },
      ]
    }
    return {
      [chainId]: calls,
    }
  }, [account, chainId, proposerContract])

  const blockchainResponse = useMultichainCalls(vaultContractCalls)

  const blockchainResults = useMemo(() => {
    const result: { [chainId: string]: any[] } = {}
    Object.entries(blockchainResponse).forEach(
      ([chainId, resultsAndErrors]) => {
        result[chainId] = resultsAndErrors
          .filter((resultOrError) => !resultOrError?.error)
          .map((resultOrError) => resultOrError?.value)
      }
    )
    return result
  }, [blockchainResponse])

  const blockchainErrors = useMemo(() => {
    const errors: { [chainId: string]: any[] } = {}

    if (blockchainResponse) {
      Object.entries(blockchainResponse).forEach(
        ([chainId, resultsAndErrors]) => {
          const chainErrors = resultsAndErrors
            .filter((resultOrError) => !!resultOrError?.error)
            .map((resultOrError) => resultOrError?.error)

          if (chainErrors.length > 0) {
            errors[chainId] = chainErrors
          }
        }
      )
    }

    return errors
  }, [blockchainResponse])

  useEffect(() => {
    if (blockchainErrors) {
      Object.entries(blockchainErrors).forEach(([chainId, chainError]) => {
        console.error(`Error on ${chainId}: ${chainError}`)
        captureException(chainError)
      })
    }
  }, [blockchainErrors])

  const currentPricePerShare = useMemo(() => {
    if (!blockchainResults) return null

    let value: FixedNumber | undefined

    Object.entries(blockchainResults).forEach(([_, values]) => {
      const pricePerShareValue = values.slice(0, 1)?.[0]
      if (strategyAddress && pricePerShareValue) {
        const decimals = assetDecimals ?? 18
        value = bigToFixedNumber(pricePerShareValue?.[0], decimals)
      }
    })
    return value
  }, [blockchainResults, strategyAddress, assetDecimals])

  const totalAssetValue = useMemo(() => {
    if (!blockchainResults) return null

    let value: FixedNumber | undefined

    Object.entries(blockchainResults).forEach(([_, values]) => {
      const totalAssetValueValue = values.slice(1, 2)?.[0]

      if (strategyAddress && totalAssetValueValue) {
        const decimals = assetDecimals ?? 18
        value = bigToFixedNumber(totalAssetValueValue?.[0], decimals)
      }
    })

    return value
  }, [blockchainResults, strategyAddress, assetDecimals])

  const liquidReserveRatio = useMemo(() => {
    if (!blockchainResults) return null

    let value: FixedNumber | undefined

    Object.entries(blockchainResults).forEach(([_, values]) => {
      const liquidReserveRatioValue = values.slice(2, 3)?.[0]

      if (strategyAddress && liquidReserveRatioValue) {
        value = bigToFixedNumber(liquidReserveRatioValue?.[0], 18)
      }
    })

    return value
  }, [blockchainResults, strategyAddress])

  const weightedAverageLength = useMemo(() => {
    if (!blockchainResults) return null

    let value: number | undefined

    Object.entries(blockchainResults).forEach(([_, values]) => {
      const weightedAverageLengthValue = values.slice(3, 4)?.[0]
      if (strategyAddress && weightedAverageLengthValue) {
        const secondsInDayBN = BigNumber.from(86400)
        const weightedAverageLengthValueBN = BigNumber.from(
          weightedAverageLengthValue?.[0]
        )

        const days = weightedAverageLengthValueBN.div(secondsInDayBN)
        // Get the remainder and calculate the fractional part
        const remainder = weightedAverageLengthValueBN.mod(secondsInDayBN)
        // multiply by 100 to get fraction in decimals
        const fractional = remainder
          .mul(BigNumber.from(100))
          .div(secondsInDayBN)
          .toNumber()
        const formattedWAL = parseFloat(
          `${days.toString()}.${fractional.toString().padStart(2, '0')}`
        )

        value = formattedWAL
      }
    })

    return value
  }, [blockchainResults, strategyAddress])

  const performanceFee = useMemo(() => {
    if (!blockchainResults) return null

    let value: FixedNumber | undefined

    Object.entries(blockchainResults).forEach(([_, values]) => {
      const performanceFeeValue = values.slice(4, 5)?.[0]

      if (strategyAddress && performanceFeeValue) {
        // this value comes from yearn and is in basis points
        value = performanceFeeValue?.[0]
          ? FixedNumber.fromString(
              (performanceFeeValue?.[0] / 100).toString(),
              'fixed128x18'
            )
          : FixedNumber.fromString('0', 'fixed128x18')
      }
    })

    return value
  }, [blockchainResults, strategyAddress])

  const isPaused = useMemo(() => {
    if (!blockchainResults) return null

    let value: boolean | undefined

    Object.entries(blockchainResults).forEach(([_, values]) => {
      const isPausedValue = values.slice(5, 6)?.[0]

      if (strategyAddress && isPausedValue) {
        value = isPausedValue?.[0]
      }
    })

    return value
  }, [blockchainResults, strategyAddress])

  const isShutdown = useMemo(() => {
    if (!blockchainResults) return null

    let value: boolean | undefined

    Object.entries(blockchainResults).forEach(([_, values]) => {
      const isShutdownValue = values.slice(6, 7)?.[0]

      if (strategyAddress && isShutdownValue) {
        value = isShutdownValue?.[0]
      }
    })

    return value
  }, [blockchainResults, strategyAddress])

  const totalLiquidBalance = useMemo(() => {
    if (!blockchainResults) return null

    let value: FixedNumber | undefined

    Object.entries(blockchainResults).forEach(([_, values]) => {
      const totalLiquidBalanceValue = values.slice(7, 8)?.[0]

      if (strategyAddress && totalLiquidBalanceValue) {
        const decimals = assetDecimals ?? 18
        value = bigToFixedNumber(totalLiquidBalanceValue?.[0], decimals)
      }
    })

    return value
  }, [blockchainResults, strategyAddress, assetDecimals])

  const repoTokenHoldings = useMemo(() => {
    if (!blockchainResults) return null

    const valuesArray: {
      repoToken: Address
      presentValue: BigNumber | undefined
    }[] = []

    Object.entries(blockchainResults).forEach(([_, values]) => {
      const repoTokenHoldingsValues = values.slice(8, 9)?.[0]?.flat() // Likely an array of arrays

      if (strategyAddress && Array.isArray(repoTokenHoldingsValues)) {
        repoTokenHoldingsValues.forEach((repoTokenAddress) => {
          valuesArray.push({
            repoToken: repoTokenAddress, // Extract the address as a string
            presentValue: undefined,
          })
        })
      }
    })

    return valuesArray
  }, [blockchainResults, strategyAddress])

  const convertToAssetsRatio = useMemo(() => {
    if (!blockchainResults) return null

    let value: FixedNumber | undefined

    Object.entries(blockchainResults).forEach(([_, values]) => {
      const convertToAssetsValue = values.slice(9, 10)?.[0]

      if (strategyAddress && convertToAssetsValue && assetDecimals) {
        value = divide(
          bigToFixedNumber(convertToAssetsValue?.[0], 18),
          bigToFixedNumber(BigNumber.from(1000000), 18)
        )
      }
    })

    return value
  }, [blockchainResults, strategyAddress, assetDecimals])

  const pendingManagementAddress = useMemo(() => {
    if (!blockchainResults) return null

    let value: Address | undefined

    Object.entries(blockchainResults).forEach(([_, values]) => {
      const pendingManagementAddressValue = values.slice(10, 11)?.[0]

      if (strategyAddress && pendingManagementAddressValue) {
        value = pendingManagementAddressValue?.[0]
      }
    })

    return value
  }, [blockchainResults, strategyAddress])

  const availableDepositLimit = useMemo(() => {
    if (!blockchainResults) return null

    let value: FixedNumber | undefined

    Object.entries(blockchainResults).forEach(([_, values]) => {
      const availableDepositLimitValue = values.slice(11, 12)?.[0]

      if (strategyAddress && availableDepositLimitValue && assetDecimals) {
        value = bigToFixedNumber(availableDepositLimitValue?.[0], assetDecimals)
      }
    })

    return value
  }, [blockchainResults, strategyAddress, assetDecimals])

  const availableWithdrawLimit = useMemo(() => {
    if (!blockchainResults) return null

    let value: FixedNumber | undefined

    Object.entries(blockchainResults).forEach(([_, values]) => {
      const availableWithdrawLimitValue = values.slice(12, 13)?.[0]

      if (strategyAddress && availableWithdrawLimitValue && assetDecimals) {
        value = bigToFixedNumber(
          availableWithdrawLimitValue?.[0],
          assetDecimals
        )
      }
    })

    return value
  }, [blockchainResults, strategyAddress, assetDecimals])

  // #endregion

  // Safe proposer owner calls

  // #region
  const isProposerResponse = useMultichainCalls(proposerContractCalls)

  const isProposerResults = useMemo(() => {
    const result: { [chainId: string]: any[] } = {}
    Object.entries(isProposerResponse).forEach(
      ([chainId, resultsAndErrors]) => {
        result[chainId] = resultsAndErrors
          .filter((resultOrError) => !resultOrError?.error)
          .map((resultOrError) => resultOrError?.value)
      }
    )
    return result
  }, [isProposerResponse])

  const isProposerErrors = useMemo(() => {
    const errors: { [chainId: string]: any[] } = {}

    if (isProposerResponse) {
      Object.entries(isProposerResponse).forEach(
        ([chainId, resultsAndErrors]) => {
          const chainErrors = resultsAndErrors
            .filter((resultOrError) => !!resultOrError?.error)
            .map((resultOrError) => resultOrError?.error)

          if (chainErrors.length > 0) {
            errors[chainId] = chainErrors
          }
        }
      )
    }

    return errors
  }, [isProposerResponse])

  useEffect(() => {
    if (isProposerErrors) {
      Object.entries(isProposerErrors).forEach(([chainId, chainError]) => {
        console.error(`Error on ${chainId}: ${chainError}`)
        captureException(chainError)
      })
    }
  }, [isProposerErrors])

  const isProposer = useMemo(() => {
    if (!isProposerResults) return null

    let value: boolean | undefined

    Object.entries(isProposerResults).forEach(([_, values]) => {
      const isProposerValue = values.slice(0, 1)?.[0]

      if (strategyAddress && isProposerValue) {
        value = isProposerValue?.[0]
      }
    })
    return value
  }, [isProposerResults, strategyAddress])

  // #endregion

  const vaultParams = useMemo(() => {
    if (
      weightedAverageLength !== undefined &&
      liquidReserveRatio !== undefined &&
      totalAssetValue !== undefined &&
      currentPricePerShare !== undefined &&
      convertToAssetsRatio !== undefined &&
      totalAssetValue !== undefined &&
      performanceFee !== undefined &&
      totalLiquidBalance !== undefined &&
      repoTokenHoldings !== undefined &&
      isPaused !== undefined &&
      isShutdown !== undefined
      // isProposer !== undefined
    ) {
      return {
        weightedAverageLength,
        liquidReserveRatio,
        currentPricePerShare,
        convertToAssetsRatio,
        totalAssetValue,
        performanceFee,
        totalLiquidBalance,
        repoTokenHoldings,
        isPaused,
        isShutdown,
        isGovernor: isProposer, // if the connected wallet is proposer owner then grant it governor access
        pendingManagementAddress,
        availableDepositLimit,
        availableWithdrawLimit,
      }
    } else {
      return undefined
    }
  }, [
    currentPricePerShare,
    convertToAssetsRatio,
    totalAssetValue,
    liquidReserveRatio,
    weightedAverageLength,
    performanceFee,
    totalLiquidBalance,
    repoTokenHoldings,
    isPaused,
    isShutdown,
    isProposer,
    pendingManagementAddress,
    availableDepositLimit,
    availableWithdrawLimit,
  ])

  return vaultParams
}
