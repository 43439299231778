import { Box, Button, Flex, Grid, HStack, Text } from '@chakra-ui/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { FixedNumber } from 'ethers'
import { isEqual } from 'lodash'
import { useEffect, useState } from 'react'
import VaultCard from '../VaultCard'
import VaultCardLoading from '../VaultCard/VaultCardLoading'
import VaultsTableEmpty from '../VaultsTableEmpty'
import { VaultData } from '../../../../models/vaults'

const sortButtons: { id: keyof VaultData; label: string }[] = [
  {
    id: 'currentYield',
    label: 'Current Yield',
  },
  {
    id: 'vaultName',
    label: 'Vault',
  },
  {
    id: 'collateralCurrencies',
    label: 'Collateral',
  },
  {
    id: 'curator',
    label: 'Curator',
  },
  {
    id: 'totalAssetValue',
    label: 'Total Asset Value',
  },
]

export const ColumnSortButton = ({
  label,
  isActive,
  onClick,
}: {
  label: string
  isActive: boolean
  onClick: () => void
}) => (
  <Button variant="unstyled" height="fit-content" onClick={onClick}>
    <HStack>
      <Text as="span" variant="body-sm/medium" color="grey.6">
        {label}
      </Text>
      <Box
        marginInlineStart={0}
        color={isActive ? 'black' : 'gray.5'}
        as="span"
        transform={isActive ? 'rotate(0deg)' : 'rotate(180deg)'}
        transition="transform 0.2s"
      >
        <FontAwesomeIcon
          icon={['far', 'chevron-up']}
          role="button"
          width="12px"
        />
      </Box>
    </HStack>
  </Button>
)

export default function VaultsTable({
  isDataLoading,
  selectedDepositToken,
  vaultsData,
  selectedChainId,
  onViewVault,
}: {
  isDataLoading?: boolean
  selectedDepositToken: string
  vaultsData: VaultData[]
  selectedChainId?: string
  onViewVault: (vaultAddress: string, chainId: string) => void
}) {
  const [activeSort, setActiveSort] = useState('')
  const [filteredAndSortedData, setFilteredAndSortedData] = useState(vaultsData)
  const [sortConfig, setSortConfig] = useState<{
    key: keyof VaultData
    direction: string
  }>({
    key: 'currentYield',
    direction: 'DESC',
  })

  useEffect(() => {
    let updatedData: VaultData[] = vaultsData

    // Step 1: Filter data based on selectedChainId
    if (selectedChainId) {
      updatedData = updatedData.filter(
        (vault: VaultData) => vault.chainId === selectedChainId
      )
    }

    // Step 2: Filter data based on selectedDepositToken
    if (selectedDepositToken && selectedDepositToken !== 'all') {
      updatedData = updatedData.filter(
        (vault: VaultData) =>
          vault.purchaseCurrency.symbol === selectedDepositToken
      )
    }

    // Step 3: Sort data based on current sortConfig
    if (sortConfig.key) {
      const direction = sortConfig.direction === 'ASC' ? 1 : -1
      updatedData = [...updatedData].sort((a, b) => {
        let valueA: any = a[sortConfig.key]
        let valueB: any = b[sortConfig.key]

        if (valueA instanceof FixedNumber) {
          valueA = parseFloat(valueA.toString())
        }
        if (valueB instanceof FixedNumber) {
          valueB = parseFloat(valueB.toString())
        }

        if (typeof valueA === 'string' && typeof valueB === 'string') {
          return valueA.localeCompare(valueB) * direction
        }

        if (typeof valueA === 'string') {
          valueA = parseFloat(valueA.replace(/,/g, ''))
        }
        if (typeof valueB === 'string') {
          valueB = parseFloat(valueB.replace(/,/g, ''))
        }

        if (valueA === undefined && valueB === undefined) return 0
        if (valueA === undefined) return direction
        if (valueB === undefined) return -direction

        return valueA < valueB ? -direction : valueA > valueB ? direction : 0
      })
    }

    // Step 4: Update state if necessary to prevent redundant re-renders
    if (updatedData?.length && !isEqual(updatedData, filteredAndSortedData)) {
      setFilteredAndSortedData(updatedData)
    }
  }, [
    filteredAndSortedData,
    vaultsData,
    selectedChainId,
    sortConfig,
    selectedDepositToken,
  ])

  const handleSort = (key: keyof VaultData) => {
    const direction =
      sortConfig.key === key && sortConfig.direction === 'ASC' ? 'DESC' : 'ASC'
    setSortConfig({ key, direction })
    setActiveSort(activeSort === key ? '' : key)
  }

  return (
    <>
      <Flex direction="column">
        {/* Table Header */}
        <Flex px={3} align="center">
          <Flex
            gap={{
              base: 7,
              '1xl': 12,
            }}
            mr="auto"
            pr={5}
            py={4}
            alignItems="center"
          >
            <Box h="100%" w="130px" pr={2}>
              {sortButtons
                .filter(({ id }) => id === 'currentYield')
                .map(({ id, label }) => (
                  <ColumnSortButton
                    key={id}
                    label={label}
                    isActive={id === activeSort}
                    onClick={() => handleSort(id)}
                  />
                ))}
            </Box>
            <Grid
              gridTemplateColumns={{
                base: '200px 75px 135px',
              }}
              gap={{
                base: '38px',
                '1xl': '89px',
                '3xl': 36,
              }}
            >
              {sortButtons
                .filter(
                  ({ id }) => id !== 'currentYield' && id !== 'totalAssetValue'
                )
                .map(({ id, label }) => (
                  <ColumnSortButton
                    key={id}
                    label={label}
                    isActive={id === activeSort}
                    onClick={() => handleSort(id)}
                  />
                ))}
            </Grid>
          </Flex>
          <Flex
            flexDirection="column"
            align="start"
            verticalAlign="top"
            w="290px"
            alignItems="start"
          >
            {sortButtons
              .filter(({ id }) => id === 'totalAssetValue')
              .map(({ id, label }) => (
                <ColumnSortButton
                  key={id}
                  label={label}
                  isActive={id === activeSort}
                  onClick={() => handleSort(id)}
                />
              ))}
          </Flex>
        </Flex>
        {/* Table Body */}
        <Flex direction="column" gap={5}>
          {isDataLoading ? (
            Array.from({ length: 5 }).map((_, idx) => (
              <VaultCardLoading key={idx} />
            ))
          ) : filteredAndSortedData.length > 0 ? (
            filteredAndSortedData.map((vault: VaultData) => (
              <VaultCard
                key={vault.address}
                vault={vault}
                onViewVault={onViewVault}
              />
            ))
          ) : (
            <Flex pt={10}>
              <VaultsTableEmpty />
            </Flex>
          )}
        </Flex>
      </Flex>
    </>
  )
}
