import { HStack, Link, Text } from '@chakra-ui/react'
import ReferralCode from '../components/elements/ReferralCode'
import { socialLinks } from '../socials'

export const isRejectedTxn = (error: any) =>
  error === 'Transaction dismissed' ||
  (error as Error)?.message?.includes('code=ACTION_REJECTED') ||
  (error as Error)?.message?.includes('user rejected transaction')

export type BidOrOffer = 'bid' | 'offer'
type DepositOrWithdraw = 'deposit' | 'withdraw'
type ElectOrEdit = 'elect' | 'edit'
type FullOrPartial = 'full' | 'partial'

// capitalise helper
export const capitalise = (s: string) => s.charAt(0).toUpperCase() + s.slice(1)

// will convert a BidOrOffer into its plural form (add an s to the end)
export const makePlural = (t: string, isPlural: boolean) =>
  `${t}${isPlural ? 's' : ''}`

export const termToastMessages = {
  approving: {
    success: (token: string) => ({
      title: `${token} approved`,
    }),
    pending: (token: string) => ({
      title: 'Approving token',
      children: `${token} is being approved so that our smart contracts can access it for the following step.`,
    }),
    failure: {
      title: 'Unable to approve token',
    },
  },
  bidOrOffer: {
    success: (t: BidOrOffer, numTenders: number, diffFormatted: string) => {
      const isPlural = numTenders > 1
      const formattedText = makePlural(t, isPlural)
      return {
        title: `Your ${formattedText} ${
          isPlural ? 'have' : 'has'
        } been submitted`,
        children: `Your ${formattedText} will be evaluated when the auction closes in ${diffFormatted}.`,
      }
    },
    pending: (t: BidOrOffer, numTenders: number) => ({
      title: `Submitting your ${makePlural(t, numTenders > 1)}`,
    }),
    failure: (t: BidOrOffer, numTenders: number) => {
      const isPlural = numTenders > 1
      const formattedText = makePlural(t, isPlural)
      return {
        title: `Unable to submit your ${formattedText}`,
        specificRequestItem: t,
        requestItemIsPlural: isPlural,
      }
    },
  },
  editBidOrOffer: {
    success: (t: BidOrOffer, numTenders: number) => ({
      title: `${makePlural(capitalise(t), numTenders > 1)} edited`,
    }),
    pending: (t: BidOrOffer, numTenders: number) => ({
      title: `Editing your ${makePlural(t, numTenders > 1)}`,
    }),
    failure: (t: BidOrOffer, numTenders: number) => ({
      title: `Unable to edit your ${makePlural(t, numTenders > 1)}`,
    }),
  },
  deleteBidOrOffer: {
    success: (t: BidOrOffer, numTenders: number) => ({
      title: `${makePlural(capitalise(t), numTenders > 1)} deleted`,
      children:
        t === 'bid'
          ? `The associated collateral has been returned to your wallet.`
          : undefined,
    }),
    pending: (t: BidOrOffer, numTenders: number) => {
      const formattedText = `${t}${numTenders > 1 ? 's' : ''}`
      return {
        title: `Deleting your ${formattedText}`,
      }
    },
    failure: (t: BidOrOffer, numTenders: number) => {
      const formattedText = `${t}${numTenders > 1 ? 's' : ''}`
      return {
        title: `Unable to delete your ${formattedText}`,
      }
    },
  },
  depositOrWithdraw: {
    success: (t: DepositOrWithdraw) => ({
      title: `Collateral ${t === 'withdraw' ? 'withdrawn' : 'deposited'}`,
    }),
    pending: (t: DepositOrWithdraw) => ({
      title: `${capitalise(t)}ing collateral`,
    }),
    failure: (t: DepositOrWithdraw) => ({
      title: `Unable to ${t} collateral`,
    }),
  },
  collapse: {
    success: {
      title: 'Borrow collapsed',
      children:
        'The associated collateral remains in excess and can now be withdrawn.',
    },
    pending: {
      title: 'Collapsing your borrow',
    },
    failure: {
      title: 'Unable to collapse your borrow',
    },
  },
  electOrEditRollover: {
    success: (t: ElectOrEdit) => ({
      title: `Rollover ${t}ed`,
      children:
        'You will be notified if your rollover was successful when your initial loan matures.',
    }),
    pending: (t: ElectOrEdit) => ({
      title: `${capitalise(t)}ing rollover`,
    }),
    failure: (t: ElectOrEdit) => ({
      title: `Unable to ${t} your rollover`,
    }),
  },
  cancelRollover: {
    success: (dateOfMaturity?: string) => ({
      title: 'Rollover cancelled',
      // TODO [Aidan] pass dateOfMaturity as props to match kitty's design
      children: dateOfMaturity
        ? `Ensure you repay your outstanding debt at maturity on ${dateOfMaturity}.`
        : 'You must now repay your debt',
    }),
    pending: {
      title: 'Cancelling your elected rollover',
    },
    failure: {
      title: 'Unable to cancel your rollover',
    },
  },
  repayLoan: {
    success: (formattedLoan: string, t: FullOrPartial) => ({
      title: `Loan ${formattedLoan} ${
        t === 'full' ? 'repaid in full' : 'partially repaid'
      }`,
      children:
        t === 'full'
          ? 'The associated collateral has been returned to your wallet.'
          : 'The associated collateral has been moved from required to excess. ',
    }),
    pending: {
      title: 'Repaying loan',
    },
    failure: (formattedLoan: string) => ({
      title: `Unable to repay loan ${formattedLoan}`,
    }),
  },
  redeemLoan: {
    success: (formattedLoan: string) => ({
      title: `Loan ${formattedLoan} redeemed`,
      children:
        'The Repo Tokens have been burned, and your outstanding credit has been sent to your wallet. ',
    }),
    pending: {
      title: 'Redeeming loan',
    },
    failure: {
      title: 'Unable to redeem loan',
      specificRequestItem: 'redemption',
    },
    paymentsDue: {
      title: 'Unable to redeem loan',
      children:
        'This loan still has outstanding payments due. Please try again later.',
    },
  },
  referral: {
    added: (address: string) => ({
      title: 'Referral address activated',
      duration: null, // prevent auto close
      children: (
        <>
          <HStack>
            <HStack pr="16px" w="full">
              <Text as="span" color="gray.6" variant="body-sm/medium">
                Referral Address
              </Text>
            </HStack>
            <HStack as="span" ml="auto">
              <ReferralCode
                referralCode={address}
                color="gray.6"
                variant="body-sm/medium"
                hideTooltip={true}
              />
            </HStack>
          </HStack>
        </>
        // Learn about the program in the{' '}
        // <Link href={'https://docs.term.finance/referrals'} isExternal>
        // Referral Program Overview
        // </Link>
      ),
    }),
    deleted: {
      title: 'Referral address removed',
    },
    addFailure: {
      title: `Unable to activate referral address`,
    },
    deleteFailure: {
      title: `Unable to remove referral address`,
    },
  },
  faucetClaim: {
    success: (token: string) => ({
      title: `${token} claim success`,
      children: `You now have ${token} test tokens that can be used in the upcoming auctions.`,
    }),
    pending: {
      title: 'Claiming tokens',
    },
    failure: (token: string) => ({
      title: `Unable to claim ${token} test tokens`,
      specificRequestItem: 'transaction',
    }),
    exceededLimit: {
      title: 'Claim limit exceeded',
      children: (
        <>
          You are unable to claim more tokens. You can request a claim limit
          increase on{' '}
          <Link href={socialLinks.discord} isExternal>
            Discord
          </Link>
          .
        </>
      ),
    },
  },
  wrapping: {
    success: (unwrappedTokenSymbol: string) => ({
      title: `${unwrappedTokenSymbol} wrapped`,
    }),
    pending: (unwrappedTokenSymbol: string, wrappedTokenSymbol: string) => ({
      title: `Wrapping ${unwrappedTokenSymbol}`,
      children: `Your ${unwrappedTokenSymbol} is being converted into ${wrappedTokenSymbol} so that it can be used by our smart contracts.`,
    }),
    failure: (unwrappedTokenSymbol: string) => ({
      title: `Wrapping ${unwrappedTokenSymbol} failed`,
      specificRequestItem: `request to wrap ${unwrappedTokenSymbol}`,
    }),
  },
  lending: {
    success: (amount: string | React.ReactNode) => ({
      title: 'Tokens Purchased',
      children: (
        <Text as="span">
          <Text as="span" variant="body-sm/bold">
            {amount}
          </Text>{' '}
          tokens are now available to view in your portfolio.
        </Text>
      ),
    }),
    pending: () => ({
      title: 'Earning',
    }),
    failure: {
      title: 'Unable to complete.',
      children:
        'Your request was not submitted to the network. Please try again.',
    },
  },
  listing: {
    success: (
      amount: string | React.ReactNode,
      listingContractUrl: string,
      purchaseTokenSymbol: string
    ) => ({
      title: `Tokens listed`,
      children: (
        <Text as="span">
          <Text as="span" variant="body-sm/bold">
            {amount} tokens
          </Text>{' '}
          are now stored in the{' '}
          <Link href={listingContractUrl} isExternal variant="inline">
            listing contract
          </Link>
          .
        </Text>
      ),
    }),
    pending: () => ({
      title: 'Listing tokens',
    }),
    failure: {
      title: 'Unable to list your tokens',
      children:
        'Your request was not submitted to the network. Please try again later.',
    },
  },
  delisting: {
    success: (
      amount: string | React.ReactNode,
      showRedeemMessage: boolean
    ) => ({
      title: 'Listing cancelled',
      children: (
        <>
          <Text as="span" variant="body-sm/normal">
            <Text as="span" variant="body-sm/bold">
              {amount} tokens
            </Text>{' '}
            transferred back to your wallet.
          </Text>
          {showRedeemMessage && (
            <Text as="span" variant="body-sm/normal">
              {' '}
              You may now redeem your remaining tokens.
            </Text>
          )}
        </>
      ),
    }),
    pending: () => ({
      title: 'Cancelling listing',
    }),
    failure: {
      title: 'Unable to cancel',
      children:
        'Your request was not submitted to the network. Please try again later.',
    },
  },
  withdrawProceeds: {
    success: (amount: string | React.ReactNode) => ({
      title: 'Tokens withdrawn',
      children: (
        <Text as="span" variant="body-sm/normal">
          <Text as="span" variant="body-sm/bold">
            {amount}
          </Text>{' '}
          sent to your wallet.
        </Text>
      ),
    }),
    pending: () => ({
      title: 'Withdrawing tokens',
    }),
    failure: {
      title: 'Unable to withdraw your tokens',
      children:
        'Your request was not submitted to the network. Please try again later.',
    },
  },
  vaultDeposit: {
    success: (symbol: string, value: string) => {
      return {
        title: `Successfully deposited into vault`,
        children: `${value} ${symbol} tokens received`,
      }
    },
    pending: () => ({
      title: `Depositing into vault`,
      children: '',
    }),
    failure: () => {
      return {
        title: 'Unable to deposit into vault',
        children: '',
      }
    },
  },
  vaultWithdraw: {
    success: (symbol: string, value: string) => {
      return {
        title: `Successful withdrawal from vault`,
        children: `${value} ${symbol} tokens received`,
      }
    },
    pending: () => ({
      title: `Withdrawing from vault`,
      children: '',
    }),
    failure: () => {
      return {
        title: 'Unable to withdraw from vault',
        children: '',
      }
    },
  },
  editVaultStat: {
    success: (stat: string, value: string) => {
      return {
        title: `Update requested for ${stat}`,
        children: `Proposed new value is ${value}`,
      }
    },
    pending: () => ({
      title: `Proposing vault parameter edit`,
      children: '',
    }),
    failure: () => {
      return {
        title: 'Unable to edit vault statistic',
        children: '',
      }
    },
  },
  setRepoTokenBlacklist: {
    success: (repoTokenAddress: string) => {
      return {
        title: 'Proposal submitted',
        children: `${repoTokenAddress} has been added to the blacklist.`,
      }
    },
    pending: () => ({
      title: `Proposing repo token blacklist`,
      children: '',
    }),
    failure: (repoTokenAddress: string) => {
      return {
        title: 'Unable to blacklist repo token',
        children: `${repoTokenAddress}`,
      }
    },
  },
  editCollateral: {
    success: (symbol: string, value: string) => {
      return {
        title: 'Proposal submitted',
        children: `Proposed new minimum maintenance ratio for ${symbol} is ${value}.`,
      }
    },
    pending: () => ({
      title: `Proposing collateral parameter update`,
      children: '',
    }),
    failure: () => {
      return {
        title: 'Unable to edit collateral parameter',
        children: '',
      }
    },
  },
  editDiscountRateMarkup: {
    success: (value: string) => {
      return {
        title: 'Proposal submitted',
        children: `Proposed new discount rate markup is ${value}.`,
      }
    },
    pending: () => ({
      title: `Proposing discount rate markup update`,
      children: '',
    }),
    failure: () => {
      return {
        title: 'Unable to update discount rate markup',
        children: '',
      }
    },
  },
  removeCollateral: {
    success: (symbol: string) => {
      return {
        title: 'Proposal submitted',
        children: `${symbol} will be removed as collateral.`,
      }
    },
    pending: (symbol: string) => ({
      title: `Proposing to remove ${symbol} as collateral`,
      children: '',
    }),
    failure: (symbol: string) => {
      return {
        title: `Unable to remove ${symbol} as collateral`,
        children: '',
      }
    },
  },
  setDiscountRateAdapter: {
    success: (value: string) => {
      return {
        title: 'Proposal submitted',
        children: `${value} proposed to be the new discount rate adapter.`,
      }
    },
    pending: () => ({
      title: 'Proposing discount rate adapter update',
      children: '',
    }),
    failure: () => {
      return {
        title: 'Unable to update discount rate adapter',
        children: '',
      }
    },
  },
  setTermController: {
    success: (value: string) => {
      return {
        title: 'Proposal submitted',
        children: `${value} proposed to be the new term controller.`,
      }
    },
    pending: () => ({
      title: 'Proposing term controller update',
      children: '',
    }),
    failure: () => {
      return {
        title: 'Unable to update term controller',
        children: '',
      }
    },
  },
  onPauseDeposit: {
    success: () => {
      return {
        title: 'Pause deposit proposal successfully submitted',
        children: '',
      }
    },
    pending: () => ({
      title: 'Proposing pause deposit',
      children: '',
    }),
    failure: () => {
      return {
        title: 'Unable to pause deposits',
        children: '',
      }
    },
  },
  onUnpauseDeposit: {
    success: () => {
      return {
        title: 'Unpause deposit proposal successfully submitted',
        children: '',
      }
    },
    pending: () => ({
      title: 'Proposing unpause deposit',
      children: '',
    }),
    failure: () => {
      return {
        title: 'Unable to unpause deposits',
        children: '',
      }
    },
  },
  onPauseStrategy: {
    success: () => {
      return {
        title: 'Pause strategy proposal successfully submitted',
        children: '',
      }
    },
    pending: () => ({
      title: 'Proposing to pause strategy',
      children: '',
    }),
    failure: () => {
      return {
        title: 'Unable to pause strategy',
        children: '',
      }
    },
  },
  onUnpauseStrategy: {
    success: () => {
      return {
        title: 'Unpause strategy proposal successfully submitted',
        children: '',
      }
    },
    pending: () => ({
      title: 'Proposing to unpause strategy',
      children: '',
    }),
    failure: () => {
      return {
        title: 'Unable to unpause strategy',
        children: '',
      }
    },
  },
  onAcceptManagement: {
    success: () => {
      return {
        title: 'Accepted vault management',
        children: '',
      }
    },
    pending: () => ({
      title: 'Accepting vault management',
      children: '',
    }),
    failure: () => {
      return {
        title: 'Unable to accept vault management',
        children: '',
      }
    },
  },
  onSetCollateralTokenParams: {
    success: () => {
      return {
        title: 'Set collateral token successfully submitted',
        children: '',
      }
    },
    pending: () => ({
      title: 'Setting collateral token',
      children: '',
    }),
    failure: () => {
      return {
        title: 'Unable to et collateral token',
        children: '',
      }
    },
  },
}
