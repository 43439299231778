import { JsonRpcProvider, FallbackProvider } from '@ethersproject/providers'
import { MultichainCalls, useMultichainCalls } from './helper-hooks'
import { Contract, FixedNumber } from 'ethers'
import { IERC20MetadataUpgradeable } from '../../abi-generated'
import { Address, Balance, Currency } from '../model'
import ERC20ABI from '../../abi/v0.2.4/IERC20MetadataUpgradeable.json'
import { useEffect, useMemo } from 'react'
import { captureException } from '@sentry/react'
import { CallResult } from '@usedapp/core'

export function useBalancesMulti(
  accounts: Address[] | undefined,
  addressesByChain:
    | { [chainId: string]: { [address: string]: Currency } }
    | undefined,
  provider: JsonRpcProvider | FallbackProvider | undefined
):
  | {
      [chainId: string]: { [account: Address]: { [address: Address]: Balance } }
    }
  | null
  | undefined {
  const contractCalls = useMemo(() => {
    const calls: MultichainCalls = {}

    if (addressesByChain && accounts && accounts.length > 0) {
      Object.entries(addressesByChain).forEach(([chainId, addresses]) => {
        const contracts = Object.keys(addresses).map(
          (address) =>
            new Contract(
              address,
              ERC20ABI,
              provider
            ) as IERC20MetadataUpgradeable
        )

        if (contracts) {
          const accountCalls = accounts.flatMap((account) =>
            contracts.map((contract) => ({
              contract,
              method: 'balanceOf',
              args: [account],
            }))
          )
          calls[chainId] = [...accountCalls]
        }
      })
    }
    return calls
  }, [addressesByChain, accounts, provider])

  const blockchainResults = useMultichainCalls(contractCalls)

  const balancesAndErrors = useMemo(() => {
    const results: Record<string, CallResult<Contract, string>[]> = {}

    Object.entries(blockchainResults).forEach(([chainId, chainResults]) => {
      results[chainId] = chainResults
    })

    return results
  }, [blockchainResults])

  const balances = useMemo(() => {
    const result: Record<
      string,
      { [account: Address]: { [address: string]: Balance } }
    > = {}

    if (balancesAndErrors && accounts && addressesByChain) {
      Object.entries(balancesAndErrors).forEach(([chainId, chainBalances]) => {
        if (!result[chainId]) result[chainId] = {}

        const chainAddresses = addressesByChain[chainId]
        const addressesList = Object.keys(chainAddresses || {})

        accounts.forEach((account, accountIndex) => {
          const accountResult: { [address: string]: Balance } = {}
          const accountBalances = chainBalances.slice(
            accountIndex * addressesList.length,
            (accountIndex + 1) * addressesList.length
          )

          addressesList.forEach((address, index) => {
            const balanceOrError = accountBalances[index]
            if (
              balanceOrError?.error === undefined &&
              balanceOrError?.value !== undefined
            ) {
              const currency = chainAddresses[address]
              accountResult[address] = {
                address,
                balance: FixedNumber.fromValue(
                  balanceOrError.value[0] || 0,
                  currency.decimals,
                  `fixed128x${currency.decimals}`
                ),
                decimals: currency.decimals,
                symbol: currency.symbol,
              }
            }
          })

          if (Object.keys(accountResult).length > 0) {
            result[chainId][account] = accountResult
          }
        })
      })
    }
    return Object.keys(result).length > 0 ? result : undefined
  }, [balancesAndErrors, accounts, addressesByChain])

  const blockchainErrors = useMemo(() => {
    const result: Record<string, any> = {}
    if (blockchainResults) {
      Object.entries(blockchainResults).forEach(([chainId, chainResults]) => {
        const chainErrors = chainResults
          .filter(
            (valueOrError) => valueOrError && valueOrError?.error !== undefined
          )
          .map((valueOrError) => valueOrError?.error)

        if (chainErrors.length > 0) {
          result[chainId] = chainErrors
        }
      })
    }
    return Object.keys(result).length > 0 ? result : undefined
  }, [blockchainResults])

  useEffect(() => {
    if (blockchainErrors) {
      Object.entries(blockchainErrors).forEach(([chainId, chainError]) => {
        console.error(`Error on ${chainId}: ${chainError}`)
        captureException(chainError)
      })
    }
  }, [blockchainErrors])

  return useMemo(() => {
    return balances || undefined // Return the structured balances
  }, [balances])
}
