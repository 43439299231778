import mrblock from '../assets/icons/kol/mrblock.png'

import unknown from '../assets/icons/error.svg'

const kolReferrerIcon: {
  [token: string]: string
} = {
  MRBLOCK: mrblock,

  unknown: unknown,
}

export default kolReferrerIcon
