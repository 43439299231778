import { Grid } from '@chakra-ui/react'
import { useMemo } from 'react'
import { isConnectedToMetaMask } from '../../../../helpers/eip6963'
import { components as ProfileSchema } from '../../../../models/profile-api'
import { EligibleRetweets, Socials } from '../../../../models/rewards'
import { RewardedTweet } from '../../../../socials'
import { CardStatus, PointsCardProps, PointsCategory } from '../../utils'
import PointsCard from '../PointsCard'

const POINTS_CARD_REWARD = {
  uniqueMarketParticipation: {
    target: 3,
    reward: '360,000 points',
  },
  consistentWeeklyParticipation: {
    reward: 'Up to 2.0x multiplier',
  },
  inviteUsers: {
    target: (noInvites: number) => {
      if (noInvites === 0) {
        return 5
      }

      const r = noInvites % 5
      if (r === 0) {
        return noInvites + 5
      }

      const d = Math.ceil(noInvites / 5)
      return d * 5
    },
    reward: '+10% points',
  },
} as const

type MetaPoints = { meta: { [x: string]: any } }

const sumPoints = (points: MetaPoints | undefined, keys: string[]) => {
  return keys
    .reduce((sum, key) => sum + (points?.meta?.[key] ?? 0), 0)
    .toString()
}

export default function PointsCardGrid({
  season,
  socialPoints,
  isSignedIn,
  hasProfile,
  accountExistsInProfile,
  totalNumberOfWeeks,
  preseasonPoints,
  auctionPoints,
  inviteCodes,
  completedRetweets,
  connectedSocials,
  onRetweet,
  onSignIn,
  onSignUp,
  onNavigateToAuctions,
  onNavigateToInvites,
  onLinkTwitter,
  onLinkDiscord,
  onLinkWallet,
}: {
  season: ProfileSchema['schemas']['Season'] | undefined
  socialPoints?: number
  isSignedIn: boolean
  hasProfile: boolean
  accountExistsInProfile: boolean
  totalNumberOfWeeks: number
  preseasonPoints?: ProfileSchema['schemas']['PreseasonPoints'] | undefined
  auctionPoints?: ProfileSchema['schemas']['AuctionPoints'] | undefined
  inviteCodes?: ProfileSchema['schemas']['InviteCode'][] | undefined
  completedRetweets: Partial<Record<RewardedTweet, boolean>>
  connectedSocials: Record<Socials, boolean> | undefined
  onRetweet: (tweet: EligibleRetweets) => void
  onSignIn: () => void
  onSignUp: () => void
  onNavigateToAuctions: () => void
  onNavigateToInvites: () => void
  onLinkTwitter?: (redirPath?: string) => void
  onLinkDiscord?: (redirPath?: string) => Promise<void>
  onLinkWallet: (isSkipWalletAnalyzerAnimation?: boolean) => void
}) {
  const pointsCards: PointsCardProps[] = useMemo(() => {
    const getPoints = (keys: string[]): string => {
      return Number(
        sumPoints(preseasonPoints as MetaPoints, keys)
      ).toLocaleString()
    }

    const beAnEarlySupportPoints = getPoints([
      'debank',
      'discord',
      'mainnet',
      'twitter',
      'supporter',
    ])
    const participateOnTestnet = getPoints(['testnet'])

    const noWeeksParticipated = auctionPoints?.meta?.noWeeksParticipated ?? 0
    const noUniqueMarkets = auctionPoints?.meta?.uniqueAuctionPairsCount ?? 0

    const retweetedVaultsAnnouncementPoints =
      season?.meta.uniqueTaskPoints?.hasRetweetedVaultsAnnouncement ?? 0

    const retweetLaunchAnnouncementPoints =
      season?.meta.uniqueTaskPoints?.hasRetweetedRewardsAnnouncement ?? 0

    const retweetWalletAnalyzerAnnouncementPoints =
      season?.meta.uniqueTaskPoints?.hasRetweetedWalletAnalyzerAnnouncement ?? 0

    const {
      consistentWeeklyParticipation,
      uniqueMarketParticipation,
      inviteUsers,
    } = POINTS_CARD_REWARD

    let noInvites =
      inviteCodes?.filter((code) => code?.meta?.progress?.signedUp)?.length ?? 0
    const invitesTarget = inviteUsers.target(noInvites)

    const retweetedLaunchAnnouncement =
      completedRetweets?.[RewardedTweet.launchTweet]
    const retweetedWalletAnalyzerAnnouncement =
      completedRetweets?.[RewardedTweet.walletAnalyzerTweet]
    const retweetedVaultsAnnouncement =
      completedRetweets?.[RewardedTweet.vaultAnnouncementTweet]
    const connectedTwitter = connectedSocials?.twitter
    const connectedDiscord = connectedSocials?.discord

    const isMetaMask = isConnectedToMetaMask()

    const pointsCards: PointsCardProps[] = [
      // purples
      {
        complete: retweetedWalletAnalyzerAnnouncement,
        category: 'other',
        points: `${retweetWalletAnalyzerAnnouncementPoints.toLocaleString()} points`,
        status: retweetedWalletAnalyzerAnnouncement ? 'complete' : 'incomplete',
        title: 'Retweet wallet analyzer announcement',
        requiresProfile: true,
        buttonText: !hasProfile
          ? 'Sign up'
          : hasProfile && !isSignedIn
            ? 'Sign in'
            : 'Retweet',
        buttonAction: () => {
          if (!hasProfile) {
            onSignUp()
          } else if (isSignedIn) {
            onRetweet('walletAnalyzer')
          } else {
            onSignIn()
          }
        },
        // isDisabled: (hasProfile && !isSignedIn) || !hasProfile,
      },
      {
        complete: true,
        category: 'other',
        points: `${beAnEarlySupportPoints} points`,
        status: 'complete',
        title: 'Participate in public beta',
      },
      ...(participateOnTestnet !== '0'
        ? [
            {
              complete: true,
              category: 'other' as PointsCategory,
              points: `${participateOnTestnet} points`,
              status: 'complete' as CardStatus,
              title: 'Participate on testnet',
            },
          ]
        : []),
      // {
      //   complete: retweetedLaunchAnnouncement,
      //   category: 'other',
      //   points: `${retweetLaunchAnnouncementPoints.toLocaleString()} points`,
      //   status: retweetedLaunchAnnouncement ? 'complete' : 'incomplete',
      //   title: 'Retweet launch announcement',
      //   requiresProfile: true,
      //   buttonText: !hasProfile
      //     ? 'Sign up'
      //     : hasProfile && !isSignedIn
      //       ? 'Sign in'
      //       : 'Retweet',
      //   buttonAction: () => {
      //     if (!hasProfile) {
      //       onSignUp()
      //     } else if (isSignedIn) {
      //       onRetweet('launchTweet')
      //     } else {
      //       onSignIn()
      //     }
      //   },
      //   // isDisabled: (hasProfile && !isSignedIn) || !hasProfile,
      // },
      {
        complete: retweetedVaultsAnnouncement,
        category: 'other',
        points: `${retweetedVaultsAnnouncementPoints.toLocaleString()} points`,
        status: retweetedVaultsAnnouncement ? 'complete' : 'incomplete',
        title: 'Retweet vaults announcement',
        requiresProfile: true,
        buttonText: !hasProfile
          ? 'Sign up'
          : hasProfile && !isSignedIn
            ? 'Sign in'
            : 'Retweet',
        buttonAction: () => {
          if (!hasProfile) {
            onSignUp()
          } else if (isSignedIn) {
            onRetweet('vaultsAnnouncement')
          } else {
            onSignIn()
          }
        },
        // isDisabled: (hasProfile && !isSignedIn) || !hasProfile,
      },
      {
        complete: connectedTwitter,
        category: 'other',
        points: `${(socialPoints ?? 0).toLocaleString()} points`,
        status: connectedTwitter ? 'complete' : 'incomplete',
        title: 'Link X account',
        requiresProfile: true,
        buttonText: hasProfile
          ? isSignedIn
            ? 'Link X'
            : 'Sign in'
          : 'Sign up',
        buttonAction: hasProfile
          ? isSignedIn
            ? onLinkTwitter
            : onSignIn
          : onSignUp,
        // isDisabled: (hasProfile && !isSignedIn) || !hasProfile,
      },
      {
        complete: connectedDiscord,
        category: 'other',
        points: `${(socialPoints ?? 0).toLocaleString()} points`,
        status: connectedDiscord ? 'complete' : 'incomplete',
        title: 'Link Discord account',
        requiresProfile: true,
        buttonText: hasProfile
          ? isSignedIn
            ? 'Link Discord'
            : 'Sign in'
          : 'Sign up',
        buttonAction: hasProfile
          ? isSignedIn
            ? onLinkDiscord
            : onSignIn
          : onSignUp,
        // isDisabled: (hasProfile && !isSignedIn) || !hasProfile,
      },
      // oranges
      {
        complete: false, // never complete
        category: 'invites',
        points: inviteUsers.reward,
        indicatorTotalSteps: invitesTarget,
        indicatorCurrentStep: noInvites,
        indicatorText:
          invitesTarget <= 15
            ? `${noInvites}/${invitesTarget} invited`
            : `${invitesTarget} invited`,
        title: 'Invite users',
        requiresProfile: true,
        buttonText: hasProfile ? 'Invite' : 'Sign up',
        buttonAction: hasProfile ? onNavigateToInvites : onSignUp,
      },
      // blues
      {
        complete: false,
        category: 'auctions',
        points: consistentWeeklyParticipation.reward,
        status: 'started',
        title: 'Earn multipliers for consistent participation',
        startedTotal: totalNumberOfWeeks,
        startedValue: noWeeksParticipated,
        multiplier: auctionPoints?.meta?.multiplierConsistency,
        buttonText: 'View auctions',
        buttonAction: onNavigateToAuctions,
      },
      {
        complete: noUniqueMarkets >= uniqueMarketParticipation.target,
        category: 'auctions',
        status:
          noUniqueMarkets >= uniqueMarketParticipation.target
            ? 'complete'
            : undefined,
        points: uniqueMarketParticipation.reward,
        indicatorTotalSteps: uniqueMarketParticipation.target,
        indicatorCurrentStep: noUniqueMarkets,
        indicatorText: `${noUniqueMarkets}/${uniqueMarketParticipation.target} participated`,
        title: `Participate in ${uniqueMarketParticipation.target} unique markets`,
        buttonText: 'View auctions',
        buttonAction: onNavigateToAuctions,
      },
      // others
      {
        complete: false, // never complete
        category: 'none',
        status: 'incomplete',
        points: 'Unlock more points',
        title: 'Link other active wallets',
        requiresProfile: true,
        isDisabled: isSignedIn && !isMetaMask && accountExistsInProfile,
        buttonText: hasProfile
          ? isSignedIn
            ? 'Link wallet'
            : 'Sign in'
          : 'Sign up',
        buttonAction: () => {
          return hasProfile
            ? isSignedIn
              ? onLinkWallet(false)
              : onSignIn()
            : onSignUp()
        },
        tooltipProps:
          isSignedIn && !isMetaMask
            ? {
                label:
                  'Switch wallets via your wallet provider to link a new wallet to your profile.',
              }
            : undefined,
      },
    ]

    return pointsCards
  }, [
    season?.meta.uniqueTaskPoints,
    preseasonPoints,
    socialPoints,
    auctionPoints,
    hasProfile,
    isSignedIn,
    accountExistsInProfile,
    inviteCodes,
    completedRetweets,
    connectedSocials,
    totalNumberOfWeeks,
    onNavigateToAuctions,
    onNavigateToInvites,
    onRetweet,
    onLinkTwitter,
    onLinkDiscord,
    onSignUp,
    onSignIn,
    onLinkWallet,
  ])

  return (
    <Grid templateColumns="repeat(5, 1fr)" gap={4}>
      {pointsCards
        .sort((a, b) => {
          // First, sort by complete status
          if (a.complete !== b.complete) {
            return a.complete ? 1 : -1
          }

          // If complete status is the same, sort by category
          const categoryOrder: Record<PointsCategory, number> = {
            other: 1, // purples
            invites: 2, // oranges
            auctions: 3, // blues
            none: 4, // not coloured
            lending: 5,
            '3xMultiplier': 6,
            unlock: 7,
          }

          return categoryOrder[a.category] - categoryOrder[b.category]
        })
        .map((data, index) => (
          <PointsCard
            key={`points-card-${index}`}
            {...data}
            hasProfile={hasProfile}
            isSignedIn={isSignedIn}
          />
        ))}
    </Grid>
  )
}
