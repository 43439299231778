import {
  Box,
  Button,
  Heading,
  Link,
  ListItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  OrderedList,
  Text,
} from '@chakra-ui/react'
import { HStack } from '../../../../components/elements/Stack'
import { useStorage } from '../../../../providers/storage'
import { Trans } from '@lingui/macro'
import { externalLinks } from '../../../../links'

export default function VaultRiskAcknowledgementModal({
  isOpen,
  account,
  onClose,
  onAcceptance,
}: {
  isOpen: boolean
  account: string | undefined
  onClose: () => void
  onAcceptance: () => void
}) {
  const { storage } = useStorage()

  const vaultRiskAcknowledgement = () => {
    if (account) {
      const vaultRiskAcceptedWalletKey = `vaultRiskAccepted-${account}`
      storage.setItem(vaultRiskAcceptedWalletKey, 'true')
      onAcceptance()
      onClose()
    } else {
      console.error('no account defined')
    }
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} variant="standard">
      <ModalOverlay />
      <ModalContent borderRadius="md" p="28px">
        <ModalHeader p="0px">
          <HStack justifyContent="space-between">
            <Heading variant="heading/h3" as="h3" color="blue.9">
              <Trans>Acknowledgement of Vault Risk</Trans>
            </Heading>
            <ModalCloseButton
              position="relative"
              top="initial"
              right="initial"
            />
          </HStack>
          <Text variant="body-md/normal" color="gray.5">
            Conditions of Use
          </Text>
        </ModalHeader>
        <ModalBody p="0px" mt="20px">
          <Box display="block" overflowY="scroll" height="300px">
            <OrderedList padding="10px">
              <ListItem>
                You agree to and accept to be bound by the{' '}
                <Link
                  href={externalLinks.termsOfUse}
                  isExternal
                  textDecoration="none"
                >
                  Terms of Use
                </Link>
                , including the risk disclosures contained therein.
              </ListItem>
              <ListItem>
                You represent and warrant that you understand Term Vaults are
                built on Yearn v3 smart contracts and have carefully reviewed
                the documentation at{' '}
                <Link
                  href="https://docs.yearn.fi"
                  isExternal
                  textDecoration="none"
                >
                  docs.yearn.fi
                </Link>{' '}
                and the risk disclosures found at{' '}
                <Link
                  href="https://docs.yearn.fi/developers/security/risks"
                  isExternal
                  textDecoration="none"
                >
                  docs.yearn.fi/developers/security/risks
                </Link>
                , including the subpages titled “Risk Scores,” “Protocol Risks,”
                “Strategy Risks,” and “Vault Risks”.
              </ListItem>
              <ListItem>
                You acknowledge the following and represent and warrant you
                understand the risks regarding the same:
                <OrderedList styleType="lower-alpha">
                  <ListItem>
                    Past performance of any Term Vault is not indicative of
                    future results. The value of your deposit can go down as
                    well as up, and you may lose some or all of your deposited
                    funds. Deposits are not insured or guaranteed by any
                    government or private entity. You bear all risks of
                    participation.
                  </ListItem>
                  <ListItem>
                    The strategies implemented by Term Vaults may not perform as
                    intended due to market conditions, technical limitations, or
                    other factors. Market volatility, liquidity constraints, or
                    external events could negatively impact strategy
                    performance. The effectiveness of strategies may deteriorate
                    over time as market conditions change or other market
                    participants adopt similar approaches. There may be deposit
                    or withdrawal restrictions placed by the curators of Term
                    Vaults.
                  </ListItem>
                  <ListItem>
                    Term Vaults interact with various decentralized finance (or
                    “defi”) protocols and smart contracts. Issues with these
                    protocols or contracts could result in trading losses or
                    frozen funds. Smart contract technology is experimental and
                    carries significant risks. Term Vaults may contain bugs,
                    vulnerabilities, or other issues that could result in loss
                    of funds. No guarantee is made regarding the security or
                    functionality of the smart contracts.
                  </ListItem>
                  <ListItem>
                    Blockchain and defi, including the Term Vaults built on
                    Yearn v3 smart contracts, are of a novel and experimental
                    nature and are associated with a variety of risks, which you
                    have familiarized yourself with and accepted. Those risks
                    include those set forth in the Terms of Use and Yearn’s
                    documentation.
                  </ListItem>
                  <ListItem>
                    Unless stated expressly, no Term Party (as defined below) is
                    a curator of a Term Vault or exercises any control over the
                    strategy implemented by said Vault.
                  </ListItem>
                  <ListItem>
                    The regulatory status of vaults implementing trading
                    strategies like Term Vaults is uncertain and subject to
                    change. Future regulatory actions could impact vault
                    operation or require modifications to strategies.
                  </ListItem>
                </OrderedList>
              </ListItem>
              <ListItem>
                You acknowledge and agree that Term Vaults are available “as is”
                and “as available” without any representation, warranties or
                guarantees whatsoever, and neither Terminal 0 Ltd. nor its
                affiliates nor their respective parents, subsidiaries, related
                companies, officers, directors, employees, agents,
                representatives, contractors, partners and licensors (the “Term
                Parties”) make any representation or guarantee whatsoever in
                connection with Term Vaults, including any representation or
                guarantee relating to yield, vault performance, vault curator,
                investment suitability, or the safety of funds in any Term Vault
                or strategy implemented by a Term Vault. The Term Parties shall
                have no liability whatsoever relating to your use of Term
                Vaults.
              </ListItem>
              <ListItem>
                You represent and warrant that you are not a person and/or not
                representing a person or entity which resides in, is a citizen
                of, or is located or incorporated in the United States of
                America or any other jurisdiction that is forbidden under the
                Terms of Use.
              </ListItem>
            </OrderedList>
          </Box>
        </ModalBody>
        <ModalFooter p="0px" mt="20px" w="full">
          <Button
            variant="primary"
            size="sm"
            w="full"
            onClick={vaultRiskAcknowledgement}
          >
            <Trans>Agree</Trans>
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
