import { graphql } from '../gql'

export const PageRewardsDocument = graphql(`
  query PageRewards(
    $seasonStart: BigInt!
    $lenders: [Bytes!]!
    $currentTimestamp: BigInt!
  ) {
    termAuctions(
      first: 1000
      where: { auctionStartTime_gt: $seasonStart, delisted: false }
    ) {
      id
      auction
      auctionStartTime
      auctionEndTime
    }
    termPurchases(
      where: { lender_in: $lenders, auction_: { delisted: false } }
    ) {
      auction {
        term {
          redemptionTimestamp
          purchaseToken
          purchaseTokenMeta {
            id
            name
            symbol
            decimals
          }
        }
      }
      repurchasePrice
      lender
    }
    termRepoExposures(
      where: {
        borrower_in: $lenders
        term_: { redemptionTimestamp_gt: $currentTimestamp, delisted: false }
      }
    ) {
      term {
        purchaseToken
        purchaseTokenMeta {
          id
          name
          symbol
          decimals
        }
      }
      repoExposure
      borrower
    }
    termRepoCollaterals(
      where: {
        repoExposure_: { borrower_in: $lenders }
        term_: { redemptionTimestamp_gt: $currentTimestamp, delisted: false }
      }
    ) {
      collateralToken
      term {
        collateralTokensMeta {
          id
          name
          symbol
          decimals
        }
      }
      amountLocked
      repoExposure {
        borrower
      }
    }
  }
`)
