import { BigNumber, constants, Contract, FixedNumber, Signer } from 'ethers'
import { useEffect, useMemo, useState } from 'react'
import { useParams, useSearchParams } from 'react-router-dom'
import { useGraphQuery } from '../data/hooks/helper-hooks'
import { useGraphAuction } from '../data/hooks/use-auction'
import { useCurrencies } from '../data/hooks/use-currencies'
import { useLoanTenders } from '../data/hooks/use-loan-tenders'
import {
  bigToFixedNumber,
  fixedToFormattedPercentage,
  getABIVersion,
} from '../helpers/conversions'
import { useGlobalRefresher } from '../providers/refresher'
import { FallbackProvider, JsonRpcProvider } from '@ethersproject/providers'
import { ChainId, useCall } from '@usedapp/core'
import { useMissingTenderRates } from '../data/hooks/use-missing-tender-rates'
import { useTokenWrapper } from '../data/hooks/use-token-wrapper'
import { useNativeTokenBalance } from '../data/hooks/use-native-token-balance'
import {
  DocumentType,
  getQueryDocument,
  getQueryVariables,
} from '../managers/subgraphManager'
import { useChainConfig } from '../providers/config'
import { PageAuctionQuery, PageAuctionQueryVariables } from '../gql/graphql'
import { useAuctionActivity } from '../data/hooks/use-auction-activity'
import { useSuggestedRates } from '../data/hooks/use-suggested-rates'
import { useTermToast } from './toasts'
import { isAddress } from 'ethers/lib/utils'
import StrategyABI from '../abi/yearn-vault/Strategy.json'
import { PageVaultQuery, PageVaultQueryVariables } from '../gql/vaults/graphql'
import { useVaultConstraints } from '../data/hooks/use-vault-constraints'
import { VaultAuctionPageParams } from '../models/vaultAuction'
import { VaultSummaryItem } from '../data/model'
import { add, divide, fixedCompare, subtract } from '../helpers/math'
import dayjs from 'dayjs'
import { formatWeightedAverageLength } from '../helpers/utils'

const zero = BigNumber.from(0)
const zeroFN = FixedNumber.fromString('0', 'fixed128x18')

export function useVaultAuctionPage(
  activeNetwork: ChainId | undefined,
  account: string | undefined,
  provider: JsonRpcProvider | FallbackProvider | undefined,
  signer: Signer | undefined,
  referralCode: string | undefined,
  onConnect: () => void,
  onCheckActiveNetwork: (
    chainId?: ChainId,
    chainName?: string
  ) => Promise<boolean>,
  onKytCheck: () => Promise<boolean>,
  onDeleteReferralCode: () => void
) {
  const { auctionAddress, chainId } = useParams()
  if (auctionAddress === undefined) {
    throw new Error(
      'Auction component may only be used within a route with id param'
    )
  }

  if (chainId === undefined) {
    throw new Error(
      'Auction component may only be used within a route with chain param'
    )
  }

  const parsedChainId = parseInt(chainId, 10)
  if (isNaN(parsedChainId)) {
    throw new Error(`Invalid value provided for ${parsedChainId}`)
  }

  const chainData = useChainConfig(parsedChainId)

  if (!chainData) {
    throw new Error(`Invalid chain id ${parsedChainId} - no chain config found`)
  }

  const [query, setQuery] = useState<any>({})
  // TODO: wire up what to do when refresh happens
  const [refreshTriggered, setRefreshTriggered] = useState(false)

  const [searchParams] = useSearchParams()
  const vaultAddress = searchParams.get('vault') ?? undefined

  const termToast = useTermToast()

  useEffect(() => {
    if (vaultAddress && !isAddress(vaultAddress)) {
      termToast.failure({
        title: 'Invalid vault address',
        children: 'Please provide a valid vault address',
      })
    }
  }, [termToast, vaultAddress])

  useEffect(() => {
    if (!vaultAddress) {
      return
    }
    const subgraphVersion = chainData.getSubgraphVersion()
    const queryDoc = getQueryDocument(
      subgraphVersion,
      DocumentType.PAGE_AUCTION
    )
    const queryVariables = getQueryVariables({
      subgraphVersion,
      docType: DocumentType.PAGE_AUCTION,
      variables: {
        auction: auctionAddress,
        wallet: vaultAddress,
      },
    })
    const query = {
      chainId: parsedChainId,
      url: chainData.subgraphUrl,
      query: queryDoc,
      variables: queryVariables,
    }
    setQuery(query)
  }, [vaultAddress, auctionAddress, chainData, parsedChainId])

  // Query data from subgraph for page
  // TODO: wire up fetching, error flags
  const {
    results: data,
    fetching,
    error,
    refresh: readFromSubgraph,
  } = useGraphQuery<PageAuctionQuery, PageAuctionQueryVariables>(query)

  const { slow: autoRefresher } = useGlobalRefresher()
  useEffect(() => {
    readFromSubgraph()
  }, [readFromSubgraph, autoRefresher])

  // Load blockchain data
  const { auction, term: partialTerm } = useGraphAuction(
    parsedChainId.toString(),
    auctionAddress,
    provider,
    data?.termAuctions
  )

  const auctionActivityData = useAuctionActivity(
    data?.auctionTermBids,
    data?.auctionTermOffers
  )

  const isUsingRebasingToken = useMemo(() => {
    return !!(
      auction?.collateralCurrency &&
      chainData.wrappedTokenMapping &&
      auction.collateralCurrency in chainData.wrappedTokenMapping
    )
  }, [auction?.collateralCurrency, chainData.wrappedTokenMapping])

  // { [chainId]: [purchase, collateral] }
  const tokenData = useMemo(() => {
    if (!auction) return { [chainId]: undefined }

    const addressesObject = [
      auction.purchaseCurrency,
      auction.collateralCurrency,
    ].map((c) => ({
      address: c,
      isRepoToken: false,
      version: auction.version,
    }))

    // if there is a wrapped token for the collateral, add the rebasing/underlying token to the list of tokens to fetch currency data for
    if (isUsingRebasingToken && chainData.wrappedTokenMapping) {
      addressesObject.push({
        address: chainData.wrappedTokenMapping[auction.collateralCurrency],
        isRepoToken: false,
        version: auction.version,
      })
    }

    return { [chainId]: addressesObject }
  }, [auction, chainData.wrappedTokenMapping, chainId, isUsingRebasingToken])

  const [_, offerLockerAddress, version] = useMemo(() => {
    const blA = auction ? auction.bidLockerAddress : undefined
    const olA = auction ? auction.offerLockerAddress : undefined
    const version = getABIVersion(auction?.version)
    return [blA, olA, version]
  }, [auction])

  const currencies = useCurrencies(tokenData, provider)
  const [purchaseCurrency, collateralCurrency] = useMemo(() => {
    if (!currencies) return [undefined, undefined, undefined]

    const purchaseCurrency = currencies?.[chainId]?.[0]
    const collateralCurrency = currencies?.[chainId]?.[1]
    return [purchaseCurrency, collateralCurrency]
  }, [currencies, chainId])

  const { value: vaultBalanceRaw } =
    useCall(
      vaultAddress && {
        contract: new Contract(vaultAddress, StrategyABI, provider),
        method: 'totalLiquidBalance',
        args: [],
      },
      {
        chainId: parsedChainId,
      }
    ) ?? {}

  const vaultPurchaseTokenBalance = vaultBalanceRaw?.[0] ?? zero

  const { value: vaultTotalAssetsRaw } =
    useCall(
      vaultAddress && {
        contract: new Contract(vaultAddress, StrategyABI, provider),
        method: 'totalAssetValue',
        args: [],
      },
      {
        chainId: parsedChainId,
      }
    ) ?? {}

  const vaultTotalAssetValue = useMemo(
    () =>
      purchaseCurrency?.decimals
        ? bigToFixedNumber(
            vaultTotalAssetsRaw?.[0] ?? zero,
            purchaseCurrency.decimals
          )
        : zeroFN,
    [vaultTotalAssetsRaw, purchaseCurrency]
  )

  const { value: vaultRepoTokenHoldingsRaw } =
    useCall(
      vaultAddress && {
        contract: new Contract(vaultAddress, StrategyABI, provider),
        method: 'repoTokenHoldings',
        args: [],
      },
      {
        chainId: parsedChainId,
      }
    ) ?? {}

  const hasRepoToken = useMemo(() => {
    if (
      auction?.termRepoTokenAddress &&
      vaultRepoTokenHoldingsRaw &&
      vaultRepoTokenHoldingsRaw?.[0]
    ) {
      const repoToken = auction.termRepoTokenAddress.toLowerCase()
      const repoTokenHoldings = vaultRepoTokenHoldingsRaw[0].map((h: string) =>
        h.toLowerCase()
      )

      return !!repoTokenHoldings.includes(repoToken)
    } else {
      return false
    }
  }, [auction?.termRepoTokenAddress, vaultRepoTokenHoldingsRaw])

  const prices = useMemo(() => {
    if (!auction || !purchaseCurrency || !collateralCurrency) {
      return undefined
    }
    const prices = {
      [chainId]: {
        [auction.purchaseCurrency]: FixedNumber.fromValue(
          auction.purchaseCurrencyOraclePriceUSDC,
          auction.purchaseCurrencyOraclePriceDecimals
        ),
        [auction.collateralCurrency]: FixedNumber.fromValue(
          auction.collateralCurrencyOraclePriceUSDC,
          auction.collateralCurrencyOraclePriceDecimals
        ),
      },
    }
    return prices
  }, [auction, chainId, purchaseCurrency, collateralCurrency])

  const decimals = useMemo(() => {
    if (!auction || !purchaseCurrency || !collateralCurrency) {
      return undefined
    }
    const decimals = {
      [chainId]: {
        [auction.purchaseCurrency]: auction.purchaseCurrencyOraclePriceDecimals,
        [auction.collateralCurrency]:
          auction.collateralCurrencyOraclePriceDecimals,
      },
    }
    return decimals
  }, [auction, chainId, purchaseCurrency, collateralCurrency])

  const [
    loanTenders,
    reloadLoanTenders,
    deleteLoanTenders,
    lockLoanTenders,
    lockVaultLoanTenders,
    deleteVaultLoanTenders,
  ] = useLoanTenders(
    chainData.chainId,
    version,
    vaultAddress,
    auctionAddress,
    decimals,
    prices,
    offerLockerAddress,
    provider,
    signer,
    data?.termOffers,
    readFromSubgraph,
    chainData.revealServerUrl,
    vaultAddress
  )

  const [loadMissingTenderRates, isLoadingMissingRates] = useMissingTenderRates(
    vaultAddress,
    loanTenders,
    [],
    signer
  )

  const vaultPurchaseTokenAllowance = constants.MaxUint256

  const {
    wrapGasToken: wrapGasTokenCall,
    unwrapGasToken: unwrapGasTokenCall,
    wrapToken: wrapTokenCall,
    unwrapToken: unwrapTokenCall,
  } = useTokenWrapper(
    chainData.chainId,
    chainData.contracts.wrappedGasToken,
    provider
  )

  const gasTokenCurrency = useMemo(
    () => chainData.nativeCurrency,
    [chainData.nativeCurrency]
  )
  const gasTokenBalance = useNativeTokenBalance(
    vaultAddress,
    gasTokenCurrency,
    auction?.chainId ? Number(auction.chainId) : undefined
  )

  const rateSuggestions = useSuggestedRates(
    auction?.chainId,
    auction?.purchaseCurrency,
    auction?.collateralCurrency,
    auction?.maintenanceMarginRatio
  )

  // Vault subgraph query
  // #region

  const [vaultsSubgraphQuery, setVaultsSubgraphQuery] = useState<any>({})
  useEffect(() => {
    if (!chainData || !vaultAddress) return
    const subgraphVersion = chainData.getSubgraphVersion()
    const queryDoc = getQueryDocument(subgraphVersion, DocumentType.PAGE_VAULT)
    const queryVariables = getQueryVariables({
      subgraphVersion,
      docType: DocumentType.PAGE_VAULT,
      variables: {
        id: vaultAddress,
      },
    })
    const query = {
      chainId: Number(chainId),
      url: chainData.vaultsSubgraphUrl,
      query: queryDoc,
      variables: queryVariables,
    }
    setVaultsSubgraphQuery(query)
  }, [chainData, chainId, vaultAddress])

  // Query data from subgraph for page
  // TODO: wire up fetching, error flags
  const {
    results: vaultSubgraphData,
    fetching: vaultSubgraphFetching,
    error: vaultSubgraphError,
    refresh: readFromVaultsSubgraph,
  } = useGraphQuery<PageVaultQuery, PageVaultQueryVariables>(
    vaultsSubgraphQuery
  )

  useEffect(() => {
    readFromVaultsSubgraph()
  }, [readFromVaultsSubgraph, autoRefresher])

  // Thresholds
  const {
    weightedMaturityCap,
    repoTokenConcentrationRatioLimit,
    liquidityRatioLimit,
  } = useMemo(() => {
    if (!vaultSubgraphData) return {}

    const weightedMaturity =
      vaultSubgraphData.termVaultStrategy?.timeToMaturityThreshold / 86400
    const repoTokenConcentrationRatio = bigToFixedNumber(
      vaultSubgraphData.termVaultStrategy?.repoTokenConcentrationLimit,
      18
    )
    const liquidityRatio = bigToFixedNumber(
      vaultSubgraphData.termVaultStrategy?.requiredReserveRatio,
      18
    )

    return {
      weightedMaturityCap: weightedMaturity,
      repoTokenConcentrationRatioLimit: repoTokenConcentrationRatio,
      liquidityRatioLimit: liquidityRatio,
    }
  }, [vaultSubgraphData])

  // Current values
  const {
    weightedMaturity: currentWeightedMaturity,
    repoTokenConcentrationRatio: currentRepoTokenConcentrationRatio,
    liquidityRatio: currentLiquidityRatio,
  } = useVaultConstraints(
    vaultAddress,
    chainId,
    hasRepoToken && auction?.termRepoTokenAddress
      ? auction?.termRepoTokenAddress
      : '0x0000000000000000000000000000000000000000',
    FixedNumber.fromString('0', `fixed128x18`),
    provider
  ) || {}

  // New values
  const [totalTenderAmount, setTotalTenderAmount] = useState<
    FixedNumber | undefined
  >(undefined)

  const fetchSimulateTransactionUpdate = useMemo(() => {
    if (
      hasRepoToken &&
      totalTenderAmount &&
      fixedCompare(
        totalTenderAmount,
        'gt',
        FixedNumber.fromString('0', 'fixed128x18')
      )
    ) {
      return true
    } else {
      return false
    }
  }, [totalTenderAmount, hasRepoToken])

  const {
    weightedMaturity: newWeightedMaturityHook,
    repoTokenConcentrationRatio: newRepoTokenConcentrationRatioHook,
    liquidityRatio: newLiquidityRatioHook,
  } = useVaultConstraints(
    vaultAddress,
    chainId,
    fetchSimulateTransactionUpdate
      ? partialTerm?.termRepoTokenAddress
      : undefined,
    fetchSimulateTransactionUpdate ? totalTenderAmount : undefined,
    provider
  ) || {}

  const {
    newWeightedMaturity,
    newRepoTokenConcentrationRatio,
    newLiquidityRatio,
  } = useMemo(() => {
    if (fetchSimulateTransactionUpdate) {
      return {
        newWeightedMaturity: newWeightedMaturityHook,
        newRepoTokenConcentrationRatio: newRepoTokenConcentrationRatioHook,
        newLiquidityRatio: newLiquidityRatioHook,
      }
    } else {
      // calculate values
      if (
        purchaseCurrency &&
        currentLiquidityRatio &&
        currentWeightedMaturity &&
        partialTerm?.redemptionTimestamp &&
        totalTenderAmount &&
        !totalTenderAmount.isZero() &&
        vaultTotalAssetValue &&
        !vaultTotalAssetValue.isZero()
      ) {
        // New WAL value
        const timeUntilRedemption = Math.abs(
          dayjs().diff(dayjs.unix(partialTerm.redemptionTimestamp), 'second')
        )
        const weightedMaturity = Math.round(
          Number(currentWeightedMaturity) +
            divide(totalTenderAmount, vaultTotalAssetValue).toUnsafeFloat() *
              timeUntilRedemption
        )

        // New concentration ratio
        const totalOffers =
          loanTenders?.reduce(
            (acc, tender) =>
              add(
                acc,
                bigToFixedNumber(
                  tender.amount.shiftedValue,
                  purchaseCurrency.decimals
                )
              ),
            FixedNumber.fromString('0', `fixed128x${purchaseCurrency.decimals}`)
          ) ?? zeroFN
        const concentrationRatio = divide(
          add(totalOffers, totalTenderAmount),
          vaultTotalAssetValue,
          18
        )

        // New liquidity reserver
        const liquidityRatio = subtract(
          currentLiquidityRatio,
          divide(totalTenderAmount, vaultTotalAssetValue)
        )

        return {
          newWeightedMaturity: weightedMaturity,
          newRepoTokenConcentrationRatio: concentrationRatio,
          newLiquidityRatio: liquidityRatio,
        }
      }

      return {}
    }
  }, [
    fetchSimulateTransactionUpdate,
    newWeightedMaturityHook,
    newRepoTokenConcentrationRatioHook,
    newLiquidityRatioHook,
    purchaseCurrency,
    currentLiquidityRatio,
    currentWeightedMaturity,
    partialTerm?.redemptionTimestamp,
    totalTenderAmount,
    vaultTotalAssetValue,
    loanTenders,
  ])

  const vaultConstraints: VaultSummaryItem[] | undefined = useMemo(() => {
    if (
      totalTenderAmount === undefined ||
      totalTenderAmount.isZero() ||
      weightedMaturityCap === undefined ||
      repoTokenConcentrationRatioLimit === undefined ||
      liquidityRatioLimit === undefined ||
      currentWeightedMaturity === undefined ||
      currentRepoTokenConcentrationRatio === undefined ||
      currentLiquidityRatio === undefined ||
      newWeightedMaturity === undefined ||
      newRepoTokenConcentrationRatio === undefined ||
      newLiquidityRatio === undefined
    ) {
      return undefined
    }

    const formattedCurrentWAL = formatWeightedAverageLength(
      currentWeightedMaturity
    )
    const formattedNewWAL = formatWeightedAverageLength(newWeightedMaturity)

    return [
      {
        text: [
          'WAL',
          `${weightedMaturityCap} ${weightedMaturityCap > 1 ? 'days' : 'day'} cap`,
        ],
        start: formattedCurrentWAL.toFixed(2),
        end: formattedNewWAL.toFixed(2),
        warning: formattedNewWAL > weightedMaturityCap,
      },
      {
        text: [
          'Concentration Ratio',
          `${fixedToFormattedPercentage(repoTokenConcentrationRatioLimit, 2, false, true).formattedPercentage} cap`,
        ],
        start: fixedToFormattedPercentage(
          currentRepoTokenConcentrationRatio,
          2,
          false,
          true
        ).formattedPercentage,
        end: fixedToFormattedPercentage(
          newRepoTokenConcentrationRatio,
          2,
          false,
          true
        ).formattedPercentage,
        warning: fixedCompare(
          newRepoTokenConcentrationRatio,
          'gt',
          repoTokenConcentrationRatioLimit
        ),
      },
      {
        text: [
          'Liquidity Reserve',
          `${fixedToFormattedPercentage(liquidityRatioLimit, 2, false, true).formattedPercentage} min`,
        ],
        start: fixedToFormattedPercentage(currentLiquidityRatio, 2, false, true)
          .formattedPercentage,
        end: fixedToFormattedPercentage(newLiquidityRatio, 2, false, true)
          .formattedPercentage,
        warning: fixedCompare(newLiquidityRatio, 'lt', liquidityRatioLimit),
      },
    ]
  }, [
    totalTenderAmount,
    currentLiquidityRatio,
    currentRepoTokenConcentrationRatio,
    currentWeightedMaturity,
    liquidityRatioLimit,
    newLiquidityRatio,
    newRepoTokenConcentrationRatio,
    newWeightedMaturity,
    repoTokenConcentrationRatioLimit,
    weightedMaturityCap,
  ])

  const params = useMemo(
    () =>
      ({
        account: vaultAddress,
        auction,
        auctionActivityData,
        term: partialTerm,
        purchaseCurrency,
        purchaseTokenBalance: vaultPurchaseTokenBalance,
        purchaseTokenAllowance: vaultPurchaseTokenAllowance,
        collateralCurrency,
        gasTokenCurrency,
        gasTokenBalance: gasTokenBalance?.balance,
        loanTenders: loanTenders,
        rateSuggestions,
        referralCode,
        reloadLoanTenders: () => {
          reloadLoanTenders()
          readFromSubgraph()
        },
        lockVaultLoanTenders: lockVaultLoanTenders
          ? (tenders, lender) =>
              lockVaultLoanTenders(
                tenders,
                lender,
                auction?.termId ?? '',
                auction?.auctionId ?? '',
                auction?.address ?? '',
                auction?.termRepoTokenAddress ?? ''
              )
          : undefined,
        deleteVaultLoanTenders: deleteVaultLoanTenders
          ? (tenders) => deleteVaultLoanTenders(tenders)
          : undefined,
        purchaseTokenApprove: async (spender, amount) => {
          return
        },
        onConnect,
        onCheckActiveNetwork,
        onKytCheck,
        onDeleteReferralCode,
        loadMissingTenderRates,
        wrapGasTokenCall,
        wrapTokenCall,
        unwrapGasTokenCall,
        unwrapTokenCall,
        isLoadingMissingRates,
        onSetTotalTenderAmount: setTotalTenderAmount,
        vaultConstraints,
      }) as VaultAuctionPageParams,
    [
      vaultAddress,
      auction,
      auctionActivityData,
      partialTerm,
      purchaseCurrency,
      vaultPurchaseTokenAllowance,
      vaultPurchaseTokenBalance,
      collateralCurrency,
      gasTokenCurrency,
      gasTokenBalance?.balance,
      loanTenders,
      rateSuggestions,
      referralCode,
      deleteVaultLoanTenders,
      lockVaultLoanTenders,
      onConnect,
      onCheckActiveNetwork,
      onKytCheck,
      onDeleteReferralCode,
      loadMissingTenderRates,
      wrapGasTokenCall,
      wrapTokenCall,
      unwrapGasTokenCall,
      unwrapTokenCall,
      isLoadingMissingRates,
      reloadLoanTenders,
      readFromSubgraph,
      setTotalTenderAmount,
      vaultConstraints,
    ]
  )
  return params
}
