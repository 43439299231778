import {
  Modal,
  ModalOverlay,
  ModalContent,
  Divider,
  Box,
  Text,
  Link,
  Image,
  Button,
} from '@chakra-ui/react'
import { FixedNumber } from 'ethers'
import { components } from '../../../../models/profile-api'
import { ModalBody } from './modal'
import { HStack, VStack } from '../../../../components/elements/Stack'
import { LockIcon } from '../../../../components/elements/Icons'
import closeIcon from '../../../../assets/icons/close.svg'

type Props = {
  isOpen: boolean
  onClose: () => void
  averageVaultBalance: FixedNumber
  currentVaultBalance: FixedNumber
  numVaults: number
  season: components['schemas']['Season'] | undefined
}

export default function VaultRewardsModal({
  isOpen,
  onClose,
  averageVaultBalance,
  currentVaultBalance,
  numVaults,
  season,
}: Props) {
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl" isCentered={true}>
      <ModalOverlay />
      <ModalContent p={5} w="520px">
        <VStack align="left" gap={'25px'}>
          <Box position="relative">
            <HStack gap={1.5}>
              <LockIcon w="11px" />
              <Text
                variant="body-xs/medium"
                fontStyle="normal"
                lineHeight="20px"
                color="gray.5"
              >
                Accrue 1.5 points / dollar per day deposited
              </Text>
            </HStack>
            <Text
              variant="body-2xl/medium"
              fontStyle="normal"
              lineHeight="24px"
              color="blue.9"
              mt="5px"
            >
              Deposit into Term Meta Vaults
            </Text>
            <Box position="absolute" right={'4px'} bottom={'2px'}>
              <Image
                src={closeIcon}
                boxSize="16px"
                cursor="pointer"
                onClick={onClose}
              />
            </Box>
          </Box>
          <Text
            variant="body-sm/normal"
            fontStyle="nomral"
            fontWeight={400}
            color="blue.9"
            opacity={0.7}
          >
            Accrue points for depositing into Term Meta Vaults and holding Vault
            LP tokens.
          </Text>
        </VStack>
        <Divider my={6} bg="#0000001a" opacity={1} />
        <ModalBody
          averageVaultBalance={averageVaultBalance}
          currentVaultBalance={currentVaultBalance}
          numVaults={numVaults}
          season={season}
        />

        <Box w="full" mt={'24px'}>
          <Link href={'/vaults'}>
            <Button w="full" variant="secondary">
              Deposit Today
            </Button>
          </Link>
        </Box>
      </ModalContent>
    </Modal>
  )
}
