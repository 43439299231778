import { Box, Flex, useDisclosure } from '@chakra-ui/react'
import dayjs from 'dayjs'
import { FixedNumber } from 'ethers'
import { useMemo } from 'react'
import { Outlet, Route, Routes } from 'react-router-dom'
import { Project } from '../../components/elements/ProjectIcon'
import { add, divide } from '../../helpers/math'
import { components } from '../../models/profile-api'
import {
  RewardsPageViewEnum,
  RewardsPageParams,
  SocialActions,
} from '../../models/rewards'
import ConnectWallet from './elements/ConnectWallet'
import EarnSection from './elements/EarnSection'
import InviteCode from './elements/InviteCode'
import InviteSection from './elements/InviteSection'
import LinkWalletModal from './elements/LinkWalletModal'
import NavBar from './elements/NavBar'
import PointsBreakdown from './elements/PointsBreakdown'
import RewardsPageLoading from './elements/RewardsPageLoading'
import RewardsSummary from './elements/RewardsSummary'
import SignInModal from './elements/SignInModal'
import SignUp from './elements/SignUp'
import SignUpModal from './elements/SignUpModal'
import SwitchProfileModal from './elements/SwitchProfileModal'
import TrackSection from './elements/TrackSection'
import WalletAnalyzer from './elements/WalletAnalyzer'
import { EarningsData } from './utils'

// TODO: implement view model
export default function RewardsPage(viewModel: RewardsPageParams) {
  // TODO [DOSPORE] these disclosures could go in viewModel
  const lendOnTermModalControls = useDisclosure()
  const participateInAuctionsModalControls = useDisclosure()
  const unlockMigrationModalControls = useDisclosure()
  const vaultRewardsModalControls = useDisclosure()

  // use primary wallet if available, otherwise use connected account
  const connectedAccount =
    viewModel.fullProfile?.wallets
      .filter((wallet) => wallet.isPrimary === true)
      .map((wallet) => wallet.address)[0] ?? viewModel.account

  const earnSectionCardInfo = useMemo(() => {
    // [DOSPORE] this could also go in viewModel but wasnt sure how you wanted to set it up since there is already some
    //  memos in here
    const info = {
      totalLoansFilled: 0,
      totalLoansTendered: 0,
      totalBorrowsFilled: 0,
      totalBorrowsTendered: 0,

      averageVaultBalance: FixedNumber.from(0),
      currentVaultBalance: FixedNumber.from(0),
      totalNumberOfVaults: 0,

      averageLoanBalance: FixedNumber.from(0),
      activeLoanBalance: viewModel.totalLoans,
      migrationBonusComplete: false,
      accountActiveSince: 0,
      accountActiveOn: [] as Project[],
      positionsFilled: 0,
      numTransactions: 0,
      totalNumberOfAuctions: 0,
    }
    if (!viewModel.fullProfile && !viewModel.publicProfile) {
      return info
    }

    const profilePointsBreakdown =
      viewModel.publicProfile?.pointsBreakdown &&
      Object.keys(viewModel.publicProfile?.pointsBreakdown).length > 0
        ? viewModel.publicProfile?.pointsBreakdown
        : viewModel.fullProfile?.pointsBreakdown &&
            Object.keys(viewModel.fullProfile?.pointsBreakdown).length > 0
          ? viewModel.fullProfile?.pointsBreakdown
          : viewModel.publicProfile?.wallet?.pointsBreakdown &&
              Object.keys(viewModel.publicProfile?.wallet?.pointsBreakdown)
                .length > 0
            ? viewModel.publicProfile?.wallet?.pointsBreakdown
            : undefined

    const transactions = {
      aave: 0,
      compound: 0,
      spark: 0,
      morpho: 0,
    }

    if (profilePointsBreakdown) {
      for (const [seasonId, profile] of Object.entries(
        profilePointsBreakdown
      )) {
        if (Number(seasonId) >= 0) {
          const migrationPointsMeta = profile.points?.migrationPoints?.meta
          if (migrationPointsMeta?.firstDate) {
            const t = dayjs(migrationPointsMeta.firstDate).unix()
            info.accountActiveSince =
              info.accountActiveSince === 0
                ? t
                : Math.min(t, info.accountActiveSince)

            transactions.aave +=
              (migrationPointsMeta.a3NumTransactions || 0) +
              (migrationPointsMeta.a2NumTransactions || 0)
            transactions.compound +=
              (migrationPointsMeta.c2NumTransactions || 0) +
              (migrationPointsMeta.c3NumTransactions || 0)
            transactions.spark += migrationPointsMeta.s1NumTransactions || 0
            transactions.morpho += migrationPointsMeta.m1NumTransactions || 0
          }

          const auctionPointsMeta = profile.points?.auctionPoints?.meta || {}
          if (Object.keys(auctionPointsMeta).length > 0) {
            info.totalBorrowsFilled +=
              auctionPointsMeta.totalAssignedBidAmount || 0
            info.totalBorrowsTendered += auctionPointsMeta.totalBidAmount || 0
            info.totalLoansFilled +=
              auctionPointsMeta.totalAssignedOfferAmount || 0
            info.totalLoansTendered += auctionPointsMeta.totalOfferAmount || 0
            info.positionsFilled += auctionPointsMeta.noAuctionsFilled || 0
          }

          const lendingPointsMeta = profile.points?.lendingPoints?.meta || {}
          if (Object.keys(lendingPointsMeta).length > 0) {
            const averageLoanBalance = FixedNumber.from(
              lendingPointsMeta.averageBalance?.toString() || '0'
            )
            if (!averageLoanBalance.isZero()) {
              info.averageLoanBalance = info.averageLoanBalance.isZero()
                ? averageLoanBalance
                : divide(
                    add(info.averageLoanBalance, averageLoanBalance),
                    FixedNumber.from(2)
                  )
            }

            info.totalNumberOfVaults += lendingPointsMeta?.vaultCount || 0
            info.currentVaultBalance = FixedNumber.from(
              lendingPointsMeta.currentVaultBalance?.toString() || '0'
            )
            info.averageVaultBalance = FixedNumber.from(
              lendingPointsMeta.averageVaultBalance?.toString() || '0'
            )
          }
        }
      }
    } else {
      info.accountActiveSince = 0
      info.positionsFilled = 0
      info.totalBorrowsFilled = 0
      info.totalBorrowsTendered = 0
      info.totalLoansFilled = 0
      info.totalLoansTendered = 0
      info.averageLoanBalance = FixedNumber.from(0)
      info.migrationBonusComplete = false
    }

    for (const [project, count] of Object.entries(transactions)) {
      if (count > 0) {
        info.accountActiveOn.push(project as Project)
        info.numTransactions += count
      }
    }

    if (viewModel.hasProfile && info.positionsFilled > 3) {
      info.migrationBonusComplete = true
    }

    // TODO: clean up - this is to get wallet analyzer info into earn card if no profile exists
    if (!viewModel.hasProfile) {
      info.migrationBonusComplete = false
      info.accountActiveSince = dayjs(
        viewModel.walletAnalyzer?.meta?.firstDate ?? 0
      ).unix()

      const transactionMeta = viewModel.walletAnalyzer?.meta

      const hasTransactions = (
        keys: (keyof components['schemas']['WalletAnalyzerMeta'])[]
      ): [boolean, number] => {
        let totalTransactions = 0
        const hasAnyTransactions = keys.some((key) => {
          const value = transactionMeta?.[key]
          if (typeof value === 'number' && value > 0) {
            totalTransactions += value
            return true
          }
          return false
        })
        return [hasAnyTransactions, totalTransactions]
      }

      info.numTransactions = 0

      const [hasAaveTransactions, aaveTransactions] = hasTransactions([
        'a3NumTransactions',
        'a2NumTransactions',
      ])
      if (hasAaveTransactions) {
        info.accountActiveOn.push('aave')
        info.numTransactions += aaveTransactions
      }

      const [hasCompoundTransactions, compoundTransactions] = hasTransactions([
        'c2NumTransactions',
        'c3NumTransactions',
      ])
      if (hasCompoundTransactions) {
        info.accountActiveOn.push('compound')
        info.numTransactions += compoundTransactions
      }

      const [hasSparkTransactions, sparkTransactions] = hasTransactions([
        's1NumTransactions',
      ])
      if (hasSparkTransactions) {
        info.accountActiveOn.push('spark')
        info.numTransactions += sparkTransactions
      }

      const [hasMorphoTransactions, morphoTransactions] = hasTransactions([
        'm1NumTransactions',
      ])
      if (hasMorphoTransactions) {
        info.accountActiveOn.push('morpho')
        info.numTransactions += morphoTransactions
      }
    }

    return info
  }, [
    viewModel.totalLoans,
    viewModel.fullProfile,
    viewModel.publicProfile,
    viewModel.hasProfile,
    viewModel.walletAnalyzer?.meta,
  ])

  const points = useMemo(() => {
    let earningsData: EarningsData[] = []
    let totalPoints = 0
    let lastUpdated = 0

    let auctionTotal = 0
    let lendingTotal = 0
    let inviteTotal = 0
    let otherTotal = 0
    let preseasonTotal = 0
    let migrationTotal = 0

    let pointsBreakdown: components['schemas']['ProfilePoints']

    // preseason points are season 0 app points, season 1 profile points
    let preseasonPoints: components['schemas']['PreseasonPoints'] | undefined
    let migrationPoints: components['schemas']['MigrationPoints'] | undefined
    let auctionPoints: components['schemas']['AuctionPoints'] | undefined

    if (viewModel?.currentSeason) {
      // fully auth-ed user
      if (viewModel.fullProfile) {
        pointsBreakdown = viewModel.fullProfile.pointsBreakdown
        totalPoints = viewModel.fullProfile.totalPoints
        lastUpdated = viewModel.fullProfile.lastUpdated
        preseasonPoints =
          pointsBreakdown?.[viewModel.currentSeason.id]?.points?.preseasonPoints
        migrationPoints =
          pointsBreakdown?.[viewModel.currentSeason.id]?.points?.migrationPoints
        auctionPoints =
          pointsBreakdown?.[viewModel.currentSeason.id]?.points?.auctionPoints
        // public profile points breakdown
      } else if (
        viewModel.publicProfile &&
        viewModel.publicProfile.profileExists &&
        viewModel.publicProfile.pointsBreakdown
      ) {
        pointsBreakdown = viewModel.publicProfile.pointsBreakdown
        totalPoints = viewModel.publicProfile?.totalPoints ?? 0
        lastUpdated = viewModel.publicProfile?.lastUpdated ?? 0
        preseasonPoints =
          pointsBreakdown?.[viewModel.currentSeason.id]?.points?.preseasonPoints
        migrationPoints =
          pointsBreakdown?.[viewModel.currentSeason.id]?.points?.migrationPoints
        auctionPoints =
          pointsBreakdown?.[viewModel.currentSeason.id]?.points?.auctionPoints
        // wallet points breakdown
      } else if (viewModel.publicProfile?.wallet?.pointsBreakdown) {
        pointsBreakdown = viewModel.publicProfile.wallet.pointsBreakdown
        totalPoints = viewModel.publicProfile.wallet?.totalPoints ?? 0
        lastUpdated = viewModel.publicProfile.wallet?.lastUpdated ?? 0
        auctionPoints =
          pointsBreakdown?.[viewModel.currentSeason.id]?.points?.auctionPoints
        // only count preseason points if current season is 1
        preseasonPoints =
          viewModel.currentSeason.id === 1
            ? pointsBreakdown?.[0]?.points?.preseasonPoints
            : undefined
        // if there are no points breakdown, return empty data
      } else {
        return {
          earningsData,
          totalPoints,
          lastUpdated,
          lockedMigrationPoints: 0,
        }
      }
    } else {
      return {
        earningsData,
        totalPoints,
        lastUpdated,
        lockedMigrationPoints: 0,
      }
    }

    let totalEligibleMigrationPoints = 0

    pointsBreakdown &&
      Object.keys(pointsBreakdown).forEach((key) => {
        if (Number(key) >= 0) {
          // temp filter out season -1
          const points = pointsBreakdown[key].points
          auctionTotal += points?.auctionPoints._subtotal ?? 0
          lendingTotal += points?.lendingPoints._subtotal ?? 0
          inviteTotal += points?.invitePoints._subtotal ?? 0
          otherTotal += points?.otherPoints._subtotal ?? 0
          preseasonTotal += points?.preseasonPoints?._subtotal ?? 0
          migrationTotal += points?.migrationPoints?._subtotal ?? 0
          totalEligibleMigrationPoints +=
            points?.migrationPoints?.meta?.totalEligiblePoints ?? 0
        }
      })

    // TODO: wire this up to support chakra-theme colours
    earningsData = [
      { category: 'Auctions', value: auctionTotal, color: '#5FD0FD' },
      { category: 'Lending', value: lendingTotal, color: '#9AE6B4' },
      { category: 'Invites', value: inviteTotal, color: '#FBD38D' },
      {
        category: 'Other',
        value: otherTotal + preseasonTotal + migrationTotal,
        color: '#9375FF',
      },
    ]

    const lockedMigrationPoints = viewModel.hasProfile
      ? Math.floor(totalEligibleMigrationPoints)
      : Math.floor(viewModel.walletAnalyzer?.bonusPoints?.[0]?.points ?? 0)

    return {
      pointsBreakdown,
      preseasonPoints,
      migrationPoints,
      auctionPoints,
      earningsData,
      totalPoints,
      lastUpdated,
      lockedMigrationPoints,
    }
  }, [
    viewModel.hasProfile,
    viewModel.currentSeason,
    viewModel.fullProfile,
    viewModel.publicProfile,
    viewModel.walletAnalyzer?.bonusPoints,
  ])

  if (
    viewModel.view === undefined ||
    viewModel.currentSeason === undefined ||
    viewModel.totalNumberOfAuctions === undefined ||
    viewModel.totalLoans === undefined ||
    viewModel.numOfActiveLoans === undefined ||
    viewModel.totalCollateral === undefined ||
    viewModel.totalBorrows === undefined ||
    viewModel.walletNetPositions === undefined ||
    viewModel.numberOfWeeks === undefined ||
    viewModel.onLogout === undefined ||
    viewModel.onUpdateProfile === undefined ||
    viewModel.onNavigateBackToInviteCode === undefined ||
    viewModel.onNavigateToSignUp === undefined ||
    viewModel.onNavigateToAuctions === undefined ||
    viewModel.onNavigateToInvites === undefined ||
    viewModel.onSignInModalOpen === undefined ||
    viewModel.onSignInModalClose === undefined ||
    viewModel.onSignUpModalOpen === undefined ||
    viewModel.onSignUpModalClose === undefined ||
    viewModel.onSignUpLater === undefined ||
    viewModel.onLinkWallet === undefined ||
    viewModel.onLinkMultiSigWallet === undefined ||
    viewModel.onSignInWithWallet === undefined ||
    viewModel.onLinkDiscord === undefined ||
    viewModel.onLinkTwitter === undefined
  ) {
    return <RewardsPageLoading />
  }

  const onUnlockMigrationSignUp = () => {
    if (unlockMigrationModalControls.isOpen) {
      unlockMigrationModalControls.onClose()
    }
    viewModel.onNavigateToSignUp()
  }

  return (
    <>
      <Box maxW="1400px" px={{ base: 8, xl: 10 }} mx="auto">
        <Flex w="100%" flexDirection="column" pt={4}>
          <>
            <SignInModal
              account={viewModel.account}
              connectedSocials={viewModel.connectedSocials}
              isOpen={viewModel.isSignInModalOpen}
              isOnConnectScreen={
                viewModel.view === RewardsPageViewEnum.NotConnected
              }
              isSwitchProfileViewOnly={viewModel.isSwitchProfileViewOnly}
              onClose={viewModel.onSignInModalClose}
              onLinkWallet={viewModel.onSignInWithWallet}
              onLinkTwitter={viewModel.onLinkTwitter}
              onLinkDiscord={viewModel.onLinkDiscord}
            />
            {viewModel.view === RewardsPageViewEnum.Dashboard &&
              viewModel.currentSeason &&
              connectedAccount && (
                <>
                  <SignUpModal
                    socialPoints={viewModel.currentSeason.meta?.socialPoints}
                    isOpen={viewModel.isSignUpModalOpen}
                    onClose={viewModel.onSignUpModalClose}
                    signUpStatus={viewModel.signUpStatus}
                    hasPreseasonPoints={viewModel.hasPreseasonPoints}
                    signUpValidation={viewModel.signUpValidation}
                    onLinkWalletNewUser={viewModel.onLinkWalletNewUser}
                    onLinkTwitter={viewModel.onLinkTwitter}
                    onLinkDiscord={viewModel.onLinkDiscord}
                    onSignUpLater={viewModel.onSignUpLater}
                    onAnalyzeWallet={viewModel.onAnalyzeWallet}
                    heading={'Sign up to unlock all features'}
                    subHeading={'Create your profile in a few simple steps'}
                    resetSignUpStepsSignal={viewModel.resetSignUpSteps}
                  />
                  <SwitchProfileModal
                    accountName={viewModel.profileName}
                    onSwitchProfile={viewModel.switchProfile.onSwitchProfile}
                    isOpen={viewModel.switchProfile.isOpen}
                    onClose={viewModel.switchProfile.onClose}
                  />
                  <PointsBreakdown
                    earningsData={points.earningsData}
                    totalPoints={points.totalPoints}
                    lastUpdated={points.lastUpdated}
                    season={viewModel.currentSeason}
                    // isLoading={viewModel.isLoading}
                  />
                  <Flex
                    justifyContent="space-between"
                    alignItems="center"
                    pt={5}
                  >
                    <NavBar
                      hasProfile={viewModel.hasProfile}
                      onSignUp={viewModel.onSignUpModalOpen}
                    />
                    <RewardsSummary
                      walletPositions={viewModel.walletNetPositions}
                      hasProfile={viewModel.hasProfile}
                      isLoggedIn={viewModel.isLoggedIn}
                      profileName={viewModel.profileName}
                      onSignUp={viewModel.onSignUpModalOpen}
                      onSignIn={viewModel.onSignInModalOpen}
                      isLoading={viewModel.isLoading}
                    />
                  </Flex>
                  <Box
                    // w="full"
                    overflow="hidden"
                    ml="0 !important"
                    pb="80px"
                    mb="0 !important"
                    py={5}
                  >
                    <Routes>
                      <Route path="/" element={<Outlet />}>
                        <Route
                          index
                          element={
                            <EarnSection
                              season={viewModel.currentSeason}
                              socialPoints={
                                viewModel.currentSeason.meta?.socialPoints
                              }
                              isSignedIn={viewModel.isLoggedIn}
                              accountExistsInProfile={
                                viewModel.accountExistsInProfile
                              }
                              hasProfile={viewModel.hasProfile}
                              totalLoansFilled={
                                earnSectionCardInfo.totalLoansFilled
                              }
                              totalLoansTendered={
                                earnSectionCardInfo.totalLoansTendered
                              }
                              totalBorrowsFilled={
                                earnSectionCardInfo.totalBorrowsFilled
                              }
                              totalBorrowsTendered={
                                earnSectionCardInfo.totalBorrowsTendered
                              }
                              totalLoans={viewModel.totalLoans}
                              totalNumOfActiveLoans={viewModel.numOfActiveLoans}
                              totalNumberOfWeeks={viewModel.numberOfWeeks}
                              activeLoanBalance={
                                earnSectionCardInfo.activeLoanBalance
                              }
                              averageLoanBalance={
                                earnSectionCardInfo.averageLoanBalance
                              }
                              averageVaultBalance={
                                earnSectionCardInfo.averageVaultBalance
                              }
                              currentVaultBalance={
                                earnSectionCardInfo.currentVaultBalance
                              }
                              totalNumberOfVaults={
                                earnSectionCardInfo.totalNumberOfVaults
                              }
                              lendOnTermModalControls={lendOnTermModalControls}
                              participateInAuctionsModalControls={
                                participateInAuctionsModalControls
                              }
                              vaultRewardsModalControls={
                                vaultRewardsModalControls
                              }
                              migrationBonusComplete={
                                earnSectionCardInfo.migrationBonusComplete
                              }
                              lockedMigrationPoints={
                                points.lockedMigrationPoints
                              }
                              accountActiveSince={
                                earnSectionCardInfo.accountActiveSince
                              }
                              accountActiveOn={
                                earnSectionCardInfo.accountActiveOn
                              }
                              numAccountTransactions={
                                earnSectionCardInfo.numTransactions
                              }
                              positionsFilled={
                                earnSectionCardInfo.positionsFilled
                              }
                              unlockMigrationModalControls={
                                unlockMigrationModalControls
                              }
                              preseasonPoints={points.preseasonPoints}
                              auctionPoints={points.auctionPoints}
                              inviteCodes={viewModel.fullProfile?.inviteCodes}
                              completedRetweets={viewModel.completedRetweets}
                              connectedSocials={viewModel.connectedSocials}
                              onNavigateToAuctions={
                                viewModel.onNavigateToAuctions
                              }
                              onNavigateToInvites={
                                viewModel.onNavigateToInvites
                              }
                              onSignIn={viewModel.onSignInModalOpen}
                              onSignUp={viewModel.onSignUpModalOpen}
                              onRetweet={viewModel.onRetweet}
                              onLinkTwitter={
                                viewModel.onLinkTwitter
                                  ? () =>
                                      viewModel.onLinkTwitter!(
                                        `/rewards?action=${SocialActions.LinkTwitter}`
                                      )
                                  : undefined
                              }
                              onLinkDiscord={
                                viewModel.onLinkDiscord
                                  ? () =>
                                      viewModel.onLinkDiscord!(
                                        `/rewards?action=${SocialActions.LinkDiscord}`
                                      )
                                  : undefined
                              }
                              onLinkWallet={viewModel.onLinkExtraWallet}
                              connectedWallet={
                                viewModel.isWalletConnected
                                  ? viewModel.account
                                  : undefined
                              }
                              onUnlockMigrationSignUp={onUnlockMigrationSignUp}
                            />
                          }
                        />
                        <Route
                          path="track"
                          element={
                            <TrackSection
                              profileData={viewModel.fullProfile?.profile}
                              profileBadges={viewModel.fullProfile?.badges}
                              usedInvites={
                                viewModel.fullProfile?.totalInvitesUsed
                              }
                              totalInvites={viewModel.fullProfile?.totalInvites}
                              season={viewModel.currentSeason}
                              seasonPointsBreakdown={
                                viewModel.currentSeason.id
                                  ? points.pointsBreakdown?.[
                                      viewModel.currentSeason.id
                                    ]
                                  : undefined
                              }
                              preseasonPoints={points.preseasonPoints}
                              totalNumberOfWeeks={viewModel.numberOfWeeks}
                              totalNumberOfAuctions={
                                viewModel.totalNumberOfAuctions
                              }
                              totalPoints={points.totalPoints}
                              totalActiveLoans={viewModel.totalLoans}
                              totalActivePositions={viewModel.numOfActiveLoans}
                              isLoading={viewModel.isLoading}
                            />
                          }
                        />
                        <Route
                          path="invite"
                          element={
                            <InviteSection
                              account={connectedAccount}
                              connectedSocials={viewModel.connectedSocials}
                              onLinkWallet={viewModel.onSignInWithWallet}
                              onLinkTwitter={viewModel.onLinkTwitter}
                              onLinkDiscord={viewModel.onLinkDiscord}
                              signedIn={viewModel.fullProfile ? true : false}
                              inviteCodes={viewModel.fullProfile?.inviteCodes}
                              usedInvites={
                                viewModel.fullProfile?.totalInvitesUsed
                              }
                              totalInvites={viewModel.fullProfile?.totalInvites}
                              referralCode={
                                viewModel.fullProfile?.profile.referralCode
                              }
                            />
                          }
                        />
                      </Route>
                    </Routes>
                  </Box>
                </>
              )}
            {viewModel.view === RewardsPageViewEnum.SignUp && (
              <SignUp
                socialPoints={viewModel.currentSeason?.meta?.socialPoints}
                signUpStatus={viewModel.signUpStatus}
                hasPreseasonPoints={viewModel.hasPreseasonPoints}
                signUpValidation={viewModel.signUpValidation}
                onLinkWalletNewUser={viewModel.onLinkWalletNewUser}
                onLinkTwitter={viewModel.onLinkTwitter}
                onLinkDiscord={viewModel.onLinkDiscord}
                onBack={viewModel.onNavigateBackToInviteCode}
                onSignUpLater={viewModel.onSignUpLater}
                onAnalyzeWallet={viewModel.onAnalyzeWallet}
                resetSignUpStepsSignal={viewModel.resetSignUpSteps}
              />
            )}
            {viewModel.view === RewardsPageViewEnum.InviteCode &&
              viewModel.onValidateInviteCode && (
                <InviteCode
                  onValidateInviteCode={viewModel.onValidateInviteCode}
                  validatedInviteCode={viewModel.validatedInviteCode}
                />
              )}
            {viewModel.view === RewardsPageViewEnum.NotConnected && (
              <ConnectWallet
                onConnect={viewModel.onConnect}
                onSignInModalOpen={viewModel.onSignInModalOpen}
              />
            )}
            {viewModel.view === RewardsPageViewEnum.WalletAnalyzer && (
              <WalletAnalyzer
                isAnimating={viewModel.walletAnalyzer.isAnimating}
                walletUserType={viewModel.walletAnalyzer.userType}
                title={viewModel.walletAnalyzer.title}
                comments={viewModel.walletAnalyzer.comments}
                bonusPoints={viewModel.walletAnalyzer.bonusPoints}
                onCloseWalletAnalyzer={viewModel.onCloseWalletAnalyzer}
                onAddAnotherWallet={viewModel.onLinkExtraWallet}
              />
            )}
          </>
          <LinkWalletModal
            account={viewModel.account}
            isOpen={viewModel.linkWallet.isOpen}
            onClose={viewModel.linkWallet.onClose}
            onSignUp={viewModel.linkWallet.onSignUp}
            onLinkWallet={viewModel.onLinkWallet}
          />
        </Flex>
      </Box>
    </>
  )
}
