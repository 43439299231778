import { Box, HStack, Text } from '@chakra-ui/react'
import { useMemo } from 'react'

export default function VaultStatusBanner({
  isVaultPaused,
  isDepositPaused,
  isVaultShutdown,
  isGovernorMismatch,
  isPendingManagement,
}: {
  isVaultPaused: boolean
  isDepositPaused: boolean
  isVaultShutdown: boolean
  isGovernorMismatch: boolean
  isPendingManagement: boolean
}) {
  const message = useMemo(() => {
    if (isGovernorMismatch) {
      return 'Governor role does not match strategy, please contact Term.'
    }
    if (isVaultShutdown) {
      return 'This vault has been shut down.'
    }
    if (isVaultPaused) {
      return 'This vault is currently paused.'
    }
    if (isDepositPaused) {
      return 'Deposits into this strategy are currently paused.'
    }
    if (isPendingManagement) {
      return 'Please accept management of this vault.'
    }
    return ''
  }, [
    isGovernorMismatch,
    isVaultShutdown,
    isVaultPaused,
    isDepositPaused,
    isPendingManagement,
  ])

  return (
    <Box as="section" bg="blue.5" py={2} overflow="hidden">
      <HStack columnGap={1} w="max-content" margin="0 auto">
        <Text as="span" variant="body-md/medium" color="white">
          {message}
        </Text>
      </HStack>
    </Box>
  )
}
