import { JsonRpcProvider, FallbackProvider } from '@ethersproject/providers'
import { Address, Currency } from '../model'
import { Contract, FixedNumber } from 'ethers'
import { useEffect, useMemo } from 'react'
import { CallObject, MultichainCalls, useMultichainCalls } from './helper-hooks'
import TermVaultStrategyABI from '../../abi/vault/TermVaultStrategy.json'
import { TermVaultStrategy } from '../../abi-generated'
import { captureException } from '@sentry/react'
import { bigToFixedNumber, fixedToBigNumber } from '../../helpers/conversions'

export function useConvertToAssetsMetaVault(
  sharesToConvertByChain:
    | {
        [chainId: string]: {
          [metaVaultAddress: string]: {
            strategyAddress: Address
            shares: FixedNumber
            assetCurrency: Currency
          }[]
        }
      }
    | undefined,
  provider: JsonRpcProvider | FallbackProvider | undefined
): {
  assetBalances:
    | {
        [chainId: string]: {
          [metaVaultAddress: string]: {
            [strategyAddress: string]:
              | { balance: FixedNumber; asset: Address }
              | null
              | undefined
          }
        }
      }
    | undefined
} {
  const strategyContracts = useMemo(() => {
    const contracts: {
      [chainId: string]: {
        [strategyAddress: Address]: TermVaultStrategy
      }
    } = {}

    if (sharesToConvertByChain) {
      for (const [chainId, metaVaults] of Object.entries(
        sharesToConvertByChain
      )) {
        contracts[chainId] = {}

        Object.values(metaVaults).forEach((strategies) => {
          strategies.forEach(({ strategyAddress }) => {
            if (strategyAddress) {
              contracts[chainId][strategyAddress] = new Contract(
                strategyAddress,
                TermVaultStrategyABI,
                provider
              ) as TermVaultStrategy
            }
          })
        })
      }
    }

    return contracts
  }, [sharesToConvertByChain, provider])

  const contractCalls = useMemo(() => {
    const calls: MultichainCalls = {}

    if (sharesToConvertByChain && strategyContracts) {
      Object.entries(strategyContracts).forEach(
        ([chainId, contractsByAddress]) => {
          const metaVaults = sharesToConvertByChain[chainId]
          if (!metaVaults) return

          const callsForChain: CallObject[] = []

          Object.entries(metaVaults).forEach(
            ([metaVaultAddress, strategies]) => {
              strategies.forEach(({ strategyAddress, shares }) => {
                const contract = contractsByAddress[strategyAddress]
                if (contract) {
                  callsForChain.push({
                    contract,
                    method: 'convertToAssets',
                    args: [fixedToBigNumber(shares)],
                  })
                }
              })
            }
          )

          if (callsForChain.length > 0) {
            calls[chainId] = callsForChain
          }
        }
      )
    }

    return calls
  }, [sharesToConvertByChain, strategyContracts])

  const blockchainResponse = useMultichainCalls(contractCalls)

  const blockchainResults = useMemo(() => {
    const result: { [chainId: string]: any[] } = {}
    Object.entries(blockchainResponse).forEach(
      ([chainId, resultsAndErrors]) => {
        result[chainId] = resultsAndErrors
          .filter((resultOrError) => !resultOrError?.error)
          .map((resultOrError) => resultOrError?.value)
      }
    )
    return result
  }, [blockchainResponse])

  const blockchainErrors = useMemo(() => {
    const errors: { [chainId: string]: any[] } = {}

    if (blockchainResponse) {
      Object.entries(blockchainResponse).forEach(
        ([chainId, resultsAndErrors]) => {
          const chainErrors = resultsAndErrors
            .filter((resultOrError) => !!resultOrError?.error)
            .map((resultOrError) => resultOrError?.error)

          if (chainErrors.length > 0) {
            errors[chainId] = chainErrors
          }
        }
      )
    }

    return errors
  }, [blockchainResponse])

  useEffect(() => {
    if (blockchainErrors) {
      Object.entries(blockchainErrors).forEach(([chainId, chainError]) => {
        console.error(`Error on ${chainId}: ${chainError}`)
        captureException(chainError)
      })
    }
  }, [blockchainErrors])

  const assetBalances = useMemo(() => {
    const result: {
      [chainId: string]: {
        [metaVaultAddress: string]: {
          [strategyAddress: string]:
            | { balance: FixedNumber; asset: Address }
            | null
            | undefined
        }
      }
    } = {}

    if (blockchainResults && sharesToConvertByChain) {
      Object.entries(sharesToConvertByChain).forEach(
        ([chainId, metaVaults]) => {
          result[chainId] = {}

          let valueIndex = 0
          Object.entries(metaVaults).forEach(
            ([metaVaultAddress, strategies]) => {
              result[chainId][metaVaultAddress] = {}

              strategies.forEach(
                ({ strategyAddress, assetCurrency }, index) => {
                  const value = blockchainResults[chainId]?.[valueIndex]
                  valueIndex++

                  if (strategyAddress && assetCurrency) {
                    result[chainId][metaVaultAddress][strategyAddress] = value
                      ? {
                          balance: bigToFixedNumber(
                            value?.[0],
                            assetCurrency.decimals ?? 18
                          ),
                          asset: assetCurrency.address,
                        }
                      : undefined
                  }
                }
              )
            }
          )
        }
      )
    }

    return result
  }, [blockchainResults, sharesToConvertByChain])

  return {
    assetBalances,
  }
}
