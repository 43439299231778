import { gql } from 'urql'

export const PageVaultDocument = gql`
  query PageVault($id: ID!) {
    termVaultStrategy(id: $id) {
      id
      discountRateAdapter
      collateralTokens {
        id
        name
        symbol
        decimals
      }
      minCollateralRatios {
        id
        collateralToken
        ratio
      }
      termController
      depositPaused
      previousTermController
      repoTokenConcentrationLimit
      timeToMaturityThreshold
      requiredReserveRatio
      discountRateMarkup
      hiddenAssets
      governor
      asset {
        id
        name
        symbol
        decimals
      }
      assetVault
      manager
      dao
      delay
      ownerless
      proposer
      roles
    }
  }
`
