import { useState } from 'react'
import VaultModalBase from '..'
import { Address } from '../../../../../data/model'
import { termToastMessages } from '../../../../../helpers/toasts'
import { useTermToast } from '../../../../../hooks/toasts'

export const UpdateDiscountRateAdapterModal = ({
  currentDiscountRateAdapter,
  onSetDiscountRateAdapter,
  isOpen,
  onClose,
}: {
  currentDiscountRateAdapter?: Address
  onSetDiscountRateAdapter: (newTermController: string) => Promise<void>
  isOpen: boolean
  onClose: () => void
}) => {
  const termToast = useTermToast()
  const [submitting, setSubmitting] = useState(false)
  return (
    <VaultModalBase
      isOpen={isOpen}
      title="Update Discount Rate Adapter"
      titleInput="New Discount Rate Adapter"
      placeholder="Enter the contract address"
      textButton="Update"
      contentCheckbox="I confirm the Discount Rate Adapter address above is correct. Please contact the team if you are unsure."
      onClose={onClose}
      actionButton={async (newTermController) => {
        setSubmitting(true)
        termToast.pending(termToastMessages.setDiscountRateAdapter.pending())
        try {
          await onSetDiscountRateAdapter(newTermController)
          termToast.success(
            termToastMessages.setDiscountRateAdapter.success(newTermController)
          )
        } catch (error) {
          if ((error as Error).message.includes('user rejected transaction')) {
            termToast.dismissed()
          } else {
            termToast.failure(
              termToastMessages.setDiscountRateAdapter.failure()
            )
          }
        } finally {
          setSubmitting(false)
        }
      }}
      defaultValue={currentDiscountRateAdapter}
      isAddressInput
      submitting={submitting}
    />
  )
}

export default UpdateDiscountRateAdapterModal
