import dayjs from 'dayjs'
import {
  RepoTokenBarChartMapping,
  RepoTokenPieChartMapping,
  VaultHolding,
} from '../../models/vault'
import { add, divide } from '../../helpers/math'
import { FixedNumber } from 'ethers'

const bucketRedemptionTimestamp = (redemptionTimestamp: number) => {
  const redemptionDate = dayjs.unix(redemptionTimestamp)
  const currentDate = dayjs()

  const dur = dayjs.duration(redemptionDate.diff(currentDate))

  if (dur.asWeeks() < 1) {
    return {
      bucket: '< 1 week',
      order: 0,
    }
  }

  if (dur.asWeeks() < 2) {
    return {
      bucket: '1 week',
      order: 1,
    }
  }

  if (dur.asWeeks() < 3) {
    return {
      bucket: '2 weeks',
      order: 2,
    }
  }

  if (dur.asWeeks() < 5) {
    return {
      bucket: '4 weeks',
      order: 3,
    }
  }

  if (dur.asWeeks() < 7) {
    return {
      bucket: '6 weeks',
      order: 4,
    }
  }

  if (dur.asWeeks() < 9) {
    return {
      bucket: '8 weeks',
      order: 5,
    }
  }

  if (dur.asWeeks() < 14) {
    return {
      bucket: '12 weeks',
      order: 6,
    }
  }
  if (dur.asWeeks() < 27) {
    return {
      bucket: '26 weeks',
      order: 7,
    }
  }
  return {
    bucket: '52 weeks',
    order: 8,
  }
}

export const groupRepoTokenHoldingsByMaturity = (
  holdings: VaultHolding[],
  idleCapitalUsd: FixedNumber
): RepoTokenBarChartMapping[] => {
  const aggregatedHoldings = holdings
    .map((holding) => {
      const holdingBucket = bucketRedemptionTimestamp(
        holding.redemptionTimestamp
      )
      return {
        order: holdingBucket.order,
        bucket: holdingBucket.bucket,
        amount: holding.presentValueUsd,
      }
    })
    .sort((a, b) => {
      return a.order - b.order
    })
    .reduce((acc, curr) => {
      const existingBucket = acc.find((bucket) => bucket.bucket === curr.bucket)

      if (existingBucket) {
        existingBucket.amount = add(existingBucket.amount, curr.amount)
      } else {
        acc.push({
          amount: curr.amount,
          bucket: curr.bucket,
        })
      }

      return acc
    }, [] as RepoTokenBarChartMapping[])

  // if there is idle capital, add it to the first bucket
  if (
    aggregatedHoldings.length > 0 &&
    aggregatedHoldings[0].bucket === '< 1 week'
  ) {
    aggregatedHoldings[0].amount = add(
      aggregatedHoldings[0].amount,
      idleCapitalUsd
    )
  } else {
    aggregatedHoldings.unshift({
      bucket: '< 1 week',
      amount: idleCapitalUsd,
    })
  }

  return aggregatedHoldings
}

export const groupRepoTokenHoldingsByCollateral = (
  holdings: VaultHolding[]
): RepoTokenPieChartMapping[] => {
  const sumPresentValue = holdings.reduce((acc, curr) => {
    return add(acc, curr.presentValue)
  }, FixedNumber.fromString('0'))

  const grouped = holdings.reduce<{ [symbol: string]: FixedNumber }>(
    (acc, holding) => {
      const symbol = holding.collateralTokens?.[0]?.symbol ?? 'Unknown'
      const currentSum = acc[symbol] ?? FixedNumber.fromString('0')
      acc[symbol] = add(currentSum, holding.presentValue)
      return acc
    },
    {}
  )

  return Object.entries(grouped)
    .map(([symbol, totalPresentValue]) => {
      const ratio = !sumPresentValue.isZero()
        ? Math.round(
            divide(totalPresentValue, sumPresentValue).toUnsafeFloat() * 100
          )
        : 0

      return {
        collateralToken: symbol,
        ratio,
      }
    })
    .sort((a, b) => b.ratio - a.ratio)
}
