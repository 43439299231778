import { Image, Text } from '@chakra-ui/react'
import { FixedNumber } from 'ethers'
import Tooltip from '../../../../components/elements/Tooltip'
import { VStack } from '../../../../components/elements/Stack'
import sparkle_icon from '../../../../assets/icons/sparkle-alt.svg'

export default function VaultApyTooltip({
  currentYield,
  symbol,
}: {
  currentYield?: FixedNumber
  symbol: string
}) {
  return (
    <Tooltip
      noDelay
      placement="bottom"
      label={
        !currentYield ? (
          'More data needed to display yield'
        ) : (
          <VStack alignItems="flex-start" w="full" color="white" gap={2}>
            <Text
              as="span"
              variant="body-sm/normal"
              color="white"
              textAlign="left"
            >
              {`Additional 1.5x Term Points / dollar deposited.`}
            </Text>
            <Text
              as="span"
              variant="body-sm/normal"
              color="white"
              textAlign="left"
            >
              {`Estimated yield based on Aave yield for ${symbol} plus yield on Term repo tokens held by vault.`}
            </Text>
          </VStack>
        )
      }
    >
      <Image boxSize="24px" src={sparkle_icon} alt={'Fixed APY'} />
    </Tooltip>
  )
}
