import { Box, StackProps } from '@chakra-ui/react'
import { HStack } from '../Stack'
import TermToastTokenIcon from '../TermToastTokenIcon'

interface TokenIconMultipleProps {
  symbols: string[]
  boxSize?: number
}

export default function TokenIconMultiple({
  symbols,
  boxSize = 24,
  ...props
}: TokenIconMultipleProps & StackProps) {
  const MAX_ICONS = 5
  const reversedSymbols = [...symbols].reverse()
  let displaySymbols = reversedSymbols
  if (symbols.length > MAX_ICONS) {
    displaySymbols = reversedSymbols.slice(0, MAX_ICONS)
    displaySymbols.push('__ELLIPSIS__')
  }

  const dynamicWidth = `${boxSize + (displaySymbols.length - 1) * (boxSize * 0.75)}px`

  return (
    <HStack
      w="100%"
      h={`${boxSize}px`}
      display="inline-block"
      {...props}
      align="start"
    >
      <Box
        boxSize={`${displaySymbols.length * boxSize}px`}
        width={dynamicWidth}
        bg="transparent"
        position="relative"
        zIndex={0}
      >
        {displaySymbols.map((symbol, index) => {
          const leftPosition = `${index * (boxSize * 0.75)}px`
          if (symbol === '__ELLIPSIS__') {
            return (
              <Box
                key="ellipsis"
                position="absolute"
                left={leftPosition}
                zIndex={index + 1}
                boxSize={`${boxSize}px`}
                display="flex"
                alignItems="center"
                justifyContent="center"
                borderRadius="50%"
              >
                ...
              </Box>
            )
          }

          return (
            <TermToastTokenIcon
              key={symbol}
              symbol={symbol}
              position="absolute"
              left={leftPosition}
              zIndex={index + 1}
              boxSize={`${boxSize}px`}
            />
          )
        })}
      </Box>
    </HStack>
  )
}
