import { JsonRpcProvider, FallbackProvider } from '@ethersproject/providers'
import { useMemo } from 'react'
import { useCurrencies } from './use-currencies'
import { FixedNumber } from 'ethers'
import { Address, Currency } from '../model'
import { useBalancesMulti } from './use-balances-multi'
import { useConvertToAssetsMetaVault } from './use-convert-to-assets-metavault'

export function useMetaVaultSingleStrategyBalances(
  metaVaultAddresses: Address[],
  singleStrategyAddressesByChain:
    | {
        [chainId: string]: Address[]
      }
    | undefined,
  provider: JsonRpcProvider | FallbackProvider | undefined
): {
  assetBalances:
    | {
        [chainId: string]: {
          [metaVaultAddress: string]: {
            [strategyAddress: string]:
              | { balance: FixedNumber; asset: Address }
              | null
              | undefined
          }
        }
      }
    | undefined
} {
  const mappedSingleStrategiesCurrencyInput = useMemo(() => {
    if (!singleStrategyAddressesByChain) return undefined

    return Object.keys(singleStrategyAddressesByChain).reduce(
      (result, id) => {
        const strategies = singleStrategyAddressesByChain[id] ?? []
        result[id] = strategies.map((address) => ({
          address,
          version: 'x',
          isRepoToken: false,
        }))
        return result
      },
      {} as {
        [chainId: string]: {
          address: Address
          version: string
          isRepoToken: boolean
        }[]
      }
    )
  }, [singleStrategyAddressesByChain])

  const singleStrategyReceiptCurrenciesByChain = useCurrencies(
    mappedSingleStrategiesCurrencyInput,
    provider
  )

  const mappedSingleStrategiesReceiptCurrenciesByAddress = useMemo(() => {
    if (
      !singleStrategyReceiptCurrenciesByChain ||
      !mappedSingleStrategiesCurrencyInput
    ) {
      return undefined
    }

    return Object.keys(singleStrategyReceiptCurrenciesByChain).reduce(
      (result, id) => {
        const singleStrategyCurrencies =
          singleStrategyReceiptCurrenciesByChain[id]
        const singleStrategyAddresses = mappedSingleStrategiesCurrencyInput[id]

        if (!singleStrategyCurrencies || !singleStrategyAddresses) {
          return result
        }

        result[id] = Object.fromEntries(
          singleStrategyAddresses.map((address, index) => {
            const currency = singleStrategyCurrencies[index]
            return [address.address, currency]
          })
        )

        return result
      },
      {} as { [chainId: string]: { [singleStrategyAddress: string]: Currency } }
    )
  }, [
    singleStrategyReceiptCurrenciesByChain,
    mappedSingleStrategiesCurrencyInput,
  ])

  const singleStrategyReceiptBalancesByChain = useBalancesMulti(
    metaVaultAddresses,
    mappedSingleStrategiesReceiptCurrenciesByAddress,
    provider
  )

  const metaVaultSingleStrategiesReceiptBalances = useMemo(() => {
    if (!singleStrategyReceiptBalancesByChain) return undefined

    const result: {
      [chainId: string]: {
        [metaVaultAddress: Address]: {
          [singleStrategyAddress: Address]: FixedNumber
        }
      }
    } = {}

    Object.entries(singleStrategyReceiptBalancesByChain).forEach(
      ([chainId, chainBalances]) => {
        result[chainId] = {}

        Object.entries(chainBalances).forEach(
          ([metaVaultAddress, singleStrategyBalances]) => {
            result[chainId][metaVaultAddress] = Object.fromEntries(
              Object.entries(singleStrategyBalances).map(
                ([address, balance]) => [address, balance.balance]
              )
            )
          }
        )
      }
    )

    return Object.keys(result).length > 0 ? result : undefined
  }, [singleStrategyReceiptBalancesByChain])

  const singleStrategyBalancesToConvertInput = useMemo(() => {
    if (
      !metaVaultSingleStrategiesReceiptBalances ||
      !mappedSingleStrategiesReceiptCurrenciesByAddress
    ) {
      return undefined
    }

    const result: {
      [chainId: string]: {
        [metaVaultAddress: string]: {
          strategyAddress: Address
          shares: FixedNumber
          assetCurrency: Currency
        }[]
      }
    } = {}

    Object.entries(metaVaultSingleStrategiesReceiptBalances).forEach(
      ([chainId, metaVaultBalances]) => {
        result[chainId] = {}

        Object.entries(metaVaultBalances).forEach(
          ([metaVaultAddress, strategies]) => {
            result[chainId][metaVaultAddress] = Object.entries(strategies).map(
              ([strategyAddress, shares]) => ({
                strategyAddress,
                shares,
                assetCurrency:
                  mappedSingleStrategiesReceiptCurrenciesByAddress[chainId][
                    strategyAddress
                  ],
              })
            )
          }
        )
      }
    )

    return result
  }, [
    metaVaultSingleStrategiesReceiptBalances,
    mappedSingleStrategiesReceiptCurrenciesByAddress,
  ])

  const { assetBalances: metaVaultSingleStrategiesAssetBalances } =
    useConvertToAssetsMetaVault(singleStrategyBalancesToConvertInput, provider)

  return {
    assetBalances: metaVaultSingleStrategiesAssetBalances,
  }
}
