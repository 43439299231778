import { Box, Skeleton, Flex, Grid } from '@chakra-ui/react'
import RepoCardHeading from '../../../Lend/elements/RepoCardHeading'
import { Trans } from '@lingui/macro'
import RepoCardButton from '../../../Lend/elements/RepoCardButton'
import { HStack, VStack } from '../../../../components/elements/Stack'

export default function MetaVaultCardLoading() {
  return (
    <HStack
      minW={{
        base: '922px',
      }}
      position="relative"
      borderRadius="6px"
      overflow="visible"
      boxShadow={'0px 4px 4px rgba(49, 130, 206, 0.03)'}
      p={3}
      justifyContent="space-between"
      background={'white'}
      transition="all .25s ease-in-out"
      spacing={14}
    >
      <HStack spacing={8}>
        <Skeleton h="48px" w="48px" />
        {/* Vault Name */}
        <Box maxW="355px" minW="290px">
          <Skeleton h="28px" w="80%" mb={2} />
          <Skeleton h="51px" w="100%" />
        </Box>
      </HStack>

      <HStack>
        <Grid
          gridTemplateColumns={{
            base: '155px 115px',
          }}
          gap={{
            base: 10,
          }}
        >
          {/* Current Yield */}
          <Flex
            flexDirection="column"
            align="start"
            verticalAlign="top"
            borderRight={{
              base: '1px solid',
            }}
            borderRightColor={{
              base: 'gray.3',
            }}
          >
            <RepoCardHeading title="Current Yield" mb={2} />
            <HStack>
              <Skeleton h="32px" w="80px" />
            </HStack>
          </Flex>

          {/* Collateral */}
          <Flex flexDirection="column" align="start" verticalAlign="top" pl={6}>
            <RepoCardHeading title="Collateral" mb={2} />
            <Skeleton h="32px" w="80px" />
          </Flex>
        </Grid>

        <HStack>
          {/* Total Asset Value */}
          <VStack
            mr={'52px'}
            py={2.5}
            spacing={0}
            alignItems="end"
            width={{
              base: '152px',
              xl: '283px',
            }}
          >
            <RepoCardHeading title="Total Asset Value" mb={2} />
            <Skeleton h="32px" w="120px" />
          </VStack>
          {/* Button */}
          <VStack
            minW={{
              base: 'max-content',
            }}
            px={{
              base: 0,
            }}
            gap={1}
            alignItems="end"
          >
            <HStack spacing={0.5} justifyContent="right" h="16px">
              <Skeleton h="16px" w="70px" />
            </HStack>
            <RepoCardButton handleClick={() => {}}>
              <Trans>View</Trans>
            </RepoCardButton>
          </VStack>
        </HStack>
      </HStack>
    </HStack>
  )
}
