import { Box, HStack, IconButton, Input, Text, Image } from '@chakra-ui/react'
import tick_icon from '../../../../assets/icons/tick_grey.svg'
import x_icon from '../../../../assets/icons/x_grey.svg'
import { INTEREST_RATE_DECIMALS } from '../../../../helpers/constants'
import { parseUserInput } from '../../../../helpers/utils'

export const EditCollateral = ({
  isDisabled,
  handleOnChange,
  handleSave,
  handleCancel,
  valueRatio,
}: {
  isDisabled: boolean
  handleOnChange: (value: string) => void
  handleSave: () => void
  handleCancel: () => void
  valueRatio: string
}) => {
  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    handleOnChange(
      parseUserInput(event.target.value, true, INTEREST_RATE_DECIMALS)
    )
  }
  return (
    <HStack gap={4} justifyContent="end">
      <HStack borderColor="blue.5 !important" borderBottom="1px solid" pb={1.5}>
        <Input
          variant="body-sm/normal !important"
          placeholder="0.000"
          value={valueRatio}
          onChange={onChange}
          border="none"
          cursor="text"
          _focus={{
            outline: 'none',
            boxShadow: 'none',
          }}
          h="auto"
          color="blue.9"
          borderRadius={0}
          w="60px"
          textAlign="right"
        />

        <Box bg="gray.2" py={1} px={1.5} borderRadius="6px">
          <Text variant="body-xs/medium">%</Text>
        </Box>
      </HStack>
      <HStack gap={1}>
        <IconButton
          isDisabled={isDisabled}
          variant="unstyled"
          size="xs"
          icon={<Image w={6} h={6} src={tick_icon} alt="proceed" />}
          onClick={handleSave}
          cursor="pointer"
          aria-label=""
        />
        <IconButton
          variant="unstyled"
          size="xs"
          icon={<Image w={6} h={6} src={x_icon} alt="cancel" />}
          onClick={handleCancel}
          cursor="pointer"
          aria-label=""
        />
      </HStack>
    </HStack>
  )
}
