import { Box, Flex, Text } from '@chakra-ui/react'
import { FixedNumber } from 'ethers'
import { useEffect, useState } from 'react'
import VaultsTableEmpty from '../VaultsTableEmpty'
import MetaVaultCard from '../MetaVaultCard'
import MetaVaultCardLoading from '../MetaVaultCardLoading'
import { Trans } from '@lingui/macro'
import { ColumnSortButton } from '../VaultsTable'
import { MetaVaultData } from '../../../../models/vaults'
import { isEqual } from 'lodash'

export default function MetaVaultsTable({
  isDataLoading,
  metaVaults,
  onViewMetaVault,
  selectedDepositToken,
  selectedChainId,
}: {
  isDataLoading?: boolean
  metaVaults: MetaVaultData[]
  onViewMetaVault: (metaVaultAddress: string, chainId: string) => void
  selectedDepositToken?: string
  selectedChainId?: string
}) {
  const [activeSort, setActiveSort] = useState('')
  const [filteredAndSortedData, setFilteredAndSortedData] = useState(metaVaults)
  const [sortConfig, setSortConfig] = useState<{
    key: keyof MetaVaultData
    direction: string
  }>({
    key: 'currentYield',
    direction: 'DESC',
  })

  useEffect(() => {
    let updatedData: MetaVaultData[] = metaVaults

    // Step 1: Filter data based on selectedChainId
    if (selectedChainId) {
      updatedData = updatedData.filter(
        (metaVault: MetaVaultData) => metaVault.chainId === selectedChainId
      )
    }

    // Step 2: Filter data based on selectedDepositToken
    if (selectedDepositToken && selectedDepositToken !== 'all') {
      updatedData = updatedData.filter(
        (metaVault: MetaVaultData) =>
          metaVault.purchaseCurrency.symbol === selectedDepositToken
      )
    }

    // Step 3: Sort data based on current sortConfig
    if (sortConfig.key) {
      const direction = sortConfig.direction === 'ASC' ? 1 : -1
      updatedData = [...updatedData].sort((a, b) => {
        let valueA: any = a[sortConfig.key]
        let valueB: any = b[sortConfig.key]

        if (valueA instanceof FixedNumber) {
          valueA = parseFloat(valueA.toString())
        }
        if (valueB instanceof FixedNumber) {
          valueB = parseFloat(valueB.toString())
        }

        if (typeof valueA === 'string' && typeof valueB === 'string') {
          return valueA.localeCompare(valueB) * direction
        }

        if (typeof valueA === 'string') {
          valueA = parseFloat(valueA.replace(/,/g, ''))
        }
        if (typeof valueB === 'string') {
          valueB = parseFloat(valueB.replace(/,/g, ''))
        }

        if (valueA === undefined && valueB === undefined) return 0
        if (valueA === undefined) return direction
        if (valueB === undefined) return -direction

        return valueA < valueB ? -direction : valueA > valueB ? direction : 0
      })
    }

    // Step 4: Update state if necessary to prevent redundant re-renders
    if (updatedData?.length && !isEqual(updatedData, filteredAndSortedData)) {
      setFilteredAndSortedData(updatedData)
    }
  }, [
    filteredAndSortedData,
    metaVaults,
    selectedChainId,
    sortConfig,
    selectedDepositToken,
  ])

  const handleSort = (key: keyof MetaVaultData) => {
    const direction =
      sortConfig.key === key && sortConfig.direction === 'ASC' ? 'DESC' : 'ASC'
    setSortConfig({ key, direction })
    setActiveSort(activeSort === key ? '' : key)
  }

  return (
    <>
      <Flex direction="column">
        {/* Table Header */}
        <Flex mb={3} minW="922px" gap={14}>
          <Box mr="auto">
            <Text variant="body-sm/medium" color="grey.6">
              <Trans>Meta Vault</Trans>
            </Text>
          </Box>

          <Flex>
            <Flex
              gap="111px"
              w={{
                base: '355px',
                xl: '486px',
              }}
            >
              <ColumnSortButton
                label="Current Yield"
                isActive={activeSort === 'currentYield'}
                onClick={() => handleSort('currentYield')}
              />
              <Text as="span" variant="body-sm/medium" color="grey.6">
                <Trans>Collateral</Trans>
              </Text>
            </Flex>
            <Flex justifyContent="start" width="300px">
              <ColumnSortButton
                label="Total Asset Value"
                isActive={activeSort === 'totalAssetValue'}
                onClick={() => handleSort('totalAssetValue')}
              />
            </Flex>
          </Flex>
        </Flex>
        {/* Table Body */}
        <Flex direction="column" gap={5}>
          {isDataLoading ? (
            Array.from({ length: 2 }).map((_, idx) => (
              <MetaVaultCardLoading key={idx} />
            ))
          ) : metaVaults?.length > 0 ? (
            // TODO: re-enable this - filteredAndSortedData.map((metaVault: MetaVaultData) => (
            metaVaults.map((metaVault: MetaVaultData) => (
              <MetaVaultCard
                key={metaVault.address}
                metaVault={metaVault}
                onViewMetaVault={onViewMetaVault}
              />
            ))
          ) : (
            <Flex pt={10}>
              <VaultsTableEmpty />
            </Flex>
          )}
        </Flex>
      </Flex>
    </>
  )
}
