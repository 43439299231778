import { Box, Heading, HStack, Skeleton, VStack } from '@chakra-ui/react'
import CollateralPieChart from '../VaultCharts/CollateralPieChart'
import CollateralHoldingBar from '../CollateralHoldingBar'
import { Trans } from '@lingui/macro'
import { MetaVaultStrategyAllocations } from '../../../../data/model'

type Props = {
  holdings: MetaVaultStrategyAllocations
  isDataLoaded?: boolean
}

const COLORS = ['#5FD0FD', '#0A5ED9', '#9375FF']
const OPACITY_CODES = [
  'FF', // 100% opacity (fully opaque)
  // "E6", // 90% opacity
  'CC', // 80% opacity
  // "B3", // 70% opacity
  '99', // 60% opacity
  // "80", // 50% opacity
  '66', // 40% opacity
  // "4D", // 30% opacity
  '33', // 20% opacity
  // "1A", // 10% opacity
]
const getColor = (index: number): string => {
  let color = COLORS[index % COLORS.length]
  const d = Math.floor(index / COLORS.length)
  const opacity = OPACITY_CODES[d % OPACITY_CODES.length]

  return `${color}${opacity}`
}

export default function MetaVaultStrategyAllocation({
  holdings,
  isDataLoaded = true,
}: Props) {
  const mappedData = holdings
    ? Object.values(holdings)
        .map(({ vaultName, ratio, balance }) => ({
          collateralToken: vaultName,
          ratio,
          balance,
        }))
        .sort((a, b) => b.ratio - a.ratio)
    : []

  return (
    <Box maxW="584px" bg="white" borderRadius="8px">
      <Heading variant="heading/h1">
        <Trans>Strategy Allocation</Trans>
      </Heading>
      <Skeleton isLoaded={isDataLoaded} h="230px" w="100%">
        <Box flexGrow="1" bg="white" p={0} mt={6}>
          <HStack spacing={10} alignItems="center">
            <CollateralPieChart
              isActiveTab={mappedData.length > 0}
              holdings={mappedData}
              width={180}
              height={180}
              getColorCustom={getColor}
              isTooltip
            />
            <VStack spacing="40px" w="327px" maxHeight="188px" overflowY="auto">
              {mappedData.map((holding, index) => (
                <CollateralHoldingBar
                  key={holding.collateralToken}
                  token={holding.collateralToken}
                  holdingPercent={holding.ratio}
                  color={getColor(index)}
                />
              ))}
            </VStack>
          </HStack>
        </Box>
      </Skeleton>
    </Box>
  )
}
