import {
  Box,
  Button,
  ModalBody as CModalBody,
  HStack,
  Image,
  Link,
  Text,
  VStack,
} from '@chakra-ui/react'
import { FixedNumber } from 'ethers'
import closeIcon from '../../../../assets/icons/close.svg'
import { InfoIcon } from '../../../../components/elements/Icons'
import Tooltip from '../../../../components/elements/Tooltip'
import { formatFixed } from '../../../../helpers/conversions'

import ColouredBox from '../ColouredBox'
import { components } from '../../../../models/profile-api'
import { useCurrentTime } from '../../../../data/hooks/helper-hooks'
import { generateAvgLoanBalanceToolip } from '../../../../helpers/utils'

type HeaderProps = {
  onClose: () => void
}

export const ModalHeader = ({ onClose }: HeaderProps) => (
  <VStack align="left" gap={'25px'}>
    <Box position="relative">
      <HStack gap={1.5}>
        <ColouredBox color="green.3" />
        <Text
          variant="body-xs/medium"
          fontStyle="normal"
          lineHeight="20px"
          color="gray.5"
        >
          Accrue 1 point / dollar per day
        </Text>
      </HStack>
      <Text
        variant="body-2xl/medium"
        fontStyle="normal"
        lineHeight="24px"
        color="blue.9"
        mt="5px"
      >
        Lend on Term
      </Text>
      <Box position="absolute" right={'4px'} bottom={'2px'}>
        <Image
          src={closeIcon}
          boxSize="16px"
          cursor="pointer"
          onClick={onClose}
        />
      </Box>
    </Box>
    <Text
      variant="body-sm/normal"
      fontStyle="nomral"
      fontWeight={400}
      color="blue.9"
      opacity={0.7}
    >
      Earn points for lending on Term and holding{' '}
      <Link
        href="https://docs.term.finance/protocol/term-repo-tokens"
        textDecoration="none"
        fontWeight={500}
        isExternal
      >
        Repo Tokens
      </Link>
      . Once Repo tokens are redeemable, they won’t earn points anymore.
    </Text>
  </VStack>
)

const Blurred = () => (
  <Box
    as="span"
    opacity={0.5}
    style={{
      color: 'transparent',
      textShadow: '0 0 15px #001027, 0 0 10px #001027',
    }}
  >
    {/* <Tooltip
      label="Hidden to prevent gaming the system."
      placement="bottom-end"
      noDelay
    > */}
    0.0
    {/* </Tooltip> */}
  </Box>
)

const HowToRow = ({
  label,
  numPoints,
  info,
  perDay,
}: {
  label: string
  numPoints: number
  info?: string
  perDay?: boolean
}) => (
  <HStack
    justify="space-between"
    py={2.5}
    px={2}
    w="full"
    _odd={{ bg: 'blue.0' }}
  >
    <HStack gap="4px">
      <Text variant="body-sm/normal" fontWeight={400} color="gray.6">
        {label}
      </Text>
      <Box>
        {info && (
          <Tooltip label={info} noDelay>
            <InfoIcon color="gray.4" boxSize="16px" />
          </Tooltip>
        )}
      </Box>
    </HStack>
    <HStack gap={'6px'}>
      <Text variant="body-sm/medium" color="blue.9">
        {`Earn ${numPoints === 0 ? <Blurred /> : numPoints} ${numPoints === 1 ? 'point' : 'points'} / dollar ${perDay ? 'per day' : ''}`}
      </Text>
      <ColouredBox color="green.3" />
    </HStack>
  </HStack>
)

type BodyProps = {
  averageLoanBalance: FixedNumber
  activeLoanBalance: FixedNumber
  numPositions: number
  season: components['schemas']['Season'] | undefined
}

export const ModalBody = ({
  averageLoanBalance,
  activeLoanBalance,
  numPositions,
  season,
}: BodyProps) => {
  const currentTime = useCurrentTime()
  const loanBalanceToolTip = generateAvgLoanBalanceToolip(currentTime, season)
  return (
    <CModalBody p={0}>
      <Text variant="body-l/medium" color="blue.9" mb={2}>
        How to earn lending points
      </Text>
      <VStack mb={'30px'}>
        <HowToRow label={'Hold Repo tokens'} numPoints={1} perDay={true} />
      </VStack>
      <Text variant="body-sm/medium" color="blue.9" mb={3}>
        Track your lending
      </Text>
      <HStack justify="space-between" w="full">
        <VStack spacing="4px" align="left">
          <Box>
            <Text
              variant="body-sm/normal"
              color="gray.6"
              opacity={0.5}
              as="span"
            >
              Your Average Loan Balance
            </Text>
            <Tooltip noDelay label={loanBalanceToolTip}>
              <InfoIcon ml={'4px'} color="gray.4" boxSize="16px" />
            </Tooltip>
          </Box>
          <Text variant="body-sm/normal" color="blue.9">
            {formatFixed(averageLoanBalance, {
              displayDecimals: 0,
              prefix: '$',
            })}
          </Text>
        </VStack>
        <VStack spacing="4px" align="left">
          <Text
            variant="body-sm/normal"
            color="gray.6"
            opacity={0.5}
            as="span"
            textAlign="right"
          >
            Your Active Loan Balance
          </Text>
          <Text variant="body-sm/normal" color="blue.9" textAlign="right">
            <Text as="span">
              {formatFixed(activeLoanBalance, {
                displayDecimals: 0,
                prefix: '$',
              })}
            </Text>{' '}
            <Text as="span" opacity={0.4}>
              {`over ${numPositions} position${numPositions !== 1 ? 's' : ''}`}
            </Text>
          </Text>
        </VStack>
      </HStack>
    </CModalBody>
  )
}

export const ModalFooter = () => (
  <>
    <Box w="full" mt={'24px'}>
      <Link href={'/auctions'}>
        <Button w="full" variant="secondary">
          View auctions
        </Button>
      </Link>
    </Box>
    <Box w="full" mt={'24px'}>
      <Link href={'/earn'}>
        <Button w="full" variant="tertiary">
          Visit the Blue Sheets Earn Page
        </Button>
      </Link>
    </Box>
  </>
)
