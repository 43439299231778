import { JsonRpcProvider, FallbackProvider } from '@ethersproject/providers'
import { Address } from '../model'
import { BigNumber, Contract } from 'ethers'
import { useEffect, useMemo } from 'react'
import { MultichainCalls, useMultichainCalls } from './helper-hooks'
import TermVaultStrategyABI from '../../abi/vault/TermVaultStrategy.json'
import { TermVaultStrategy } from '../../abi-generated'
import { captureException } from '@sentry/react'

export function useVaultRepoTokenHoldings(
  repoTokenHoldingsWithoutValueByChain: {
    [chainId: string]: {
      [strategyAddress: Address]:
        | {
            repoToken: Address
            presentValue: BigNumber | undefined
          }[]
        | null
        | undefined
    }
  },
  provider: JsonRpcProvider | FallbackProvider | undefined
): {
  repoTokenHoldingsWithValue:
    | {
        [chainId: string]: {
          [strategyAddress: Address]: {
            repoToken: Address
            presentValue: BigNumber
          }[]
        }
      }
    | undefined
} {
  const vaultContractCalls: MultichainCalls = useMemo(() => {
    const calls: MultichainCalls = {}

    Object.entries(repoTokenHoldingsWithoutValueByChain).forEach(
      ([chainId, strategiesForChain]) => {
        const chainCalls: {
          contract: Contract
          method: string
          args: [Address]
          meta: { strategyAddress: Address; repoToken: Address }
        }[] = []

        Object.entries(strategiesForChain).forEach(
          ([strategyAddress, repoTokenHoldingsWithoutValue]) => {
            if (!repoTokenHoldingsWithoutValue?.length) return

            const strategyContract = new Contract(
              strategyAddress,
              TermVaultStrategyABI,
              provider
            ) as TermVaultStrategy

            repoTokenHoldingsWithoutValue.forEach((holding) => {
              chainCalls.push({
                contract: strategyContract,
                method: 'getRepoTokenHoldingValue',
                args: [holding.repoToken],
                meta: {
                  strategyAddress,
                  repoToken: holding.repoToken,
                },
              })
            })
          }
        )

        calls[chainId] = chainCalls
      }
    )

    return calls
  }, [repoTokenHoldingsWithoutValueByChain, provider])

  const blockchainResponse = useMultichainCalls(vaultContractCalls)

  const blockchainResults = useMemo(() => {
    const result: { [chainId: string]: any[] } = {}
    Object.entries(blockchainResponse).forEach(
      ([chainId, resultsAndErrors]) => {
        result[chainId] = resultsAndErrors
          .filter((resultOrError) => !resultOrError?.error)
          .map((resultOrError) => resultOrError?.value)
      }
    )
    return result
  }, [blockchainResponse])

  const blockchainErrors = useMemo(() => {
    const errors: { [chainId: string]: any[] } = {}

    if (blockchainResponse) {
      Object.entries(blockchainResponse).forEach(
        ([chainId, resultsAndErrors]) => {
          const chainErrors = resultsAndErrors
            .filter((resultOrError) => !!resultOrError?.error)
            .map((resultOrError) => resultOrError?.error)

          if (chainErrors.length > 0) {
            errors[chainId] = chainErrors
          }
        }
      )
    }

    return errors
  }, [blockchainResponse])

  useEffect(() => {
    if (blockchainErrors) {
      Object.entries(blockchainErrors).forEach(([chainId, chainError]) => {
        console.error(`Error on ${chainId}: ${chainError}`)
        captureException(chainError)
      })
    }
  }, [blockchainErrors])

  const repoTokenHoldingsWithValue = useMemo(() => {
    if (!blockchainResults || !repoTokenHoldingsWithoutValueByChain)
      return undefined

    const repoTokenHoldingsWithValue: {
      [chainId: string]: {
        [strategyAddress: Address]: {
          repoToken: Address
          presentValue: BigNumber
        }[]
      }
    } = {}

    // fill in results from chain
    Object.entries(blockchainResults).forEach(([chainId, chainValues]) => {
      // chainValues is an array of results, in the exact same order
      // the calls were added to vaultContractCalls[chainId]
      const chainCalls = vaultContractCalls[chainId] || []

      repoTokenHoldingsWithValue[chainId] = {}

      // We'll group results by strategyAddress
      // so we can form repoTokenHoldingsWithValue
      chainCalls.forEach((call, index) => {
        // The call had meta: { strategyAddress, repoToken }
        const { strategyAddress, repoToken } = call.meta
        const rawCallValue = chainValues[index]?.[0] // getRepoTokenHoldingValue returned a single BigNumber, so it's in [0]
        const presentValue = BigNumber.from(rawCallValue ?? 0)

        if (!repoTokenHoldingsWithValue[chainId][strategyAddress]) {
          repoTokenHoldingsWithValue[chainId][strategyAddress] = []
        }

        repoTokenHoldingsWithValue[chainId][strategyAddress].push({
          repoToken,
          presentValue,
        })
      })
    })

    // handle empty results so the structure is preserved
    Object.entries(repoTokenHoldingsWithoutValueByChain).forEach(
      ([chainId, strategies]) => {
        if (!repoTokenHoldingsWithValue[chainId]) {
          repoTokenHoldingsWithValue[chainId] = {}
        }

        // For each strategy that is empty, ensure we have an empty array
        Object.entries(strategies).forEach(([strategyAddress, holdings]) => {
          // If the holdings is an empty array, or null/undefined, preserve it
          if (!holdings?.length) {
            if (!repoTokenHoldingsWithValue[chainId][strategyAddress]) {
              repoTokenHoldingsWithValue[chainId][strategyAddress] = []
            }
          }
        })
      }
    )

    return repoTokenHoldingsWithValue
  }, [
    blockchainResults,
    repoTokenHoldingsWithoutValueByChain,
    vaultContractCalls,
  ])

  return {
    repoTokenHoldingsWithValue,
  }
}
