import { FallbackProvider, JsonRpcProvider } from '@ethersproject/providers'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import dayjs from 'dayjs'
import { BigNumber, FixedNumber, Signer } from 'ethers'
import { useLocation, useNavigate } from 'react-router-dom'
import {
  useBucketedCurrentTime,
  useCurrentTimeSlow,
  useGraphQueries,
  useProfileData,
  useProfilePublicData,
} from '../data/hooks/helper-hooks'
import { useLogin } from '../data/hooks/points/use-login'
import { useMigrationPoints } from '../data/hooks/points/use-migration-points'
import { useSeasonsInfo } from '../data/hooks/points/use-seasons-info'
import { useValidateInviteCode } from '../data/hooks/points/use-validate-invite-code'
import { useValidateReferralCode } from '../data/hooks/points/use-validate-referral-code'

import { useGraphRewards } from '../data/hooks/use-rewards'
import {
  Address,
  Currency,
  DiscordOAuthCallback,
  TwitterOAuthCallback,
} from '../data/model'
import { PageRewardsQuery, PageRewardsQueryVariables } from '../gql/graphql'
import { getSignInSignature } from '../helpers/eip4361'
import {
  constructDiscordAvatarUrl,
  decodeJWTPayload,
  getWeeksBetween,
  isExpiredJWT,
  shortenAddress,
} from '../helpers/utils'
import {
  DocumentType,
  getQueryDocument,
  getQueryVariables,
} from '../managers/subgraphManager'
import {
  RewardsPageViewEnum,
  RewardsPageParams,
  SignUpValidation,
  WalletAnalyzerUserTypeEnum,
  LoginActions,
  SocialActions,
  EligibleRetweets,
} from '../models/rewards'
import { useChainConfigs } from '../providers/config'
import { usePrices } from '../data/hooks/use-prices'
import { useDisclosure } from '@chakra-ui/react'
import { components } from '../models/profile-api'
import { encodeState, oauth2Authorize } from './helpers/oauth'
import config from '../config'
import { v4 } from 'uuid'
import { useStorage } from '../providers/storage'
import { useTermToast } from './toasts'
import {
  useDeleteProfileData,
  useRemoveDiscordFromProfile,
  useRemoveTwitterFromProfile,
  useRemoveWalletFromProfile,
} from '../data/hooks/points/use-delete-profile'
import { getAccountSignature, requestAccounts } from '../helpers/metamask'
import { isConnectedToMetaMask } from '../helpers/eip6963'
import { getAddress } from 'ethers/lib/utils'
import { useProfileUpdate } from '../data/hooks/points/use-profile-update'
import { RewardedTweet, constructRetweetUrl } from '../socials'
// import { rewardsMachine } from '../machines/rewardsMachine'

export const useRewardsPage = ({
  account,
  signer,
  provider,
  twitterData,
  discordData,
  onConnect,
}: {
  account: Address | undefined
  signer: Signer | undefined
  provider: JsonRpcProvider | FallbackProvider | undefined
  twitterData: TwitterOAuthCallback
  discordData: DiscordOAuthCallback
  onConnect: () => void
}) => {
  // const [current, send] = useMachine(rewardsMachine);

  const chainConfigs = useChainConfigs()
  const bucketedCurrentTime = useBucketedCurrentTime()
  const currentTimeSlow = useCurrentTimeSlow()

  // TODO: connect refresher
  // const { slow: autoRefresher } = useGlobalRefresher()
  const { storage } = useStorage()
  const termToast = useTermToast()

  // const [prevAccount, setPrevAccount] = useState<string | undefined>(undefined)
  const [token, setToken] = useState<string | undefined>(
    storage.getItem('profile-token') ?? undefined
  )

  // reset the sign up screen steps when user switches wallet
  const [resetSignUpSteps, setResetSignUpSteps] = useState({})

  // keep track of previous profile id when switching profiles
  const prevProfileIdRef = useRef<string | undefined>()
  const [currentProfileId, setCurrentProfileId] = useState<string | undefined>(
    undefined
  )

  const [publicProfileData, setPublicProfileData] =
    useState<components['schemas']['ProfilePublicData']>()
  const [profileData, setProfileData] =
    useState<components['schemas']['ProfileData']>()

  const {
    token: loginToken,
    login,
    logout,
    // isLoading: isLoadingLogin,
    // isValid: isTokenValid,
    // error: loginError,
    // action: loginAction,
  } = useLogin()

  // modal controls
  // #region
  const {
    isOpen: isSignInModalOpen,
    onOpen: onSignInModalOpen,
    onClose: onSignInModalClose,
  } = useDisclosure()

  const {
    isOpen: isSignUpModalOpen,
    onOpen: onSignUpModalOpen,
    onClose: onSignUpModalClose,
  } = useDisclosure()

  const {
    isOpen: isLinkWalletModalOpen,
    onOpen: onLinkWalletModalOpen,
    onClose: onLinkWalletModalClose,
  } = useDisclosure()

  const {
    isOpen: isSwitchProfileModalOpen,
    onOpen: onSwitchProfileModalOpen,
    onClose: onSwitchProfileModalClose,
  } = useDisclosure()

  const [isLinkingWalletViaMetamask, setIsLinkingWalletViaMetamask] =
    useState(false)

  // Handles discord callback race condition when triggering wallet analyzer
  const [isWalletReadyToAnalyze, setIsWalletReadyToAnalyze] = useState(false)

  const onOpenLinkWalletModal = useCallback(() => {
    if (isLinkingWalletViaMetamask) {
      return
    }
    onLinkWalletModalOpen()
    // close switch profile modal if it's open (eg user changes wallet again without interacting with modal first)
    if (isSwitchProfileModalOpen) {
      console.log('triggering switch profile close')
      onSwitchProfileModalClose()
    }
  }, [
    isLinkingWalletViaMetamask,
    isSwitchProfileModalOpen,
    onLinkWalletModalOpen,
    onSwitchProfileModalClose,
  ])

  const onOpenSwitchProfileModal = useCallback(() => {
    onSwitchProfileModalOpen()
    // close link wallet modal if it's open (eg user changes profile again without interacting with modal first)
    if (isLinkWalletModalOpen) {
      onLinkWalletModalClose()
    }
  }, [isLinkWalletModalOpen, onLinkWalletModalClose, onSwitchProfileModalOpen])

  const onOpenSignUpModal = useCallback(() => {
    console.debug('opening sign up modal, resetting sign up steps')
    setResetSignUpSteps({})
    onSignUpModalOpen()
  }, [onSignUpModalOpen])
  // #endregion

  // helper to clear token and profile data
  const clearToken = useCallback(() => {
    console.debug('clearing profile auth token')
    setProfileData(undefined)
    storage.removeItem('profile-token')
    setToken(undefined)
    setCurrentProfileId(undefined)
    logout()
  }, [logout, storage])

  const {
    data: publicProfileApiResult,
    error: publicProfileApiError,
    isLoading: publicProfileApiIsLoading,
    refresh: refreshPublicProfileData,
  } = useProfilePublicData(account)

  const {
    analyzeWallet,
    // isLoading: isLoadingAnalyzedWallet,
    isAnimating: isAnimatingWalletAnalyzer,
    allMigrationPoints,
  } = useMigrationPoints()

  const {
    validateInviteCode,
    lastValidatedInviteCode,
    reset: resetInviteDetails,
    isLoading: isLoadingInviteCodeValidation,
    inviteCode: inviteDetails,
    validationError: inviteValidationError,
  } = useValidateInviteCode()
  const {
    validateReferralCode,
    isLoading: isLoadingReferralCodeValidation,
    referralCode: referralDetails,
    validationError: referralValidationError,
  } = useValidateReferralCode()

  useEffect(() => {
    if (
      !publicProfileApiIsLoading &&
      !publicProfileApiError &&
      publicProfileApiResult
    ) {
      console.debug(
        'setting public data with this information: %o',
        publicProfileApiResult
      )
      setPublicProfileData(publicProfileApiResult)
    } else {
      console.debug(
        'clearing public profile data: loading - %o, error - %o, data - %o',
        publicProfileApiIsLoading,
        publicProfileApiError,
        publicProfileApiResult
      )
      setPublicProfileData(undefined)
    }
  }, [publicProfileApiIsLoading, publicProfileApiError, publicProfileApiResult])

  // stop 403s on first load and remove expired/invalid tokens
  useEffect(() => {
    const token = storage.getItem('profile-token')
    if (token) {
      const decodedToken = decodeJWTPayload(token)
      if (
        decodedToken &&
        decodedToken?.exp &&
        !isExpiredJWT(decodedToken.exp) &&
        decodedToken?.sub
      ) {
        setToken(token)
        setCurrentProfileId(decodedToken.sub)
      } else {
        console.warn('clearing token due to expiration')
        clearToken()
      }
    }
  }, [clearToken, storage])

  const {
    data: profileApiResult,
    error: profileApiError,
    isLoading: profileApiIsLoading,
    refresh: refreshProfileData,
  } = useProfileData(token)

  // NOTE: this will log out the user if the profile api returns an error code
  useEffect(() => {
    if (!!profileApiError) {
      // TODO: handle this better at the rest hook level
      // if the error is a JSON object, it's likely an API error due to a bad request
      // if it's a string, then it's another generic error (eg network error)
      if (profileApiError?.message?.charAt(0) === '{') {
        const errorObj = JSON.parse(profileApiError?.message)
        if (errorObj?.status >= 400) {
          console.warn('clearing token due to profile api error: %o', errorObj)
          clearToken()
        }
      }
    }
  }, [clearToken, profileApiError])

  useEffect(() => {
    console.debug('profile data: %o', profileApiResult)
  }, [profileApiResult])

  useEffect(() => {
    if (!profileApiIsLoading && !profileApiError && profileApiResult) {
      // setProfileData((prevResult) => {
      //   if (prevResult !== profileApiResult) {
      //     console.log('profile data changed, refreshing public profile data')
      //     refreshPublicProfileData()
      //   }
      //   return profileApiResult
      // })
      setProfileData(profileApiResult)
    } else {
      setProfileData(undefined)
    }
  }, [profileApiIsLoading, profileApiError, profileApiResult])

  // Update public profile data when profile data changes
  useEffect(() => {
    if (profileData) {
      console.debug('profile data changed, refreshing public profile data')
      refreshPublicProfileData()
    }
  }, [profileData])

  const [, setUserRedirectedToSignUp] = useState<boolean>(false)

  const [walletSignUpStatus, setWalletSignUpStatus] = useState<
    'success' | 'failure' | undefined
  >(undefined)
  const [twitterSignUpRedirectStatus, setTwitterSignUpRedirectStatus] =
    useState<'success' | 'failure' | undefined>(undefined)
  const [discordSignUpRedirectStatus, setDiscordSignUpRedirectStatus] =
    useState<'success' | 'failure' | undefined>(undefined)
  const [hasSkippedTwitter, setHasSkippedTwitter] = useState<
    boolean | undefined
  >(undefined)

  // preseason points check -> if wallet has season 0 points
  const hasPreseasonPoints = useMemo(() => {
    return (
      !!publicProfileData?.walletExists &&
      !!(
        Object.keys(
          publicProfileData?.wallet?.pointsBreakdown?.[0]?.points ?? {}
        ).length > 0
      )
    )
  }, [publicProfileData])

  const triggerWalletAnalyzer = useCallback(
    (clearPrevious?: boolean, triggerAnimation?: boolean) => {
      setUserIsOnSignUpScreen(false)
      setUserIsOnWalletAnalyzer(true)
      console.log('running wallet analyzer for %o', account)
      if (account) {
        analyzeWallet(account, clearPrevious, triggerAnimation)
      }
    },
    [analyzeWallet, account]
  )

  /**
   * LOGIN API CALLS
   */
  // #region

  const [userIsOnWalletAnalyzer, setUserIsOnWalletAnalyzer] = useState(false)

  // Create a profile
  const signUpWithWallet = useCallback(
    async (referralType?: 'invite' | 'referral', code?: string) => {
      console.log('signing up with wallet')
      clearToken()

      if (!signer) {
        throw new Error('No signer available')
      }

      if (!hasPreseasonPoints && !(referralType && code)) {
        throw new Error('No preseason points or referral code available')
      }

      setWalletSignUpStatus(undefined)

      const nonce = v4()
      const signature = await getSignInSignature(signer, nonce)
      const response = await login(LoginActions.SignUpWallet, {
        token: undefined,
        wallet: {
          signature,
          nonce,
        },
        referralType:
          referralType && code
            ? {
                [referralType]: code,
              }
            : undefined,
      })

      if (!response.success) {
        console.warn('failed to sign up with wallet')
        setWalletSignUpStatus('failure')
        return
      }

      console.log('signed up with wallet')
      setWalletSignUpStatus('success')

      if (!isSignUpModalOpen) {
        setUserIsOnSignUpScreen(true)
      }
    },
    [clearToken, signer, hasPreseasonPoints, login, isSignUpModalOpen]
  )

  // Can link extra wallet(s), discord and/or twitter to profile

  // Used by link wallet modal
  const linkWalletToProfile = useCallback(
    async (isSkipWalletAnalyzerAnimation?: boolean) => {
      try {
        console.log('linking wallet to existing profile')
        if (!signer) {
          throw new Error('No signer available')
        }

        if (!token) {
          throw new Error('No profile token available')
        }

        const nonce = v4()
        const signature = await getSignInSignature(signer, nonce)

        const result = await login(LoginActions.LinkWallet, {
          token,
          wallet: {
            signature,
            nonce,
          },
        })

        if (!result.success) {
          console.error('error linking wallet: %o', result?.error)
          termToast.failure({
            title: 'Error linking wallet',
            children: result?.error?.message ?? 'Please try again.',
          })
          return
        }

        console.log('linked wallet to profile')

        triggerWalletAnalyzer(true, !isSkipWalletAnalyzerAnimation)

        termToast.success({
          title: 'Successfully linked wallet to your profile',
          children:
            'Points associated with this new wallet will appear on the next snapshot.',
        })

        if (isLinkWalletModalOpen) {
          onLinkWalletModalClose()
        }
      } catch (error) {
        console.error('error linking wallet: %o', error)
        termToast.failure({
          title: 'Error linking wallet',
          children: 'Please try again.',
        })
        return
      }
    },
    [
      signer,
      token,
      login,
      isLinkWalletModalOpen,
      onLinkWalletModalClose,
      termToast,
      triggerWalletAnalyzer,
    ]
  )

  // Used by link wallet card and manage profile
  const onLinkExtraWallet = useCallback(
    async (isSkipWalletAnalyzerAnimation?: boolean) => {
      // special case for when user is connected to metamask
      if (isConnectedToMetaMask()) {
        setIsLinkingWalletViaMetamask(true)
        let accounts = await requestAccounts()
        accounts = accounts
          .map((acc) => getAddress(acc)) // check sum addresses
          .filter((acc) => acc !== account) // make sure it's not the same as the current account
          .filter(
            (acc) =>
              acc &&
              !profileData?.wallets?.some(
                (w) => w.address.toLowerCase() === acc.toLowerCase()
              )
          ) // make sure it's not already linked

        if (accounts.length === 0) {
          termToast.failure({
            title: 'Selected wallet(s) already exist in profile.',
            children: 'Please try again.',
          })
          setIsLinkingWalletViaMetamask(false)
          return
        }

        for (let acc of accounts) {
          const nonce = v4()
          const signature = await getAccountSignature(acc, nonce)
          const result = await login(LoginActions.SilentLink, {
            token,
            wallet: {
              signature,
              nonce,
            },
          })
          if (result.success) {
            await analyzeWallet(acc, false, !isSkipWalletAnalyzerAnimation)
            termToast.success({
              title: 'Successfully linked wallet to your profile',
              children:
                'Points associated with this new wallet will appear on the next snapshot.',
            })
          } else {
            termToast.failure({
              title: 'Error linking wallet',
              children: result?.error?.message ?? 'Please try again.',
            })
          }
        }

        if (!isSkipWalletAnalyzerAnimation) {
          setUserIsOnWalletAnalyzer(true)
          setView(RewardsPageViewEnum.WalletAnalyzer)
        }

        setIsLinkingWalletViaMetamask(false)
      } else {
        linkWalletToProfile(isSkipWalletAnalyzerAnimation)
      }
    },
    [
      account,
      profileData,
      token,
      login,
      termToast,
      analyzeWallet,
      linkWalletToProfile,
    ]
  )

  // Link a contract account to this profile
  const linkMultiSigWalletToProfile = useCallback(
    async (multiSigWallet: string) => {
      try {
        console.log('linking contract wallet to existing profile')
        if (!token) {
          throw new Error('No profile token available')
        }

        if (!multiSigWallet) {
          throw new Error('No contract wallet address available')
        }

        const result = await login(LoginActions.LinkMultiSig, {
          token,
          multiSigWallet: {
            address: multiSigWallet,
          },
        })

        if (!result.success) {
          console.error('error linking contract account: %o', result?.error)
          termToast.failure({
            title: 'Error linking contract wallet',
            children: result?.error?.message ?? 'Please try again.',
          })
          return
        }

        console.log('linked contract wallet to profile')
        await analyzeWallet(multiSigWallet, false, false)
        termToast.success({
          title: 'Successfully linked contract wallet to your profile',
          children:
            'Points associated with this new wallet will appear on the next snapshot.',
        })
      } catch (error) {
        console.error('error linking contract wallet: %o', error)
        termToast.failure({
          title: 'Error linking contract wallet',
          children: 'Please try again.',
        })
        return
      }
    },
    [login, analyzeWallet, termToast, token]
  )

  const linkDiscordToProfile = useCallback(
    async (
      discordCode: string,
      isSignUp?: boolean,
      isSignUpModal?: boolean
    ) => {
      try {
        console.log('linking discord to existing profile')
        if (!token) {
          throw new Error('No profile token available')
        }

        const result = await login(
          isSignUp ? LoginActions.SignUpLinkDiscord : LoginActions.LinkDiscord,
          {
            token,
            discord: {
              authToken: discordCode,
            },
          }
        )
        setIsSocialLoading(false)

        if (!result.success) {
          console.error('error linking discord: %o', result.error)
          if (isSignUp) {
            setDiscordSignUpRedirectStatus('failure')
          }
          if (isSignUpModal) {
            onSignUpModalOpen()
          }
          termToast.failure({
            title: 'Error linking Discord',
            children: result?.error?.message ?? 'Please try again.',
          })
          return
        }

        if (isSignUp) {
          console.log(
            'discord linked to profile during sign up successfully - wallet analyzer being triggered now'
          )
          setDiscordSignUpRedirectStatus('success')
          setIsWalletReadyToAnalyze(true)
          console.log('signed up with wallet and linked discord')
        } else {
          console.log('linked discord to profile')
        }

        if (isSignUpModal) {
          onSignUpModalOpen()
        }

        termToast.success({
          title: 'Successfully linked Discord account to your profile',
        })
      } catch (error) {
        setIsSocialLoading(false)
        console.error('error linking discord: %o', error)
        termToast.failure({
          title: 'Error linking Discord',
          children: 'Please try again.',
        })
        return
      }
    },
    [login, onSignUpModalOpen, termToast, token]
  )

  // Handle race condition on redirect from Discord - triggering wallet analyezr before account is set
  useEffect(() => {
    if (account && isWalletReadyToAnalyze) {
      triggerWalletAnalyzer(true, true)
      setIsWalletReadyToAnalyze(false)
    }
  }, [account, isWalletReadyToAnalyze, triggerWalletAnalyzer])

  const linkTwitterToProfile = useCallback(
    async (
      twitterCode: string,
      twitterVerifier: string,
      isSignUp?: boolean,
      isSignUpModal?: boolean
    ) => {
      try {
        console.log('linking twitter to existing profile')
        if (!token) {
          throw new Error('No profile token available')
        }

        const response = await login(
          isSignUp ? LoginActions.SignUpLinkTwitter : LoginActions.LinkTwitter,
          {
            token,
            twitter: {
              code: twitterCode,
              codeVerifier: twitterVerifier,
              redirectUri: config.oauth.twitter.redirectUri,
            },
          }
        )
        setIsSocialLoading(false)

        if (!response.success) {
          console.error('error linking twitter: %o', response.error)
          if (isSignUp) {
            setTwitterSignUpRedirectStatus('failure')
          }
          if (isSignUpModal) {
            onSignUpModalOpen()
          }
          termToast.failure({
            title: 'Error linking X account',
            children: response?.error?.message ?? 'Please try again.',
          })
          return
        }

        if (isSignUp) {
          console.log('linked twitter to profile during sign up successfully')
          setTwitterSignUpRedirectStatus('success')
          setUserRedirectedToSignUp(true)
        } else {
          console.log('linked twitter to profile')
        }

        if (isSignUpModal) {
          onSignUpModalOpen()
        }

        termToast.success({
          title: 'Successfully linked X account to your profile',
        })
      } catch (error) {
        console.error('error linking twitter: %o', error)
        termToast.failure({
          title: 'Error linking X account',
          children: 'Please try again.',
        })
        return
      }
    },
    [login, onSignUpModalOpen, termToast, token]
  )

  // Can authenticate with any of wallet, discord and/or twitter

  const authenticateWithWallet = useCallback(async () => {
    try {
      console.log('signing in using wallet')

      if (!signer) {
        throw new Error('No signer available')
      }

      const nonce = v4()
      const signature = await getSignInSignature(signer, nonce)
      clearToken() // clear existing token
      const response = await login(LoginActions.SignInWallet, {
        token: undefined,
        wallet: {
          signature,
          nonce,
        },
      })

      if (!response.success) {
        console.error('error authenticating wallet: %o', response.error)
        termToast.failure({
          title: 'Error authenticating with wallet',
          children: response?.error?.message ?? 'Please try again.',
        })
        return
      }

      console.log('signed in with wallet')

      if (isSignInModalOpen) {
        onSignInModalClose()
      }
      termToast.success({
        title: 'Successfully signed in with wallet signature',
      })
    } catch (error) {
      console.error('error authenticating wallet: %o', error)
      termToast.failure({
        title: 'Error authenticating with wallet',
        children: 'Please try again.',
      })
      return
    }
  }, [
    clearToken,
    isSignInModalOpen,
    login,
    onSignInModalClose,
    signer,
    termToast,
  ])

  const authenticateWithDiscord = useCallback(
    async (discordCode: string) => {
      try {
        console.log('signing in using discord')
        clearToken() // clear existing token
        setPublicProfileData(undefined)
        const response = await login(LoginActions.SignInDiscord, {
          discord: {
            authToken: discordCode,
          },
        })

        setIsSocialLoading(false)

        if (!response.success) {
          console.error('error authenticating discord: %o', response.error)
          termToast.failure({
            title: 'Error signing in using the connected Discord account',
            children: response?.error?.message ?? 'Please try again.',
          })
          return
        }

        console.log('signed in with discord')
        termToast.success({
          title: 'Successfully signed in using the connected Discord account',
        })

        if (isSwitchProfileModalOpen) {
          onSwitchProfileModalClose()
        }

        refreshProfileData()
      } catch (error) {
        setIsSocialLoading(false)
        console.error('error authenticating discord: %o', error)
        termToast.failure({
          title: 'Error authenticating with Discord',
          children: 'Please try again.',
        })
        return
      }
    },
    [
      clearToken,
      isSwitchProfileModalOpen,
      login,
      onSwitchProfileModalClose,
      refreshProfileData,
      termToast,
    ]
  )

  const authenticateWithTwitter = useCallback(
    async (twitterCode: string, twitterVerifier: string) => {
      try {
        console.log('signing in using twitter')
        clearToken() // clear existing token
        setPublicProfileData(undefined)
        const response = await login(LoginActions.SignInTwitter, {
          twitter: {
            code: twitterCode,
            codeVerifier: twitterVerifier,
            redirectUri: config.oauth.twitter.redirectUri,
          },
        })

        setIsSocialLoading(false)

        if (!response.success) {
          console.error('error authenticating twitter: %o', response.error)
          termToast.failure({
            title: 'Error signing in using the connected X account',
            children: response?.error?.message ?? 'Please try again.',
          })
          return
        }

        console.log('linked twitter to profile')

        termToast.success({
          title: 'Successfully signed in using the connected X account',
        })

        if (isSwitchProfileModalOpen) {
          onSwitchProfileModalClose()
        }

        refreshProfileData()
      } catch (error) {
        setIsSocialLoading(false)
        console.error('error authenticating twitter: %o', error)
        termToast.failure({
          title: 'Error authenticating with X',
          children: 'Please try again.',
        })
        return
      }
    },
    [
      clearToken,
      isSwitchProfileModalOpen,
      login,
      onSwitchProfileModalClose,
      refreshProfileData,
      termToast,
    ]
  )
  // #endregion

  const navigate = useNavigate()
  const externalNavigate = useCallback(
    (url: string) => {
      window.location.href = url || '#'
    },
    [
      // no deps
    ]
  )

  /**
   * Handle callback from social login
   */
  // #region

  const twitterEffectRan = useRef(false)
  const discordEffectRan = useRef(false)

  const [isSocialLoading, setIsSocialLoading] = useState(false)

  // Twitter Auth logic

  useEffect(() => {
    if (!!twitterData.status) {
      if (twitterEffectRan.current) {
        return
      }
      twitterEffectRan.current = true

      if (twitterData.status === 'failure') {
        const toastMessage =
          twitterData.action === SocialActions.LinkTwitter
            ? 'Error linking X'
            : twitterData.action === SocialActions.SignInTwitter
              ? 'Error authenticating with X'
              : 'An error occured with X'
        termToast.failure({
          title: toastMessage,
          children: 'Please try again.',
        })

        // mark the active step if coming from sign up screen
        if (twitterData.action === SocialActions.SignUpLinkTwitter) {
          setTwitterSignUpRedirectStatus('failure')
        }

        if (twitterData.action === SocialActions.SignUpLinkTwitterModal) {
          setTwitterSignUpRedirectStatus('failure')
          onOpenSignUpModal()
        }
      } else if (twitterData.code && twitterData.verifier) {
        setIsSocialLoading(true)
        if (
          twitterData.action === SocialActions.SignUpLinkTwitter ||
          twitterData.action === SocialActions.SignUpLinkTwitterModal
        ) {
          linkTwitterToProfile(
            twitterData.code,
            twitterData.verifier,
            true,
            twitterData.action === SocialActions.SignUpLinkTwitterModal
          )
        } else if (twitterData.action === SocialActions.LinkTwitter) {
          linkTwitterToProfile(twitterData.code, twitterData.verifier)
        } else {
          authenticateWithTwitter(twitterData.code, twitterData.verifier)
        }
      }
      if (twitterData.refName) {
        setSignUpValidation({
          type: 'invite',
          code: '',
          name: twitterData.refName,
          image: twitterData?.refImage ?? '',
        })
      }
    }
  }, [
    authenticateWithTwitter,
    linkTwitterToProfile,
    onOpenSignUpModal,
    termToast,
    twitterData,
  ])

  // Discord Auth logic
  useEffect(() => {
    if (!!discordData.status) {
      if (discordEffectRan.current) {
        return
      }
      discordEffectRan.current = true

      if (discordData.status === 'failure') {
        const toastMessage =
          discordData.action === SocialActions.LinkDiscord
            ? 'Error linking Discord'
            : discordData.action === SocialActions.SignInDiscord
              ? 'Error authenticating with Discord'
              : 'An error occured with Discord'
        termToast.failure({
          title: toastMessage,
          children: 'Please try again.',
        })

        // mark the active step if coming from sign up screen
        if (
          discordData.action === SocialActions.SignUpLinkDiscord ||
          discordData.action === SocialActions.SignUpLinkDiscordModal
        ) {
          setDiscordSignUpRedirectStatus('failure')
          setHasSkippedTwitter(!!discordData?.hasSkippedTwitter)
          if (discordData.refName) {
            setSignUpValidation({
              type: 'invite',
              code: '',
              name: discordData.refName,
              image: discordData?.refImage ?? '',
            })
          }
        }

        if (discordData.action === SocialActions.SignUpLinkDiscordModal) {
          onOpenSignUpModal()
        }

        return
      }

      if (discordData.token) {
        setIsSocialLoading(true)
        console.log('sending discord auth login now')
        if (
          discordData.action === SocialActions.SignUpLinkDiscord ||
          discordData.action === SocialActions.SignUpLinkDiscordModal
        ) {
          linkDiscordToProfile(
            discordData.token,
            true,
            discordData.action === SocialActions.SignUpLinkDiscordModal
          )
          setHasSkippedTwitter(!!discordData?.hasSkippedTwitter)
          if (discordData.refName) {
            setSignUpValidation({
              type: 'invite',
              code: '',
              name: discordData.refName,
              image: discordData?.refImage ?? '',
            })
          }
        } else if (discordData.action === SocialActions.LinkDiscord) {
          linkDiscordToProfile(discordData.token)
        } else {
          authenticateWithDiscord(discordData.token)
        }
      }
    }
  }, [
    authenticateWithDiscord,
    discordData,
    linkDiscordToProfile,
    onOpenSignUpModal,
    termToast,
  ])
  // #endregion

  const profile = useMemo(() => {
    // Recover jwt claims from token
    if (token) {
      return decodeJWTPayload(token)?.aud
    }
    return undefined
  }, [token])

  // handle link wallet updates/new auth methods
  // TODO: adding refreshProfileData as dependency triggers infinite updates!
  useEffect(() => {
    if (loginToken) {
      console.debug(
        'trigger a refresh of profile data when login token changes'
      )
      refreshProfileData()
    }
  }, [loginToken])

  // token changes:
  useEffect(() => {
    const storedToken = storage.getItem('profile-token')
    if (storedToken && loginToken && storedToken !== loginToken) {
      // only update token if it has expired
      const decodedStoredToken = decodeJWTPayload(storedToken)
      if (
        !decodedStoredToken ||
        (decodedStoredToken?.exp && isExpiredJWT(decodedStoredToken.exp))
      ) {
        storage.setItem('profile-token', loginToken)
        setToken(loginToken)
        const incomingProfileId = decodeJWTPayload(loginToken)?.sub
        setCurrentProfileId(incomingProfileId)
      }
    } else if (!storedToken && loginToken) {
      storage.setItem('profile-token', loginToken)
      setToken(loginToken)
      setCurrentProfileId(decodeJWTPayload(loginToken)?.sub)
    }
  }, [loginToken, storage, currentTimeSlow])

  const currentTimestamp = dayjs().unix()
  const seasons = useSeasonsInfo().seasonsInfo ?? undefined
  const currentSeason =
    seasons?.['filter'] &&
    seasons?.filter(
      (season) =>
        season.id >= 0 && // temporary exclusion of 'open beta season'
        dayjs(season.start).unix() <= currentTimestamp &&
        dayjs(season.end).unix() > currentTimestamp
    )[0]
  const seasonStartUnix = dayjs(currentSeason?.start).unix()

  // sets the invite/referral validation information
  const { invite, referral } = useMemo(() => {
    const invite = {
      isLoading: isLoadingInviteCodeValidation,
      details: inviteDetails,
      error: !!inviteValidationError,
      message: inviteValidationError,
    }

    const referral = {
      isLoading: isLoadingReferralCodeValidation,
      details: referralDetails,
      error: !!referralValidationError,
      message: referralValidationError,
    }

    return {
      invite,
      referral,
    }
  }, [
    isLoadingInviteCodeValidation,
    inviteDetails,
    inviteValidationError,
    isLoadingReferralCodeValidation,
    referralValidationError,
    referralDetails,
  ])

  useEffect(() => {
    if (!isLoadingInviteCodeValidation && !!inviteValidationError) {
      termToast.failure({
        title: inviteValidationError ?? 'Error validating invite code',
        children: 'Please try again.',
      })
    }
  }, [isLoadingInviteCodeValidation, inviteValidationError, termToast])

  useEffect(() => {
    if (!isLoadingReferralCodeValidation && !!referralValidationError) {
      termToast.failure({
        title: 'Error validating referral code',
        children: 'Please try another code.',
      })
    }
  }, [isLoadingReferralCodeValidation, referralValidationError, termToast])

  const numberOfWeeks = useMemo(
    () => getWeeksBetween(seasonStartUnix, currentTimeSlow.unix()),
    [seasonStartUnix, currentTimeSlow]
  )

  const queries = useMemo(() => {
    if (!profileData && !account) return []
    else if (!profileData && account) {
      return chainConfigs.map((chainConfig) => {
        const subgraphVersion = chainConfig.getSubgraphVersion()
        const queryDoc = getQueryDocument(
          subgraphVersion,
          DocumentType.PAGE_REWARDS
        )
        const queryVariables = getQueryVariables({
          subgraphVersion,
          docType: DocumentType.PAGE_REWARDS,
          variables: {
            seasonStart: seasonStartUnix,
            lenders: [account],
            currentTimestamp: bucketedCurrentTime.unix(),
          },
        })
        return {
          chainId: chainConfig.chainId,
          url: chainConfig.subgraphUrl,
          query: queryDoc,
          variables: queryVariables,
        }
      })
    }

    return chainConfigs.map((chainConfig) => {
      const subgraphVersion = chainConfig.getSubgraphVersion()
      const queryDoc = getQueryDocument(
        subgraphVersion,
        DocumentType.PAGE_REWARDS
      )
      const queryVariables = getQueryVariables({
        subgraphVersion,
        docType: DocumentType.PAGE_REWARDS,
        variables: {
          seasonStart: seasonStartUnix,
          lenders: profileData?.wallets?.map((wallet) => wallet.address) ?? [],
          currentTimestamp: bucketedCurrentTime.unix(),
        },
      })
      return {
        chainId: chainConfig.chainId,
        url: chainConfig.subgraphUrl,
        query: queryDoc,
        variables: queryVariables,
      }
    })
  }, [profileData, account, chainConfigs, seasonStartUnix, bucketedCurrentTime])

  const {
    results: data,
    // fetching,
    // error,
    // refresh: readFromSubgraph,
  } = useGraphQueries<PageRewardsQuery, PageRewardsQueryVariables>(queries)

  // useEffect(() => {
  //   readFromSubgraph()
  // }, [readFromSubgraph, autoRefresher])

  const currenciesByChainData = useMemo(() => {
    const currenciesByChain: {
      [chainId: string]: Currency[]
    } = {}

    Object.entries(data).forEach(([chainId, result]) => {
      // For ERC20 tokens
      const purchaseTokenCurrencies = [
        ...new Set(
          result.termPurchases
            .map((p) => ({
              address: p.auction.term.purchaseTokenMeta?.id ?? '',
              symbol: p.auction.term.purchaseTokenMeta?.symbol ?? '',
              decimals: p.auction.term.purchaseTokenMeta?.decimals ?? 18,
              isRepoToken: false,
            }))
            .filter((x) => !!x)
        ),
        ...new Set(
          result.termRepoExposures
            .map((p) => ({
              address: p.term.purchaseTokenMeta?.id ?? '',
              symbol: p.term.purchaseTokenMeta?.symbol ?? '',
              decimals: p.term.purchaseTokenMeta?.decimals ?? 18,
              isRepoToken: false,
            }))
            .filter((x) => !!x)
        ),
      ]

      const collateralTokenCurrencies = [
        ...new Set(
          result.termRepoCollaterals
            .flatMap((p) =>
              (p.term.collateralTokensMeta ?? []).map((meta) => ({
                address: meta.id ?? '',
                symbol: meta.symbol ?? '',
                decimals: meta.decimals ?? 18,
                isRepoToken: false,
              }))
            )
            .filter((x) => x.address) // Ensure only valid addresses are kept
        ),
      ]

      const currencies = purchaseTokenCurrencies.concat(
        collateralTokenCurrencies
      )

      currencies.forEach((curr) => {
        if (!currenciesByChain[chainId]) {
          currenciesByChain[chainId] = []
        }

        if (
          !currenciesByChain[chainId].some(
            (currency) => currency.address === curr.address
          )
        ) {
          currenciesByChain[chainId].push(curr)
        }
      })
    })

    return currenciesByChain
  }, [data])

  const currencies = useMemo(() => {
    if (!currenciesByChainData) return undefined

    const result: Record<string, { [token: Address]: Currency }> = {}

    currenciesByChainData &&
      Object.entries(currenciesByChainData).forEach(
        ([chainId, currencyArray]) => {
          result[chainId] =
            currencyArray.reduce(
              (acc, currency) => {
                acc[currency.address] = currency
                return acc
              },
              {} as { [token: Address]: Currency }
            ) ?? {}
        }
      )

    return result
  }, [currenciesByChainData])

  const pricesData = usePrices(
    currenciesByChainData ?? undefined,
    undefined,
    provider
  )

  const prices = useMemo(() => {
    if (!pricesData) return undefined

    const result: Record<string, { [token: Address]: FixedNumber }> = {}

    pricesData &&
      Object.entries(pricesData).forEach(([chainId, priceArray]) => {
        result[chainId] =
          priceArray.reduce(
            (acc, price) => {
              acc[price.token] = FixedNumber.fromValue(
                price.price ?? BigNumber.from(0),
                price.decimals,
                `fixed128x${price.decimals}`
              )
              return acc
            },
            {} as { [token: Address]: FixedNumber }
          ) ?? {}
      })

    return result
  }, [pricesData])
  // total auctions since season started
  const [
    totalNumberOfAuctions,
    totalLoans,
    totalCollateral,
    totalBorrows,
    walletNetPositions,
    numOfActiveLoans,
  ] = useGraphRewards(data, prices, currencies)

  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const referralCodeParam = queryParams.get('ref')
  const inviteCodeParam = queryParams.get('invite')

  // TODO: clear invite/referral code after user signs up / if user navigates away
  // clear url param after triggered:
  // const searchParams = new URLSearchParams(location.search)
  // searchParams.delete('ref')
  // searchParams.delete('invite')
  // navigate({
  //   pathname: location.pathname,
  //   search: searchParams.toString(),
  // }, { replace: true })

  useEffect(() => {
    if (!!referralCodeParam) {
      validateReferralCode(referralCodeParam)
    }
  }, [referralCodeParam, validateReferralCode])

  useEffect(() => {
    if (!!inviteCodeParam) {
      validateInviteCode(inviteCodeParam)
    }
  }, [inviteCodeParam, validateInviteCode])

  const [signUpValidation, setSignUpValidation] = useState<
    SignUpValidation | undefined
  >(undefined)

  useEffect(() => {
    if (invite.details) {
      setSignUpValidation({
        type: 'invite',
        code: invite.details.code,
        name: invite.details.inviter.name,
        image: invite.details.inviter.image,
      })
    } else if (referral.details) {
      setSignUpValidation({
        type: 'referral',
        code: referral.details.code,
        name: referral.details.referrer.name,
        image: referral.details.referrer.image,
      })
    }
  }, [invite, referral])

  const [walletUserType, setWalletUserType] =
    useState<WalletAnalyzerUserTypeEnum>(WalletAnalyzerUserTypeEnum.UpAndComer)
  const [walletAnalyzerTitle, setWalletAnalyzerTitle] = useState<string>('')
  const [walletAnalyzerMeta, setWalletAnalyzerMeta] = useState<
    components['schemas']['WalletAnalyzerMeta'] | undefined
  >(undefined)
  const [walletAnalyzerComments, setWalletAnalyzerComments] = useState<
    string[]
  >([''])
  const [walletAnalyzerBonusPoints, setWalletAnalyzerBonusPoints] = useState<
    { points: number; address: string }[]
  >([])

  useEffect(() => {
    setUserIsOnSignUpScreen(false)
    setHasClickedSignUpLater(false)
  }, [account])

  // useEffect(() => {
  // if (
  // !isLoadingAnalyzedWallet &&
  // allMigrationPoints &&
  // Object.keys(allMigrationPoints).length > 0
  // ) {
  // setUserIsOnWalletAnalyzer(true)
  // setUserIsOnSignUpScreen(false)
  // }
  // }, [allMigrationPoints, isLoadingAnalyzedWallet])

  useEffect(() => {
    Object.keys(allMigrationPoints).forEach((wallet) => {
      const { points, isLoading } = allMigrationPoints[wallet]

      if (!isLoading && points) {
        const eligiblePoints = points.points?.eligiblePoints || 0

        setWalletAnalyzerMeta(points.meta)

        if (eligiblePoints > 2000000) {
          setWalletUserType(WalletAnalyzerUserTypeEnum.LendingLegend)
          setWalletAnalyzerTitle('You have bonus points to unlock!')
          setWalletAnalyzerComments([
            'You’ve received bonus points for being an active DeFi lender. You can add your other wallets, they may also have points!',
          ])
          setWalletAnalyzerBonusPoints((prevState) => [
            ...prevState,
            { points: eligiblePoints, address: shortenAddress(wallet) },
          ])
        } else if (eligiblePoints > 500000) {
          setWalletUserType(WalletAnalyzerUserTypeEnum.DefiVeteran)
          setWalletAnalyzerTitle('You are a DeFi Veteran!')
          setWalletAnalyzerComments([
            'You’ve received points for your experience in DeFi. Consider adding more wallets to see if they have points too!',
          ])
          setWalletAnalyzerBonusPoints((prevState) => [
            ...prevState,
            { points: eligiblePoints, address: shortenAddress(wallet) },
          ])
        } else {
          setWalletUserType(WalletAnalyzerUserTypeEnum.UpAndComer)
          setWalletAnalyzerTitle('You have potential...')
          setWalletAnalyzerComments([
            'This wallet has no bonus points.',
            'If you have another active wallet, try that instead.',
          ])
          setWalletAnalyzerBonusPoints((prevState) => [
            ...prevState,
            { points: eligiblePoints, address: shortenAddress(wallet) },
          ])
        }
      }
    })
  }, [allMigrationPoints])

  const walletAnalyzerInfo = useMemo(() => {
    return {
      isAnimating: isAnimatingWalletAnalyzer,
      userType: walletUserType,
      title: walletAnalyzerTitle,
      comments: walletAnalyzerComments,
      bonusPoints: walletAnalyzerBonusPoints,
      meta: walletAnalyzerMeta,
    }
  }, [
    isAnimatingWalletAnalyzer,
    walletUserType,
    walletAnalyzerTitle,
    walletAnalyzerComments,
    walletAnalyzerBonusPoints,
    walletAnalyzerMeta,
  ])

  const [hasClickedContinueToDashboard, setHasClickedContinueToDashboard] =
    useState(false)

  const closeWalletAnalyzer = useCallback(() => {
    console.log('Closing wallet analyzer')
    setUserIsOnWalletAnalyzer(false)
    setHasClickedContinueToDashboard(true)
    if (isSignUpModalOpen) {
      onSignUpModalClose()
    }
  }, [isSignUpModalOpen, onSignUpModalClose])

  // if wallet not connected -> show connect screen
  //
  // if wallet is connected + profile + token -> show rewards page
  // if wallet is connected + profile + no token -> show rewards page + open sign in modal
  //
  // if wallet is connected + no profile + preseason -> show login screen with preseason info
  // if wallet is connected + no profile + referral code -> show sign-up screen (bypass invite code screen)
  // if wallet is connected + no profile + no preseason + no referral code -> show invite code screen

  /**
   * VIEW LOGIC
   */

  // default state
  const [userIsOnSignUpScreen, setUserIsOnSignUpScreen] = useState(false)

  useEffect(() => {
    console.debug(
      'user is on sign up screen has changed: %o',
      userIsOnSignUpScreen
    )
  }, [userIsOnSignUpScreen])

  const [hasClickedSignUpLater, setHasClickedSignUpLater] = useState(false)
  const signUpLater = useCallback(() => {
    console.debug('user clicked sign up later')
    if (account) {
      // analyze wallet if the user doesn't create a profile
      analyzeWallet(account, true, false)
    }
    setHasClickedSignUpLater(true)
    setUserIsOnSignUpScreen(false)
  }, [account, analyzeWallet])

  const navigateBackToInviteCode = useCallback(() => {
    console.debug('navigating back to invite code')
    resetInviteDetails()
    setView(RewardsPageViewEnum.InviteCode)
    setUserIsOnSignUpScreen(false)
  }, [resetInviteDetails])

  const navigateToSignUp = useCallback(() => {
    console.debug('navigating to sign up')
    setView(RewardsPageViewEnum.SignUp)
    setHasClickedSignUpLater(false)
    setUserIsOnSignUpScreen(true)
  }, [])

  const navigation = useMemo(() => {
    return {
      onNavigateToAuctions: () => navigate('/'),
      onNavigateToInvite: () => navigate('/rewards/invite'),
    }
  }, [navigate])

  const [view, setView] = useState<RewardsPageViewEnum | undefined>(undefined)

  useEffect(() => {
    console.debug('view has changed to: %o', view)
  }, [view])

  // Invite / Sign Up / Sign In / Wallet Analyzer logic
  useEffect(() => {
    if (!currentProfileId && !account && isSocialLoading) {
      console.log('come back from linking')
      setView(undefined)
      return
    }

    if (!currentProfileId && !account) {
      console.debug('user wallet is not connected')
      setView(RewardsPageViewEnum.NotConnected)
      return
    }

    if (!currentProfileId && !publicProfileData) {
      console.debug('public profile data: %o', publicProfileData)
      setView(undefined)
      return
    }

    if (
      isSocialLoading &&
      ((twitterData?.action &&
        (twitterData.action === SocialActions.SignUpLinkTwitter ||
          twitterData.action === SocialActions.SignUpLinkTwitterModal)) ||
        (discordData?.action &&
          (discordData.action === SocialActions.SignUpLinkDiscord ||
            discordData.action === SocialActions.SignUpLinkDiscordModal)))
    ) {
      console.debug('user redirected from social and data still loading')
      setView(undefined)
      return
    }

    if (
      currentProfileId &&
      !userIsOnWalletAnalyzer &&
      !hasClickedContinueToDashboard &&
      ((twitterData?.action &&
        twitterData.action === SocialActions.SignUpLinkTwitter) ||
        (discordData?.action &&
          discordData.action === SocialActions.SignUpLinkDiscord))
    ) {
      console.debug(
        'user has a profile but is redirected during sign up from social: %o, %o',
        twitterData,
        discordData
      )
      setUserIsOnSignUpScreen(true)
      setView(RewardsPageViewEnum.SignUp)
      return
    }

    if (currentProfileId && !userIsOnSignUpScreen && !userIsOnWalletAnalyzer) {
      console.debug(
        'user has a profile and is not on sign up screen or wallet analyzer'
      )
      setView(RewardsPageViewEnum.Dashboard)
      return
    }

    // User wallet is connected
    if (account) {
      console.debug('inside view logic, account connected: %o', account)
      console.debug('using this public profile data: %o', publicProfileData)
      console.debug('is user on sign up screen: %o', userIsOnSignUpScreen)
      console.debug('is user on wallet analyzer: %o', userIsOnWalletAnalyzer)

      if (
        (isLinkWalletModalOpen || isSwitchProfileModalOpen) &&
        !userIsOnWalletAnalyzer
      ) {
        console.debug('user is linking wallet or switching profile')
        setView(RewardsPageViewEnum.Dashboard)
        return
      }

      if (userIsOnWalletAnalyzer) {
        console.debug('user is on wallet analyzer')
        setView(RewardsPageViewEnum.WalletAnalyzer)
        return
      }

      if (
        view === RewardsPageViewEnum.WalletAnalyzer &&
        !hasClickedContinueToDashboard
      ) {
        console.debug('user is viewing wallet analyzer')
        return
      }

      if (
        view === RewardsPageViewEnum.WalletAnalyzer &&
        hasClickedContinueToDashboard
      ) {
        console.debug('user moving from wallet analyzer to dashboard')
        setView(RewardsPageViewEnum.Dashboard)
        return
      }

      // User has a profile
      if (
        !!publicProfileData?.profileExists &&
        !userIsOnSignUpScreen &&
        !userIsOnWalletAnalyzer
      ) {
        console.debug('user has a profile')
        setView(RewardsPageViewEnum.Dashboard)
        return
      }

      // User does not have a profile but has a valid referral code
      if (!publicProfileData?.profileExists && referral.details) {
        console.debug(
          'user does not have a profile but has a valid referral code'
        )
        setUserIsOnSignUpScreen(true)
        setView(RewardsPageViewEnum.SignUp)
        return
      }

      // User does not have a profile and has no preseason points and has entered a valid invite code
      if (
        !publicProfileData?.profileExists &&
        !hasPreseasonPoints &&
        invite.details
      ) {
        console.debug(
          'user does not have a profile and has no preseason points and has a valid invite code'
        )
        setUserIsOnSignUpScreen(true)
        setView(RewardsPageViewEnum.SignUp)
        return
      }

      // User does not have a profile and has no preseason points
      if (!publicProfileData?.profileExists && !hasPreseasonPoints) {
        console.debug('public profile data: %o', publicProfileData)
        console.debug(
          'user does not have a profile and has no preseason points'
        )
        setView(RewardsPageViewEnum.InviteCode)
        return
      }

      // User does not have a profile but has preseason points and has clicked sign up later
      if (
        !publicProfileData?.profileExists &&
        hasPreseasonPoints &&
        hasClickedSignUpLater
      ) {
        console.debug(
          'user does not have a profile but has preseason points and has clicked sign up later'
        )
        setView(RewardsPageViewEnum.Dashboard)
        return
      }

      // User does not have a profile but has preseason points
      if (
        !publicProfileData?.profileExists &&
        hasPreseasonPoints &&
        !userIsOnWalletAnalyzer
      ) {
        console.debug('user does not have a profile but has preseason points')
        // setUserIsOnSignUpScreen(true)
        setView(RewardsPageViewEnum.SignUp)
        return
      }
      // User wallet is not connected
    }
  }, [
    // view commented out to prevent infinite loop
    account,
    currentProfileId,
    publicProfileData,
    isLinkWalletModalOpen,
    isSwitchProfileModalOpen,
    hasClickedSignUpLater,
    userIsOnSignUpScreen,
    hasPreseasonPoints,
    invite.details,
    profileData,
    referral.details,
    token,
    hasClickedContinueToDashboard,
    userIsOnWalletAnalyzer,
    twitterData,
    discordData,
    isSocialLoading,
  ])

  const [isSwitchProfileViewOnly, setIsSwitchProfileViewOnly] = useState(false)
  const closeSignInModal = useCallback(
    (token?: string) => {
      console.log('closing sign-in modal')
      onSignInModalClose()
      if (token) {
        console.debug('closing sign-in modal and setting token')
        storage.setItem('profile-token', token)
        setToken(token)
        const newProfileId = decodeJWTPayload(token)?.sub
        setCurrentProfileId(newProfileId)
      }
      if (isSwitchProfileViewOnly) {
        clearToken()
        setIsSwitchProfileViewOnly(false)
      }
    },
    [clearToken, isSwitchProfileViewOnly, onSignInModalClose, storage]
  )

  useEffect(() => {
    if (
      prevProfileIdRef.current &&
      currentProfileId &&
      currentProfileId !== prevProfileIdRef.current
    ) {
      termToast.success({
        title: 'Profile switched',
        children: 'Your profile has been successfully switched.',
      })
    }
  }, [currentProfileId, termToast])

  const linkWallet = useMemo(() => {
    return {
      isOpen: isLinkWalletModalOpen,
      onClose: onLinkWalletModalClose,
      onSignUp: () => {
        setResetSignUpSteps({})
        console.log(
          'user clicked sign up from link wallet - logging them out of current session'
        )
        clearToken()
        onLinkWalletModalClose()
      },
    }
  }, [clearToken, isLinkWalletModalOpen, onLinkWalletModalClose])

  const switchProfile = useMemo(() => {
    return {
      isOpen: isSwitchProfileModalOpen,
      onClose: onSwitchProfileModalClose,
      onSwitchProfile: () => {
        console.log('switching profile')
        const prevProfileId = token ? decodeJWTPayload(token)?.sub : undefined
        prevProfileIdRef.current = prevProfileId
        setIsSwitchProfileViewOnly(true)
        onSignInModalOpen()
        onSwitchProfileModalClose()
      },
    }
  }, [
    isSwitchProfileModalOpen,
    onSignInModalOpen,
    onSwitchProfileModalClose,
    token,
  ])

  const isProfileApiLoading = profileApiIsLoading || publicProfileApiIsLoading
  const hasError = profileApiError || publicProfileApiError

  // logic to trigger link wallet modal when account changes
  useEffect(() => {
    // if user has switched wallet again back to a wallet that exists in an account then close these modals if they are open
    const closeOpenModals = () => {
      if (isLinkWalletModalOpen) {
        onLinkWalletModalClose()
      }
      if (isSwitchProfileModalOpen) {
        onSwitchProfileModalClose()
      }
    }

    if (
      !account ||
      !profileData ||
      !publicProfileData ||
      isProfileApiLoading ||
      hasError
    )
      return

    const lowerAccount = account.toLowerCase()

    const accountExistsInProfile = profileData.wallets.some(
      (wallet) => wallet.address === lowerAccount
    )
    const profileMatchesCurrentId =
      publicProfileData?.profile === Number(currentProfileId)
    const profileExists = publicProfileData?.profileExists

    if (accountExistsInProfile) {
      console.debug(
        'switchProfile - account changed, new account is already in profile'
      )
      closeOpenModals()
      return
    }

    if (profileMatchesCurrentId) {
      console.debug(
        'switchProfile - account changed, new account has matching profile id'
      )
      closeOpenModals()
      return
    }

    if (profileExists) {
      console.debug(
        'switch profile - public profile data looked at here: %o',
        publicProfileData
      )
      console.debug('switchProfile - opening switch profile modal')
      onOpenSwitchProfileModal()
      return
    } else {
      console.debug(
        'link wallet - public profile data looked at here: %o',
        publicProfileData
      )
      console.debug('switchProfile - opening switch wallet modal')
      onOpenLinkWalletModal()
      return
    }
  }, [
    account,
    currentProfileId,
    hasError,
    isProfileApiLoading,
    profileData,
    publicProfileData,
  ])

  // ==========================================================================
  // DELETE PROFILE / REMOVE INFO LOGIC =======================================
  // ==========================================================================
  // #region

  // if user signed in via social but is not connected through wallet - sign them out
  const socialDelinkCleanup = useCallback(() => {
    if (token && !account) {
      console.warn(
        'user logged in via social method with no wallet connected - logging out...'
      )
      clearToken()
      navigate('/')
      return
    }
    refreshProfileData()
  }, [token, account, refreshProfileData, clearToken, navigate])

  const { trigger: deleteProfileData } = useDeleteProfileData(() => {
    clearToken()
    setPublicProfileData(undefined)
    setResetSignUpSteps({})
    navigate('/')
  })
  const { trigger: removeTwitterFromProfile } =
    useRemoveTwitterFromProfile(socialDelinkCleanup)
  const { trigger: removeDiscordFromProfile } =
    useRemoveDiscordFromProfile(socialDelinkCleanup)
  const { trigger: detachWalletFromProfile } =
    useRemoveWalletFromProfile(refreshProfileData)

  const onDeleteProfile = useCallback(() => {
    if (token) {
      deleteProfileData(token)
    } else {
      console.error('no profile token found, unable to delete profile')
    }
  }, [deleteProfileData, token])

  const onRemoveTwitterFromProfile = useCallback(() => {
    if (token) {
      removeTwitterFromProfile(token)
    } else {
      console.error('no profile token found, unable to delete profile')
    }
  }, [removeTwitterFromProfile, token])

  const onRemoveDiscordFromProfile = useCallback(() => {
    if (token) {
      removeDiscordFromProfile(token)
    } else {
      console.error('no profile token found, unable to delete profile')
    }
  }, [removeDiscordFromProfile, token])

  const onDetachWalletFromProfile = useCallback(
    (walletAddress: string) => {
      if (
        token &&
        account &&
        (walletAddress !== account ||
          (profileData?.wallets &&
            profileData.wallets.length > 1 &&
            profileData.wallets.some(
              (wallet) => wallet.address === account && !wallet.isPrimary
            )))
      ) {
        detachWalletFromProfile(token, walletAddress)
      } else {
        console.error('no profile token found, unable to delete profile')
      }
    },
    [account, detachWalletFromProfile, profileData?.wallets, token]
  )
  // #endregion

  // ==========================================================================
  // UPDATE PROFILE LOGIC =====================================================
  // ==========================================================================
  // #region
  const {
    // isLoading: updateProfileIsLoading,
    update: updateProfile,
    // error: profileUpdateError,
  } = useProfileUpdate(refreshProfileData)

  const onUpdateProfile = useCallback(
    async (task: string, value: boolean) => {
      try {
        if (!token) {
          throw new Error('No token found')
        }
        const result = await updateProfile({
          token,
          uniqueTasks: {
            [task]: value,
          },
        })

        if (!result.success) {
          console.error('result error: %o', result?.error)
          throw new Error('Error updating profile: %o')
        } else {
          // termToast.success({
          //   title: 'Profile updated',
          //   children: 'Your profile has been successfully updated.',
          // })
        }
      } catch (e) {
        console.error('Error updating profile: %o', e)
        termToast.failure({
          title: 'Error updating profile',
          children: 'Please try again.',
        })
      }
    },
    [token, updateProfile, termToast]
  )
  // #endregion

  const onRetweet = useCallback(
    (retweet: EligibleRetweets) => {
      let url: string
      if (retweet === 'walletAnalyzer') {
        url = constructRetweetUrl(RewardedTweet.walletAnalyzerTweet)
        onUpdateProfile('hasRetweetedWalletAnalyzerAnnouncement', true)
      }
      if (retweet === 'vaultsAnnouncement') {
        url = constructRetweetUrl(RewardedTweet.vaultAnnouncementTweet)
        onUpdateProfile('hasRetweetedVaultsAnnouncement', true)
      } else {
        url = constructRetweetUrl(RewardedTweet.launchTweet)
        onUpdateProfile('hasRetweetedRewardsAnnouncement', true)
      }
      window.open(url, '_blank', 'noreferrer')
    },
    [onUpdateProfile]
  )

  // if user is already logged in then we don't need to wait for the public profile data
  const isPageLoading = useMemo(() => {
    if (isSocialLoading) {
      return true
    }
    if (userIsOnSignUpScreen) {
      return false
    }
    if (isProfileApiLoading) {
      return true
    }
    if (currentProfileId) {
      return !profileData
    } else {
      return !publicProfileData
    }
  }, [
    isSocialLoading,
    currentProfileId,
    isProfileApiLoading,
    profileData,
    publicProfileData,
    userIsOnSignUpScreen,
  ])

  const profileImageUrl = useMemo(() => {
    return profileData?.profile?.twitterData?.profile_image_url
      ? profileData?.profile?.twitterData?.profile_image_url
      : profileData?.profile?.discordData?.id &&
          profileData?.profile?.discordData?.avatar
        ? constructDiscordAvatarUrl(
            profileData?.profile?.discordData?.id,
            profileData?.profile?.discordData?.avatar
          )
        : profileData?.profile?.meta?.accountPhoto
          ? profileData?.profile?.meta?.accountPhoto
          : undefined
  }, [profileData])

  const accountExistsInProfile = useMemo(
    () =>
      !!account?.toLowerCase() &&
      !!profileData?.wallets.some(
        (wallet) => wallet.address === account.toLowerCase()
      ),
    [account, profileData]
  )

  const connectedSocials = useMemo(() => {
    return {
      discord: profileData
        ? !!profileData?.profile?.discordId
        : !!publicProfileData?.hasLinkedDiscord,
      twitter: profileData
        ? !!profileData?.profile?.twitterId
        : !!publicProfileData?.hasLinkedTwitter,
    }
  }, [profileData, publicProfileData])

  const onLinkTwitter = useCallback(
    (redirPath?: string) => {
      oauth2Authorize({
        navigate: externalNavigate,
        authorizeUrl: config.oauth.twitter.authUrl,
        clientId: config.oauth.twitter.clientId,
        redirectUri: config.oauth.twitter.redirectUri,
        scope: config.oauth.twitter.scopes,
        state: encodeState({
          timestamp: dayjs().format(),
          path: redirPath ?? '',
        }),
      })
      // This then needs to be handled on-load of the modal itself. The modal should
      // check if the login was successful or not (display this to the user) and if
      // successful, the user's auth-code will be available in application state (see
      // <TermApp />). This also requires that the modal be open-able by loading a
      // specific path. There are five loading states that must be accounted for:
      // - Regular load (from elsewhere within the term app)
      // - Callback from twitter containing successful auth
      // - Callback from twitter containing failed auth
      // - Callback from discord containing successful auth
      // - Callback from discord containing failed auth
    },
    [externalNavigate]
  )

  const onLinkDiscord = useCallback(
    async (redirPath?: string) => {
      const codeVerifier = `${v4()}${v4()}`.replace(/-/g, '')
      window.sessionStorage.setItem('discord-code-verifier', codeVerifier) // Needed to exchange code for token later on
      const cvMsgBuffer = new TextEncoder().encode(codeVerifier)
      const cvHashBuffer = await window.crypto.subtle.digest(
        'SHA-256',
        cvMsgBuffer
      )
      const codeChallenge = btoa(
        String.fromCharCode(...new Uint8Array(cvHashBuffer))
      )
        .replace(/\+/g, '-')
        .replace(/\//g, '_')
        .replace(/=+$/, '')
      const codeChallengeMethod = 'S256'

      oauth2Authorize({
        navigate: externalNavigate,
        authorizeUrl: config.oauth.discord.authUrl,
        clientId: config.oauth.discord.clientId,
        redirectUri: config.oauth.discord.redirectUri,
        scope: config.oauth.discord.scopes,
        state: encodeState({
          timestamp: dayjs().format(),
          path: redirPath ?? '',
        }),
        responseType: 'code',
        codeChallenge,
        codeChallengeMethod,
      })
      // This then needs to be handled on-load of the modal itself. The modal should
      // check if the login was successful or not (display this to the user) and if
      // successful, the user's auth-code will be available in application state (see
      // <TermApp />). This also requires that the modal be open-able by loading a
      // specific path. There are five loading states that must be accounted for:
      // - Regular load (from elsewhere within the term app)
      // - Callback from twitter containing successful auth
      // - Callback from twitter containing failed auth
      // - Callback from discord containing successful auth
      // - Callback from discord containing failed auth
    },
    [externalNavigate]
  )

  const pageData = useMemo(() => {
    const params: RewardsPageParams = {
      isLoading: isPageLoading,
      view: view,
      account,
      accountExistsInProfile,
      isWalletConnected: !!account,
      connectedSocials,
      profileName:
        profileData?.profile?.twitterId ??
        profileData?.profile?.discordId ??
        profileData?.profile?.meta?.accountName ??
        'Term User',
      profileImageUrl,
      linkWallet,
      switchProfile,
      isLoggedIn: !!currentProfileId,
      isSwitchProfileViewOnly,
      hasProfile: !!profileData || !!publicProfileData?.profileExists,
      hasPreseasonPoints,
      fullProfile: profileData,
      publicProfile: publicProfileData,
      totalNumberOfAuctions,
      totalLoans,
      numOfActiveLoans,
      totalCollateral,
      totalBorrows,
      walletNetPositions,
      validatedInviteCode: lastValidatedInviteCode,
      currentSeason,
      numberOfWeeks,
      migrationPoints: account
        ? allMigrationPoints?.[account]?.points
        : undefined,
      isSignInModalOpen,
      isSignUpModalOpen,
      hasClickedSignUpLater: hasClickedSignUpLater,
      walletAnalyzer: walletAnalyzerInfo,
      completedRetweets: {
        [RewardedTweet.launchTweet]:
          !!profileData?.profile?.meta?.uniqueTasks
            ?.hasRetweetedRewardsAnnouncement,
        [RewardedTweet.walletAnalyzerTweet]:
          !!profileData?.profile?.meta?.uniqueTasks
            ?.hasRetweetedWalletAnalyzerAnnouncement,
        [RewardedTweet.vaultAnnouncementTweet]:
          !!profileData?.profile?.meta?.uniqueTasks
            ?.hasRetweetedVaultsAnnouncement,
      },
      signUpStatus: {
        wallet: walletSignUpStatus,
        twitter: twitterSignUpRedirectStatus,
        discord: discordSignUpRedirectStatus,
        hasSkippedTwitter,
      },
      resetSignUpSteps,
      onLogout: clearToken,
      onNavigateBackToInviteCode: navigateBackToInviteCode,
      onNavigateToSignUp: navigateToSignUp,
      onNavigateToAuctions: navigation.onNavigateToAuctions,
      onNavigateToInvites: navigation.onNavigateToInvite,
      onSignInModalOpen: onSignInModalOpen,
      onSignInModalClose: closeSignInModal,
      onSignUpModalOpen: onOpenSignUpModal,
      onSignUpModalClose: onSignUpModalClose,
      onSignUpLater: signUpLater,
      onClaimSeasonRewards: () => {
        console.info('clicked') // TODO,
      },
      onRetweet,
      onConnect,
      onDeleteProfile: onDeleteProfile,
      onRemoveTwitter: onRemoveTwitterFromProfile,
      onRemoveDiscord: onRemoveDiscordFromProfile,
      onRemoveWallet: onDetachWalletFromProfile,
      onSignMessage: getSignInSignature,
      onAnalyzeWallet: triggerWalletAnalyzer,
      onCloseWalletAnalyzer: closeWalletAnalyzer,
      onValidateInviteCode: validateInviteCode,
      onValidateReferralCode: validateReferralCode,
      onLinkWalletNewUser: signUpWithWallet,
      onLinkWallet: linkWalletToProfile,
      onLinkExtraWallet: onLinkExtraWallet,
      onLinkMultiSigWallet: linkMultiSigWalletToProfile,
      onSignInWithWallet: authenticateWithWallet,
      onLinkDiscord,
      onLinkTwitter,
      onUpdateProfile,
      signUpValidation,
      profile,
    }
    return params
  }, [
    isPageLoading,
    accountExistsInProfile,
    publicProfileData,
    view,
    currentProfileId,
    connectedSocials,
    resetSignUpSteps,
    account,
    profileImageUrl,
    linkWallet,
    switchProfile,
    hasPreseasonPoints,
    profileData,
    totalNumberOfAuctions,
    totalLoans,
    numOfActiveLoans,
    totalCollateral,
    totalBorrows,
    walletNetPositions,
    lastValidatedInviteCode,
    currentSeason,
    numberOfWeeks,
    allMigrationPoints,
    isSignInModalOpen,
    isSwitchProfileViewOnly,
    hasClickedSignUpLater,
    walletAnalyzerInfo,
    navigateBackToInviteCode,
    navigateToSignUp,
    navigation.onNavigateToAuctions,
    navigation.onNavigateToInvite,
    onSignInModalOpen,
    linkWalletToProfile,
    onLinkExtraWallet,
    linkMultiSigWalletToProfile,
    signUpWithWallet,
    authenticateWithWallet,
    closeSignInModal,
    signUpLater,
    onConnect,
    onUpdateProfile,
    triggerWalletAnalyzer,
    closeWalletAnalyzer,
    validateInviteCode,
    validateReferralCode,
    signUpValidation,
    profile,
    onRetweet,
    clearToken,
    walletSignUpStatus,
    twitterSignUpRedirectStatus,
    discordSignUpRedirectStatus,
    hasSkippedTwitter,
    isSignUpModalOpen,
    onOpenSignUpModal,
    onSignUpModalClose,
    onDeleteProfile,
    onDetachWalletFromProfile,
    onRemoveDiscordFromProfile,
    onRemoveTwitterFromProfile,
    onLinkTwitter,
    onLinkDiscord,
  ])

  // return RewardsPageViewModel(pageData)
  return pageData
}
