import { FallbackProvider, JsonRpcProvider } from '@ethersproject/providers'
import { BigNumber, FixedNumber, Signer } from 'ethers'
import { Address, Currency, MetaVaultStrategyAllocations } from '../data/model'
import { MetaVaultPageParams } from '../models/metaVault'
import { useChainConfig, useChainConfigs, useConfig } from '../providers/config'
import { useNavigate, useParams } from 'react-router-dom'
import { useEffect, useMemo, useState } from 'react'
import {
  DocumentType,
  getQueryDocument,
  getQueryVariables,
} from '../managers/subgraphManager'
import {
  useBucketedCurrentTime,
  useGraphQueries,
  useGraphQuery,
  useIdleVaultRates,
  useSentioData,
} from '../data/hooks/helper-hooks'
import {
  PageMetaVaultQuery,
  PageMetaVaultQueryVariables,
} from '../gql/vaults/graphql'
import { useGlobalRefresher } from '../providers/refresher'
import { calculateCurrentYield, mapSentioAPIResults } from '../helpers/utils'
import { useMetaVault } from '../data/hooks/use-meta-vault'
import { useVaults } from '../data/hooks/use-vaults'
import { useVaultsMappings } from '../data/hooks/use-vaults-mappings'
import { mapVaultsAndMetaVaults, mergeAPYData } from '../pages/Vaults/utils'
import {
  MetaVaultData,
  VaultData,
  VaultsMapping,
  VaultStrategy,
} from '../models/vaults'
import { useTokenWrapper } from '../data/hooks/use-token-wrapper'
import { useNativeTokenBalance } from '../data/hooks/use-native-token-balance'
import { useBalances } from '../data/hooks/use-balances'
import { useCurrencies } from '../data/hooks/use-currencies'
import { usePrices } from '../data/hooks/use-prices'
import { bigToFixedNumber } from '../helpers/conversions'
import { useConvertToAssets } from '../data/hooks/use-convert-to-assets'
import { calculateMetaVaultStrategyAllocation } from '../pages/MetaVault/utils'
import { useTokenApprove } from '../data/hooks/use-token-approve'
import { useAllowances } from '../data/hooks/use-allowances'
import {
  TermRepoTokenHoldingsQuery,
  TermRepoTokenHoldingsQueryVariables,
  VaultOpenOffersQuery,
  VaultOpenOffersQueryVariables,
} from '../gql/graphql'
import { useVaultRepoTokenHoldings } from '../data/hooks/use-vault-repo-token-holdings'
import { VaultHolding } from '../models/vault'
import { add, divide, multiply, subtract } from '../helpers/math'
import { useMetaVaultSingleStrategyBalances } from '../data/hooks/use-meta-vault-single-strategy-balances'

export const composeMetaVaultPageViewModel = (
  chainId: string,
  metaVaultAddress: Address,
  vaultsMapping: VaultsMapping | undefined,
  rawVaults: VaultStrategy | undefined,
  sentioAPIResults: {
    [chainId: string]: {
      [strategyAddress: Address]: {
        price: FixedNumber | null | undefined
        timestamp: number
      }
    }
  },
  singleStrategyCurrentPricePerShare: {
    [chainId: string]: {
      [strategyAddress: string]: FixedNumber | null | undefined
    }
  },
  singleStrategyTotalAssetValue: {
    [chainId: string]: {
      [strategyAddress: string]: FixedNumber | null | undefined
    }
  },
  multiStrategyCurrentPricePerShare: FixedNumber | null | undefined,
  multiStrategyTotalAssetValue: FixedNumber | null | undefined,
  metaVaultSingleStrategiesAssetBalances:
    | {
        [strategyAddress: string]:
          | {
              balance: FixedNumber
              asset: Address
            }
          | null
          | undefined
      }
    | undefined,
  connectedWalletConvertedAssetBalance:
    | {
        [strategyAddress: string]:
          | {
              balance: FixedNumber
              asset: Address
            }
          | null
          | undefined
      }
    | undefined,
  multiStrategyIsShutdown: boolean | null | undefined,
  multiStrategyAvailableDepositLimit: FixedNumber | null | undefined,
  multiStrategyAvailableWithdrawLimit: FixedNumber | null | undefined,
  estimatedAPYs:
    | {
        [chainId: string]: {
          [strategyAddress: string]: FixedNumber
        }
      }
    | undefined,
  deposit:
    | ((chainId: string, amount: FixedNumber) => Promise<void>)
    | null
    | undefined,
  withdrawal:
    | ((
        chainId: string,
        amount: FixedNumber,
        maxLoss?: FixedNumber
      ) => Promise<void>)
    | null
    | undefined
): {
  metaVault: MetaVaultData
  singleVaults: VaultData[]
  totalDeposited: FixedNumber | undefined
  userDepositedAssetCurrency: FixedNumber | undefined
  multiStrategyCurrentYield: FixedNumber | undefined
  metaVaultStrategyAllocations: MetaVaultStrategyAllocations
  metaVaultDeposit?: (chainId: string, amount: FixedNumber) => Promise<void>
  metaVaultWithdraw?: (
    chainId: string,
    amount: FixedNumber,
    maxLoss?: FixedNumber
  ) => Promise<void>
} => {
  const singleStrategiesCurrentYield = calculateCurrentYield(
    singleStrategyCurrentPricePerShare,
    sentioAPIResults
  )

  const multiStrategyCurrentYield = calculateCurrentYield(
    { [chainId]: { [metaVaultAddress]: multiStrategyCurrentPricePerShare } },
    sentioAPIResults
  )

  const transformedStrategiesBalances = Object.entries(
    metaVaultSingleStrategiesAssetBalances ?? {}
  ).reduce(
    (acc, [strategyAddress, value]) => {
      if (value && value.balance) {
        acc[strategyAddress] = value.balance
      }
      return acc
    },
    {} as { [strategyAddress: Address]: FixedNumber }
  )

  const metaVaultStrategyAllocations = calculateMetaVaultStrategyAllocation(
    transformedStrategiesBalances,
    vaultsMapping?.vaultDetails?.[chainId]
  )

  const { vaults, metaVaults } = mapVaultsAndMetaVaults(
    new Set([Number(chainId)]),
    rawVaults,
    vaultsMapping,
    {}, // TODO: add receipt token currencies
    estimatedAPYs ?? {},
    { [chainId]: transformedStrategiesBalances },
    {},
    {},
    { [chainId]: metaVaultStrategyAllocations }
  )

  return {
    metaVault: metaVaults[0],
    singleVaults: vaults,
    totalDeposited: multiStrategyTotalAssetValue ?? undefined,
    userDepositedAssetCurrency:
      connectedWalletConvertedAssetBalance?.[metaVaultAddress]?.balance ??
      FixedNumber.fromString('0', `fixed128x18`),
    multiStrategyCurrentYield:
      estimatedAPYs?.[chainId]?.[metaVaultAddress] ?? undefined,
    metaVaultStrategyAllocations,
    metaVaultDeposit: deposit ?? undefined,
    metaVaultWithdraw: withdrawal ?? undefined,
  }
}

export function useMetaVaultPage(
  account: Address | undefined,
  provider: JsonRpcProvider | FallbackProvider | undefined,
  signer: Signer | undefined,
  onCheckActiveNetwork: (
    chainId?: number,
    chainName?: string
  ) => Promise<boolean>,
  onConnect: () => void,
  onKytCheck: () => Promise<boolean>
) {
  const config = useConfig()
  const chainConfigs = useChainConfigs()
  const { metaVaultAddress, chainId } = useParams()
  const navigate = useNavigate()

  if (metaVaultAddress === undefined) {
    throw new Error(
      'Vault component may only be used within a route with id param'
    )
  }

  if (chainId === undefined) {
    throw new Error(
      'Vault component may only be used within a route with chain param'
    )
  }

  const parsedChainId = Number(chainId)
  if (isNaN(parsedChainId)) {
    throw new Error(`Invalid value provided for ${parsedChainId}`)
  }

  const chainData = useChainConfig(parsedChainId)

  if (!chainData) {
    throw new Error(`Invalid chain id ${parsedChainId} - no chain config found`)
  }

  const [vaultsSubgraphQuery, setVaultsSubgraphQuery] = useState<any>({})
  useEffect(() => {
    const subgraphVersion = chainData.getSubgraphVersion()
    const queryDoc = getQueryDocument(
      subgraphVersion,
      DocumentType.PAGE_META_VAULT
    )
    const queryVariables = getQueryVariables({
      subgraphVersion,
      docType: DocumentType.PAGE_META_VAULT,
      variables: {
        id: metaVaultAddress,
      },
    })
    const query = {
      chainId: parsedChainId,
      url: chainData.vaultsSubgraphUrl,
      query: queryDoc,
      variables: queryVariables,
    }
    setVaultsSubgraphQuery(query)
  }, [account, metaVaultAddress, chainData, parsedChainId])

  const {
    results: vaultSubgraphData,
    fetching,
    error,
    refresh: readFromVaultsSubgraph,
  } = useGraphQuery<PageMetaVaultQuery, PageMetaVaultQueryVariables>(
    vaultsSubgraphQuery
  )

  const { slow: autoRefresher } = useGlobalRefresher()
  useEffect(() => {
    readFromVaultsSubgraph()
  }, [readFromVaultsSubgraph, autoRefresher])

  const {
    // proposerAddress,
    // governorAddress,
    // delayModifierAddress,
    metaVaultAssetCurrency,
    assetDecimalsByChain,
    singleStrategyAddressesByChain,
    rawVaults,
  } = useMemo(() => {
    if (!vaultSubgraphData) {
      return {
        proposerAddress: undefined,
        governorAddress: undefined,
        delayModifierAddress: undefined,
        singleStrategyAddresses: [],
        metaVaultAssetCurrency: undefined,
        assetDecimalsByChain: undefined,
        singleStrategyAddressesByChain: undefined,
        rawVaults: { [chainId]: [] } as VaultStrategy,
        metaVaultInfo: undefined,
      }
    } else {
      const rawVaults = {
        [chainId]:
          vaultSubgraphData?.termMultiStrat?.strategies
            ?.filter(
              (strat) =>
                !config.chains[chainId].vaultsToFilterOut.includes(
                  strat.id.toLowerCase()
                )
            )
            ?.map((strat) => {
              return {
                isMetaVault: false,
                id: strat.id.toLowerCase(),
                assocMetaVaultAddress: metaVaultAddress,
                collateralTokens: strat.collateralTokens.map((token) => {
                  return {
                    id: token.id,
                    name: token.name,
                    symbol: token.symbol,
                    decimals: token.decimals,
                  }
                }),
                asset: {
                  id: strat.asset.id,
                  name: strat.asset.name,
                  symbol: strat.asset.symbol,
                  decimals: strat.asset.decimals,
                },
              }
            }) ?? [],
      } as VaultStrategy

      rawVaults?.[chainId]?.push({
        isMetaVault: true,
        id: vaultSubgraphData?.termMultiStrat?.id.toLowerCase() ?? '',
        collateralTokens: [],
        asset: {
          id: vaultSubgraphData?.termMultiStrat?.asset?.id ?? '',
          name: vaultSubgraphData?.termMultiStrat?.asset?.name ?? '',
          symbol: vaultSubgraphData?.termMultiStrat?.asset?.symbol ?? '',
          decimals: vaultSubgraphData?.termMultiStrat?.asset?.decimals ?? 18,
        },
      })

      return {
        proposerAddress:
          vaultSubgraphData?.termMultiStrat?.proposer?.toLowerCase(),
        // TODO confirm if governor is needed
        governorAddress: undefined,
        delayModifierAddress: vaultSubgraphData?.termMultiStrat?.delay ?? '',
        metaVaultAssetCurrency: {
          address: vaultSubgraphData?.termMultiStrat?.asset?.id ?? '',
          symbol: vaultSubgraphData?.termMultiStrat?.asset?.symbol ?? '',
          decimals:
            Number(vaultSubgraphData?.termMultiStrat?.asset?.decimals) ?? 18,
          isRepoToken: false,
        } as Currency,
        assetDecimalsByChain: {
          [chainId]: vaultSubgraphData?.termMultiStrat?.strategies
            ?.filter(
              (strat) =>
                !config.chains[chainId].vaultsToFilterOut.includes(
                  strat.id.toLowerCase()
                )
            )
            ?.map((strategy) => Number(strategy.asset.decimals) ?? 18),
        },
        singleStrategyAddressesByChain: {
          [chainId]:
            vaultSubgraphData?.termMultiStrat?.strategies
              ?.filter(
                (strat) =>
                  !config.chains[chainId].vaultsToFilterOut.includes(
                    strat.id.toLowerCase()
                  )
              )
              ?.map((strategy) => strategy.id.toLowerCase()) ?? [],
        },
        rawVaults,
      }
    }
  }, [config.chains, chainId, metaVaultAddress, vaultSubgraphData])

  const { data: sentioData } = useSentioData([chainId])

  const mappedAssetDecimalsPerStrategy = useMemo(
    () => ({
      [chainId]: Object.fromEntries(
        rawVaults[chainId].map((vault) => {
          return [vault.id, Number(vault.asset.decimals)]
        })
      ),
    }),
    [rawVaults, chainId]
  )

  const mappedSentioResults = useMemo(() => {
    if (!sentioData) return {}
    return mapSentioAPIResults(sentioData, mappedAssetDecimalsPerStrategy)
  }, [sentioData, mappedAssetDecimalsPerStrategy])

  const {
    currentPricePerShare: multiStrategyCurrentPricePerShare,
    totalAssetValue: multiStrategyTotalAssetValue,
    isShutdown: multiStrategyIsShutdown,
    availableDepositLimit: multiStrategyAvailableDepositLimit,
    availableWithdrawLimit: multiStrategyAvailableWithdrawLimit,
    convertToAssetsRatio,
    deposit,
    withdraw,
  } = useMetaVault(
    account,
    metaVaultAddress,
    chainId,
    signer,
    metaVaultAssetCurrency?.decimals,
    provider,
    readFromVaultsSubgraph
  ) || {}

  const {
    currentPricePerShare: singleStrategyCurrentPricePerShare,
    totalAssetValue: singleStrategyTotalAssetValue,
    totalLiquidBalance: singleStrategyTotalLiquidBalance,
    repoTokenHoldings: singleStrategyRepoTokenHoldingsWithoutValue,
    performanceFee: singleStrategyPerformanceFees,
  } = useVaults(
    account,
    singleStrategyAddressesByChain,
    assetDecimalsByChain,
    signer,
    provider,
    () => {}
  )

  // fetch vault mappings from external source
  const vaultsMapping = useVaultsMappings()

  // Get idle vault capital deployed interest rate
  // #region
  const marketIds = useMemo(() => {
    if (!vaultsMapping?.vaultDetails) {
      return undefined
    }
    const marketIds: string[] = []
    for (const chainId in vaultsMapping.vaultDetails) {
      for (const vaultAddress in vaultsMapping.vaultDetails?.[chainId]) {
        if (
          vaultsMapping.vaultDetails[chainId]?.[vaultAddress]
            ?.idleCapitalDeployed
        ) {
          marketIds.push(
            vaultsMapping.vaultDetails[chainId][vaultAddress]
              .idleCapitalDeployed
          )
        }
      }
    }
    return marketIds
  }, [vaultsMapping])
  const mappedIdleVaultRates = useIdleVaultRates(marketIds)

  // Repo token holdings per vault
  // #region
  const tokenHoldingQueries = useMemo(() => {
    return chainConfigs.map((chainConfig) => {
      const subgraphVersion = chainConfig.getSubgraphVersion()
      const queryDoc = getQueryDocument(
        subgraphVersion,
        DocumentType.TERM_REPO_TOKEN_HOLDINGS
      )
      const repoTokenHoldings =
        singleStrategyRepoTokenHoldingsWithoutValue?.[
          chainConfig.chainId.toString()
        ]
      const uniqueRepoTokens = new Set<Address>()

      Object.entries(repoTokenHoldings ?? []).forEach(([_, tokens]) => {
        if (!tokens) return
        tokens.forEach(({ repoToken }) => {
          if (!uniqueRepoTokens.has(repoToken)) {
            uniqueRepoTokens.add(repoToken)
          }
        })
      })

      const queryVariables = getQueryVariables({
        subgraphVersion,
        docType: DocumentType.TERM_REPO_TOKEN_HOLDINGS,
        variables: {
          termRepoTokens: Array.from(uniqueRepoTokens) ?? [],
        },
      })
      return {
        chainId: chainConfig.chainId,
        url: chainConfig.subgraphUrl,
        query: queryDoc,
        variables: queryVariables,
      }
    })
  }, [chainConfigs, singleStrategyRepoTokenHoldingsWithoutValue])

  const {
    results: repoTokenHoldingSubgraphData,
    // fetching: subgraphFetching,
    // error: subgraphError,
    refresh: readFromSubgraphTokenHoldings,
  } = useGraphQueries<
    TermRepoTokenHoldingsQuery,
    TermRepoTokenHoldingsQueryVariables
  >(tokenHoldingQueries)

  const repoTokensByChain = useMemo(() => {
    if (!repoTokenHoldingSubgraphData) {
      return undefined
    }

    return Object.entries(repoTokenHoldingSubgraphData).reduce(
      (acc, [chainId, result]) => {
        acc[chainId] = result?.termRepos?.reduce(
          (acc, termRepo) => {
            acc[termRepo.termRepoToken] = {
              address: termRepo.termRepoToken,
              decimals: termRepo.termRepoTokenMeta?.decimals ?? 18,
              auctionClearingPrice: bigToFixedNumber(
                termRepo?.completedAuctions?.sort(
                  (a, b) => b.auctionEndTime - a.auctionEndTime
                )?.[0]?.auctionClearingPrice ?? '0',
                18
              ),
            }
            return acc
          },
          {} as {
            [repoTokenAddress: Address]: {
              address: Address
              decimals: number
              auctionClearingPrice: FixedNumber
            }
          }
        )
        return acc
      },
      {} as {
        [chainId: string]: {
          [repoTokenAddress: Address]: {
            address: Address
            decimals: number
            auctionClearingPrice: FixedNumber
          }
        }
      }
    )
  }, [repoTokenHoldingSubgraphData])

  const { repoTokenHoldingsWithValue: repoTokenHoldingsWithValueByChain } =
    useVaultRepoTokenHoldings(
      singleStrategyRepoTokenHoldingsWithoutValue,
      provider
    )
  // #endregion

  // Open auction offers per vault
  // #region

  const currentTime = useBucketedCurrentTime(15)
  const auctionOffersSubgraphQueries = useMemo(() => {
    if (!chainConfigs || !singleStrategyAddressesByChain) {
      return []
    }
    return chainConfigs.map((chainConfig) => {
      const subgraphVersion = chainConfig.getSubgraphVersion()
      const queryDoc = getQueryDocument(
        subgraphVersion,
        DocumentType.VAULT_OPEN_OFFERS
      )
      const queryVariables = getQueryVariables({
        subgraphVersion,
        docType: DocumentType.VAULT_OPEN_OFFERS,
        variables: {
          currentTime: currentTime.unix(),
          vaultAddresses:
            singleStrategyAddressesByChain?.[chainConfig.chainId] ?? [],
        },
      })
      return {
        chainId: chainConfig.chainId,
        url: chainConfig.subgraphUrl,
        query: queryDoc,
        variables: queryVariables,
      }
    })
  }, [chainConfigs, currentTime, singleStrategyAddressesByChain])

  const {
    results: auctionOffersSubgraphData,
    // fetching: auctionOffersFetching,
    // error: auctionOffersError,
    refresh: readFromAuctionOffersSubgraph,
  } = useGraphQueries<VaultOpenOffersQuery, VaultOpenOffersQueryVariables>(
    auctionOffersSubgraphQueries
  )

  useEffect(() => {
    readFromAuctionOffersSubgraph()
  }, [readFromAuctionOffersSubgraph, autoRefresher])

  const openAuctionOffersWithoutPriceByVault: {
    [chainId: string]: { [strategy: Address]: VaultHolding[] }
  } = useMemo(() => {
    if (!auctionOffersSubgraphData) {
      return {}
    }

    const groupedOffersByChain: {
      [chainId: string]: { [strategy: Address]: VaultHolding[] }
    } = {}

    Object.entries(auctionOffersSubgraphData).forEach(([chainId, result]) => {
      if (!groupedOffersByChain[chainId]) {
        groupedOffersByChain[chainId] = {}
      }

      groupedOffersByChain[chainId] = result?.termOffers?.reduce(
        (acc, offer) => {
          const repoTokenId = offer.auction.term.termRepoTokenMeta?.id ?? ''
          const vaultAddress = offer.offeror

          // If we have not seen this vaultAddress yet, create a single VaultHolding entry
          if (!acc[vaultAddress]) {
            acc[vaultAddress] = [
              {
                chainId,
                isOpenOffer: true,
                repoToken: repoTokenId,
                repoTokenCurrency: {
                  address: repoTokenId,
                  symbol: offer.auction.term.termRepoTokenMeta?.symbol ?? '',
                  decimals:
                    offer.auction.term.termRepoTokenMeta?.decimals ?? 18,
                  isRepoToken: true,
                },
                presentValue: FixedNumber.fromString('0', 18),
                faceValue: FixedNumber.fromString('0', 18),
                presentValueUsd: FixedNumber.fromString('0', 18),
                repurchaseTimestamp: offer.auction.term.repurchaseTimestamp,
                redemptionTimestamp: offer.auction.term.redemptionTimestamp,
                collateralTokens:
                  offer.auction.term.collateralTokensMeta?.map(
                    (collateralTokenMeta) => {
                      return {
                        address: collateralTokenMeta.id,
                        symbol: collateralTokenMeta.symbol,
                        decimals: collateralTokenMeta.decimals,
                        isRepoToken: false,
                      } as Currency
                    }
                  ) ?? [],
                auctionClearingPrice: FixedNumber.fromString('0', 18),
                distributionRatio: FixedNumber.fromString('0', 18),
              },
            ]
          }

          const holding = acc[vaultAddress][0]
          holding.presentValue = add(
            holding.presentValue,
            bigToFixedNumber(
              offer.amount,
              offer.auction.term.purchaseTokenMeta?.decimals
            )
          )
          holding.faceValue = add(
            holding.faceValue,
            bigToFixedNumber(
              offer.amount,
              offer.auction.term.purchaseTokenMeta?.decimals
            )
          )

          return acc
        },
        {} as { [vaultAddress: Address]: VaultHolding[] }
      )
    })

    return groupedOffersByChain
  }, [auctionOffersSubgraphData])

  // #endregion

  // get estimated apy per vault:
  // #region
  const estimatedSingleStrategyAPY = useMemo(() => {
    if (!repoTokenHoldingsWithValueByChain) {
      return undefined
    }

    let liquidBalanceApy: {
      [chainId: string]: { [strategyAddress: Address]: FixedNumber }
    } = {}
    let repoTokenHoldingsApy: {
      [chainId: string]: { [strategyAddress: Address]: FixedNumber }
    } = {}

    Object.entries(singleStrategyTotalAssetValue).forEach(
      ([chainId, totalAssetValueByStrategy]) => {
        if (!totalAssetValueByStrategy) return
        Object.entries(totalAssetValueByStrategy).forEach(
          ([strategyAddress, totalAssetValue]) => {
            if (!totalAssetValue) return

            const liquidBalance =
              singleStrategyTotalLiquidBalance?.[chainId]?.[strategyAddress]
            if (liquidBalance) {
              if (!liquidBalanceApy[chainId]) {
                liquidBalanceApy[chainId] = {}
              }
              // include open offers in liquid balance
              const openOffersTotal = openAuctionOffersWithoutPriceByVault?.[
                chainId
              ]?.[strategyAddress]?.reduce(
                (acc, offer) => add(acc, offer.presentValue),
                FixedNumber.fromString('0', `fixed128x18`)
              )

              const vaultMapping =
                vaultsMapping?.vaultDetails?.[chainId]?.[strategyAddress]
              if (!vaultMapping?.idleCapitalDeployed) return

              const liquidDistributionRatio = divide(
                add(
                  liquidBalance,
                  openOffersTotal ?? FixedNumber.fromString('0', 'fixed128x18')
                ),
                totalAssetValue
              )
              const idleVaultRate =
                mappedIdleVaultRates?.[vaultMapping.idleCapitalDeployed]
              const idleVaultSupplyFN = divide(
                FixedNumber.fromString(idleVaultRate ?? '0', `fixed128x18`),
                FixedNumber.fromString('100', `fixed128x18`)
              )

              liquidBalanceApy[chainId][strategyAddress] = multiply(
                liquidDistributionRatio,
                idleVaultSupplyFN
              )
            }
          }
        )
      }
    )

    Object.entries(repoTokenHoldingsWithValueByChain).forEach(
      ([chainId, holdingsByVault]) => {
        if (!holdingsByVault) return
        Object.entries(holdingsByVault).forEach(
          ([strategyAddress, repoTokenHoldings]) => {
            if (!repoTokenHoldings) return
            const totalAssetValue =
              singleStrategyTotalAssetValue?.[chainId]?.[strategyAddress]
            if (!totalAssetValue) return

            if (!repoTokenHoldingsApy[chainId]) {
              repoTokenHoldingsApy[chainId] = {}
            }

            if (!repoTokenHoldingsApy[chainId][strategyAddress]) {
              repoTokenHoldingsApy[chainId][strategyAddress] =
                FixedNumber.fromString('0', `fixed128x18`)
            }

            Object.values(repoTokenHoldings).forEach((holding) => {
              const repoTokenData =
                repoTokensByChain?.[chainId]?.[holding.repoToken]
              if (!repoTokenData) return

              const holdingValueFN = bigToFixedNumber(
                holding.presentValue,
                repoTokenData.decimals
              )

              const distributionRatio =
                !holding.presentValue.isZero() && !totalAssetValue?.isZero()
                  ? divide(holdingValueFN, totalAssetValue)
                  : FixedNumber.fromString('0', `fixed128x18`)

              repoTokenHoldingsApy[chainId][strategyAddress] = add(
                repoTokenHoldingsApy[chainId][strategyAddress],
                multiply(distributionRatio, repoTokenData.auctionClearingPrice)
              )
            })
          }
        )
      }
    )

    const totalApy: {
      [chainId: string]: { [strategyAddress: string]: FixedNumber }
    } = {}

    const chainIds = new Set([
      ...Object.keys(liquidBalanceApy),
      ...Object.keys(repoTokenHoldingsApy),
    ])

    for (const chainId of chainIds) {
      totalApy[chainId] = {}

      // Grab all strategies in both objects
      const strategyAddresses = new Set([
        ...Object.keys(liquidBalanceApy[chainId] ?? {}),
        ...Object.keys(repoTokenHoldingsApy[chainId] ?? {}),
      ])

      for (const strategyAddress of strategyAddresses) {
        const lbApy =
          liquidBalanceApy[chainId]?.[strategyAddress] ??
          FixedNumber.fromString('0', 'fixed128x18')

        const repoApy =
          repoTokenHoldingsApy[chainId]?.[strategyAddress] ??
          FixedNumber.fromString('0', 'fixed128x18')

        const performanceFee =
          singleStrategyPerformanceFees?.[chainId]?.[strategyAddress] ??
          FixedNumber.fromString('0', 'fixed128x18')

        // subtract performance fee
        totalApy[chainId][strategyAddress] = multiply(
          add(lbApy, repoApy),
          subtract(FixedNumber.fromString('100', `fixed128x18`), performanceFee)
        )
      }
    }

    return totalApy
  }, [
    mappedIdleVaultRates,
    openAuctionOffersWithoutPriceByVault,
    repoTokenHoldingsWithValueByChain,
    repoTokensByChain,
    singleStrategyPerformanceFees,
    singleStrategyTotalAssetValue,
    singleStrategyTotalLiquidBalance,
    vaultsMapping?.vaultDetails,
  ])

  // #endregion

  const flattenedMetaVaultAddresses = useMemo(
    () => [metaVaultAddress],
    [metaVaultAddress]
  )
  const { assetBalances: metaVaultAssetBalances } =
    useMetaVaultSingleStrategyBalances(
      flattenedMetaVaultAddresses,
      singleStrategyAddressesByChain,
      provider
    )

  const estimatedMultiStrategyAPY = useMemo(() => {
    if (!metaVaultAssetBalances || !multiStrategyTotalAssetValue)
      return undefined

    const result: {
      [chainId: string]: {
        [multiStrategyAddress: string]: FixedNumber
      }
    } = {}

    Object.entries(metaVaultAssetBalances).forEach(
      ([chainId, balancesByStrategy]) => {
        if (!balancesByStrategy) return

        result[chainId] = Object.entries(balancesByStrategy).reduce(
          (acc, [metaVaultAddress, balances]) => {
            if (!balances) return acc

            acc[metaVaultAddress] = Object.entries(balances).reduce(
              (acc, [strategyAddress, balance]) => {
                if (
                  !balance ||
                  !estimatedSingleStrategyAPY?.[chainId]?.[strategyAddress]
                )
                  return acc
                const distributionRatio = multiply(
                  estimatedSingleStrategyAPY?.[chainId]?.[strategyAddress],
                  divide(balance.balance, multiStrategyTotalAssetValue)
                )
                return add(acc, distributionRatio)
              },
              FixedNumber.fromString('0', `fixed128x18`)
            )

            return acc
          },
          {} as { [multiStrategyAddress: string]: FixedNumber }
        )
      }
    )

    return result
  }, [
    metaVaultAssetBalances,
    estimatedSingleStrategyAPY,
    multiStrategyTotalAssetValue,
  ])

  const estimatedAPYs = mergeAPYData(
    estimatedSingleStrategyAPY,
    estimatedMultiStrategyAPY
  )

  // get metavault balance in all strategies
  // this is the receipt token
  // use convertToAssets to get the asset token
  // get prices for asset token
  // convert from asset token to USD

  const mappedSingleStrategiesCurrencyInput = useMemo(() => {
    if (!singleStrategyAddressesByChain) return undefined
    return {
      [chainId]: (singleStrategyAddressesByChain[chainId] ?? []).map(
        (strategyAddress) => {
          return {
            address: strategyAddress,
            version: 'x',
            isRepoToken: false,
          }
        }
      ),
    }
  }, [singleStrategyAddressesByChain, chainId])

  const singleStrategyReceiptCurrenciesByChain = useCurrencies(
    mappedSingleStrategiesCurrencyInput,
    provider
  )

  const mappedSingleStrategiesReceiptCurrenciesByAddress = useMemo(() => {
    if (
      !singleStrategyReceiptCurrenciesByChain ||
      !mappedSingleStrategiesCurrencyInput
    )
      return undefined

    const singleStrategyCurrencies =
      singleStrategyReceiptCurrenciesByChain[chainId]
    const singleStrategyAddresses = mappedSingleStrategiesCurrencyInput[chainId]

    return {
      [chainId]: Object.fromEntries(
        singleStrategyAddresses.map((address, index) => {
          const currency = singleStrategyCurrencies[index]
          return [address.address, currency]
        })
      ),
    }
  }, [
    singleStrategyReceiptCurrenciesByChain,
    mappedSingleStrategiesCurrencyInput,
    chainId,
  ])

  const singleStrategyReceiptBalancesByChain = useBalances(
    metaVaultAddress,
    mappedSingleStrategiesReceiptCurrenciesByAddress,
    provider
  )

  const metaVaultSingleStrategiesReceiptBalances = useMemo(() => {
    if (!singleStrategyReceiptBalancesByChain) return undefined

    const singleStrategyBalances = singleStrategyReceiptBalancesByChain[chainId]

    return Object.fromEntries(
      singleStrategyBalances.map((balance) => {
        return [balance.address, balance.balance]
      })
    ) as { [singleStrategyAddress: string]: FixedNumber }
  }, [singleStrategyReceiptBalancesByChain, chainId])

  const singleStrategyBalancesToConvertInput = useMemo(() => {
    if (!metaVaultSingleStrategiesReceiptBalances || !metaVaultAssetCurrency)
      return undefined

    return {
      [chainId]: Object.entries(metaVaultSingleStrategiesReceiptBalances).map(
        ([address, balance]) => {
          return {
            strategyAddress: address,
            shares: balance,
            assetCurrency: metaVaultAssetCurrency,
          }
        }
      ),
    }
  }, [
    metaVaultSingleStrategiesReceiptBalances,
    metaVaultAssetCurrency,
    chainId,
  ])

  const { assetBalances: metaVaultSingleStrategiesAssetBalances } =
    useConvertToAssets(singleStrategyBalancesToConvertInput, provider)

  // get current user meta vault balance

  const mappedMultiStrategyCurrencyInput = useMemo(() => {
    if (!metaVaultAddress) return undefined
    return {
      [chainId]: [
        {
          address: metaVaultAddress,
          version: 'x',
          isRepoToken: false,
        },
      ],
    }
  }, [metaVaultAddress, chainId])

  const multiStrategyReceiptCurrencyByChain = useCurrencies(
    mappedMultiStrategyCurrencyInput,
    provider
  )

  const {
    mappedMultiStrategyReceiptCurrencyByAddress,
    mappedMultiStrategyAssetCurrencyByAddress,
  } = useMemo(() => {
    if (
      !multiStrategyReceiptCurrencyByChain ||
      !mappedMultiStrategyCurrencyInput ||
      !metaVaultAssetCurrency
    )
      return {}

    return {
      mappedMultiStrategyReceiptCurrencyByAddress: {
        [chainId]: {
          [metaVaultAddress]: multiStrategyReceiptCurrencyByChain[chainId][0],
        },
      } as { [chainId: string]: { [metaVaultAddress: Address]: Currency } },
      mappedMultiStrategyAssetCurrencyByAddress: {
        [chainId]: {
          [metaVaultAssetCurrency.address]: metaVaultAssetCurrency,
        },
      } as { [chainId: string]: { [metaVaultAddress: Address]: Currency } },
    }
  }, [
    multiStrategyReceiptCurrencyByChain,
    mappedMultiStrategyCurrencyInput,
    metaVaultAssetCurrency,
    chainId,
    metaVaultAddress,
  ])

  const connectedWalletReceiptBalanceByChain = useBalances(
    account,
    mappedMultiStrategyReceiptCurrencyByAddress,
    provider
  )

  const connectedWalletAssetBalanceByChain = useBalances(
    account,
    mappedMultiStrategyAssetCurrencyByAddress,
    provider
  )

  const multiStrategyBalanceToConvertInput = useMemo(() => {
    if (
      !connectedWalletReceiptBalanceByChain ||
      !metaVaultAddress ||
      !metaVaultAssetCurrency
    )
      return undefined

    return {
      [chainId]: [
        {
          strategyAddress: metaVaultAddress,
          shares: connectedWalletReceiptBalanceByChain?.[chainId]?.[0]?.balance,
          assetCurrency: metaVaultAssetCurrency,
        },
      ],
    }
  }, [
    connectedWalletReceiptBalanceByChain,
    metaVaultAddress,
    metaVaultAssetCurrency,
    chainId,
  ])

  const { assetBalances: connectedWalletConvertedAssetBalanceByChain } =
    useConvertToAssets(multiStrategyBalanceToConvertInput, provider)

  const pricesInput = useMemo(() => {
    if (!metaVaultAssetCurrency) return undefined

    return {
      [chainId]: [
        {
          address: metaVaultAssetCurrency.address,
          decimals: metaVaultAssetCurrency.decimals,
        },
      ],
    }
  }, [metaVaultAssetCurrency, chainId])

  const pricesData = usePrices(pricesInput, undefined, provider)

  const metaVaultAssetPriceFN = useMemo(() => {
    if (!pricesData || !pricesData[chainId]?.[0]) {
      return undefined
    } else {
      return bigToFixedNumber(
        pricesData[chainId][0].price,
        pricesData[chainId][0].decimals
      )
    }
  }, [pricesData, chainId])

  const gasTokenCurrency = useMemo(
    () => chainData.nativeCurrency,
    [chainData.nativeCurrency]
  )

  const gasTokenBalance = useNativeTokenBalance(
    account,
    gasTokenCurrency,
    parsedChainId
  )

  const { wrapGasToken: wrapGasTokenCall } = useTokenWrapper(
    chainData.chainId,
    chainData.contracts.wrappedGasToken,
    provider
  )

  // approvals:
  const metaVaultAssetApprove = useTokenApprove(
    signer,
    metaVaultAssetCurrency,
    parsedChainId
  )

  const metaVaultAssetTokenAllowance = useMemo(() => {
    if (!account || !metaVaultAddress || !metaVaultAssetCurrency) {
      return undefined
    }
    const allowances = [
      {
        token: metaVaultAssetCurrency.address,
        spender: metaVaultAddress,
        owner: account,
        termId: 'x',
      },
    ]

    return {
      [chainId]: allowances,
    }
  }, [account, metaVaultAddress, metaVaultAssetCurrency, chainId])

  const allowances = useAllowances(metaVaultAssetTokenAllowance, provider)
  const metaVaultAssetAllowance = useMemo(() => {
    return allowances?.[chainId]?.['x']?.[0] ?? BigNumber.from(0)
  }, [allowances, chainId])

  const {
    metaVault,
    singleVaults,
    totalDeposited,
    userDepositedAssetCurrency,
    multiStrategyCurrentYield,
    metaVaultStrategyAllocations,
    metaVaultDeposit,
    metaVaultWithdraw,
  } = composeMetaVaultPageViewModel(
    chainId,
    metaVaultAddress,
    vaultsMapping,
    rawVaults,
    mappedSentioResults,
    singleStrategyCurrentPricePerShare,
    singleStrategyTotalAssetValue,
    multiStrategyCurrentPricePerShare,
    multiStrategyTotalAssetValue,
    metaVaultSingleStrategiesAssetBalances?.[chainId],
    connectedWalletConvertedAssetBalanceByChain?.[chainId],
    multiStrategyIsShutdown,
    multiStrategyAvailableDepositLimit,
    multiStrategyAvailableWithdrawLimit,
    estimatedAPYs,
    deposit,
    withdraw
  )

  return useMemo(
    () =>
      ({
        isDataLoaded: true, // TODO: wire this up properly
        account,
        chainId,

        metaVaultDetails: metaVault,
        individualVaultDetails: singleVaults,
        metaVaultStrategyAllocations,

        totalDeposited,
        connectedWalletDeposits: userDepositedAssetCurrency,
        singleVaultPricePerShareMap: singleStrategyCurrentPricePerShare,
        metaVaultPricePerShare: multiStrategyCurrentPricePerShare,
        metaVaultConvertToAssetsRatio: convertToAssetsRatio,
        metaVaultCurrentYield: multiStrategyCurrentYield,

        connectedWalletAssetAllowance: metaVaultAssetAllowance,
        metaVaultAssetPrice: metaVaultAssetPriceFN,
        metaVaultReceiptCurrency:
          multiStrategyReceiptCurrencyByChain?.[chainId]?.[0],
        connectedWalletMetaVaultReceiptBalance:
          connectedWalletReceiptBalanceByChain?.[chainId]?.[0]?.balance ??
          FixedNumber.fromString('0', `fixed128x18`),
        connectedWalletAssetBalance:
          connectedWalletAssetBalanceByChain?.[chainId]?.[0]?.balance ??
          FixedNumber.fromString('0', `fixed128x18`),
        gasTokenBalance:
          gasTokenBalance?.balance ?? FixedNumber.fromString('0', 18),
        gasTokenCurrency,
        availableDepositLimit: multiStrategyAvailableDepositLimit,
        availableWithdrawLimit: multiStrategyAvailableWithdrawLimit,

        isShutdown: multiStrategyIsShutdown,

        hasHistoricalPerformance: false,
        historicalPerformanceDataChart: [],
        setSelectedTime: () => {},
        selectedTime: 0,

        onConnect,
        onKytCheck,
        onApproveMetaVaultAsset: async (amount?: string) => {
          await onCheckActiveNetwork(chainData.chainId, chainData.chainName)
          await metaVaultAssetApprove(
            metaVaultAddress,
            amount || config.approveAmount
          )
        },
        onMetaVaultDeposit: async (amount: string) => {
          if (!account) {
            throw new Error('Account is undefined')
          }
          if (!metaVaultDeposit) {
            throw new Error('Deposit function is undefined')
          }
          await onCheckActiveNetwork(chainData.chainId, chainData.chainName)
          await metaVaultDeposit(
            chainId,
            FixedNumber.fromString(
              amount,
              `fixed128x${metaVaultAssetCurrency?.decimals}`
            )
          )
        },
        onMetaVaultWithdraw: async (amount: string, maxLoss?: number) => {
          if (!account) {
            throw new Error('Account is undefined')
          }
          if (!metaVaultWithdraw) {
            throw new Error('Deposit function is undefined')
          }
          await onCheckActiveNetwork(chainData.chainId, chainData.chainName)
          await metaVaultWithdraw(
            chainId,
            FixedNumber.fromString(
              amount,
              `fixed128x${metaVaultAssetCurrency?.decimals}`
            ),
            maxLoss ? FixedNumber.fromString(maxLoss.toString(), 18) : undefined
          )
        },
        onWrapGasToken: wrapGasTokenCall,

        onViewSingleVault: (vaultAddress: string, chainId: string) =>
          navigate(`/vaults/${vaultAddress}/${chainId}`, {
            state: { metaVault: metaVault },
          }),
        onViewVaults: () => navigate(`/vaults`),
      }) as MetaVaultPageParams,
    [
      account,
      chainId,
      config.approveAmount,
      chainData,
      metaVault,
      metaVaultAddress,
      singleVaults,
      metaVaultStrategyAllocations,
      totalDeposited,
      userDepositedAssetCurrency,
      metaVaultAssetAllowance,
      gasTokenCurrency,
      gasTokenBalance,
      metaVaultAssetPriceFN,
      metaVaultAssetCurrency,
      convertToAssetsRatio,
      metaVaultAssetApprove,
      multiStrategyCurrentYield,
      singleStrategyCurrentPricePerShare,
      multiStrategyCurrentPricePerShare,
      multiStrategyIsShutdown,
      multiStrategyReceiptCurrencyByChain,
      multiStrategyAvailableDepositLimit,
      multiStrategyAvailableWithdrawLimit,
      connectedWalletReceiptBalanceByChain,
      connectedWalletAssetBalanceByChain,
      metaVaultDeposit,
      metaVaultWithdraw,
      wrapGasTokenCall,
      onCheckActiveNetwork,
      onConnect,
      onKytCheck,
      navigate,
    ]
  )
}
