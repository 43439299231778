import {
  Box,
  Circle,
  Flex,
  HStack,
  Image,
  Link,
  Skeleton,
  Text,
  VStack,
} from '@chakra-ui/react'
import { useEffect, useRef, useState } from 'react'

import sparkle_icon from '../../../../assets/icons/sparkle-alt.svg'
import yearnLogo from '../../../../assets/icons/projects/yearn.svg'

import { FixedNumber } from 'ethers'
import { ArrowUpRight } from '../../../../components/elements/Icons'
import { fixedToFormattedPercentage } from '../../../../helpers/conversions'
import { shortenAddress } from '../../../../helpers/utils'
import { useChainConfig } from '../../../../providers/config'
import { Currency } from '../../../../data/model'
import { useNetworkWhiteIcons } from '../../../../data/hooks/use-network-icons'
import Tooltip from '../../../../components/elements/Tooltip'
import VaultCuratorIcon from '../../../../components/elements/VaultCuratorIcon'
import BannerStat from './BannerStat'
import VaultTokenIcon from './VaultTokenIcon'

type Props = {
  currentYield?: FixedNumber
  pricePerShare: FixedNumber
  totalAssetValue: FixedNumber

  vaultAssetCurrency: Currency
  vaultReceiptCurrency: Currency
  vaultReceiptBalance: FixedNumber

  vaultCurator: string
  vaultName: string
  curatorIcon?: string
  vaultAddress: string
  chainId: string | number
  // TODO: check this type VaultType
  vaultType: string
  isDataLoaded?: boolean
}

export default function Banner({
  currentYield,
  pricePerShare,
  totalAssetValue,

  vaultAssetCurrency,
  vaultReceiptCurrency,
  vaultReceiptBalance,

  vaultCurator,
  vaultName,
  curatorIcon,
  vaultAddress,
  chainId,
  vaultType,
  isDataLoaded = true,
}: Props) {
  const chainConfig = useChainConfig(chainId)
  const networkIcon = useNetworkWhiteIcons(chainId)

  let formattedYieldPercentage: string | undefined
  if (currentYield) {
    formattedYieldPercentage = fixedToFormattedPercentage(
      currentYield,
      2,
      true
    ).formattedPercentage
  }

  const textRef = useRef<HTMLDivElement>(null)
  const [isVaultNameTruncated, setIsVaultNameTruncated] = useState(false)

  useEffect(() => {
    if (textRef.current) {
      const { scrollWidth, offsetWidth } = textRef.current
      setIsVaultNameTruncated(scrollWidth > offsetWidth)
    }
  }, [vaultCurator, vaultAssetCurrency])

  return (
    <Flex
      w="full"
      bg="blue.10"
      borderRadius="6px"
      border="0px solid gray.2"
      color="#fff"
      overflow="hidden"
    >
      <HStack
        p={6}
        pr={{ base: 0, xl: 6 }}
        spacing={{
          base: '10px',
          '2xl': '40px',
        }}
        flexGrow={1}
      >
        {/* Current Yield */}
        <VStack
          spacing={3}
          align="start"
          w={{ base: 'full', xl: '150px' }}
          maxWidth="150px"
          mt={1}
          flexGrow={1}
        >
          <Text
            fontSize="16px"
            lineHeight="20px"
            fontWeight="500"
            whiteSpace="nowrap"
          >
            Current Yield
          </Text>
          <Skeleton isLoaded={isDataLoaded} h="32px" w="full">
            {!currentYield ? (
              <HStack gap={{ base: 0, xl: 2 }}>
                <Text
                  fontSize={{ base: '24px', xl: '32px' }}
                  lineHeight="32px"
                  fontWeight="500"
                >
                  N/A
                </Text>
                <Tooltip
                  noDelay
                  placement="bottom"
                  label={'More data needed to display yield'}
                >
                  <Image boxSize={6} src={sparkle_icon} />
                </Tooltip>
              </HStack>
            ) : (
              <HStack gap={{ base: 0, xl: 2 }}>
                <Text fontSize="32px" lineHeight="32px" fontWeight="500">
                  {formattedYieldPercentage}%
                </Text>
                <Tooltip
                  noDelay
                  placement="bottom"
                  label={
                    <VStack
                      alignItems="flex-start"
                      w="full"
                      color="white"
                      gap={2}
                    >
                      <Text
                        as="span"
                        variant="body-sm/normal"
                        color="white"
                        textAlign="left"
                      >
                        {`Additional 1.5x Term Points / dollar deposited.`}
                      </Text>
                      <Text
                        as="span"
                        variant="body-sm/normal"
                        color="white"
                        textAlign="left"
                      >
                        {`Estimated yield based on Aave yield for ${vaultAssetCurrency.symbol} plus yield on Term repo tokens held by vault.`}
                      </Text>
                    </VStack>
                  }
                >
                  <Image boxSize="24px" src={sparkle_icon} alt={'Fixed APY'} />
                </Tooltip>
              </HStack>
            )}
          </Skeleton>
        </VStack>
        {/* Stats */}
        <BannerStat
          title="Total Asset Value"
          value={totalAssetValue}
          symbol={vaultAssetCurrency.symbol}
          isDataLoaded={isDataLoaded}
        />
        <BannerStat
          title="Price Per Share"
          value={pricePerShare}
          symbol={vaultAssetCurrency.symbol}
          isDataLoaded={isDataLoaded}
          maxW="110px"
        />
        <BannerStat
          title="Your Holdings"
          value={vaultReceiptBalance}
          symbol={vaultReceiptCurrency.symbol}
          overrideSymbol={vaultAssetCurrency.symbol}
          isDataLoaded={isDataLoaded}
        />
      </HStack>
      {/* Vault Info */}
      <HStack justifyContent="end" gap="16px">
        <VStack spacing={3} align="start">
          <Skeleton isLoaded={isDataLoaded} h="40px" w="full">
            <HStack spacing={3}>
              <VaultCuratorIcon
                boxSize="24px"
                vaultName={curatorIcon ?? 'unknown'}
              />
              <Tooltip
                noDelay
                label={isVaultNameTruncated ? vaultName : undefined}
              >
                <Text
                  ref={textRef}
                  variant="body-4xl/normal"
                  // noOfLines={1}
                  whiteSpace="nowrap"
                  overflow="hidden"
                  textOverflow="ellipsis"
                  maxW="min(330px, 25dvw)"
                  lineHeight="normal"
                >
                  {vaultName}
                  {/* {vaultCurator} {vaultAssetCurrency.symbol} Vault */}
                </Text>
              </Tooltip>
            </HStack>
          </Skeleton>
          <HStack
            color="white"
            opacity={0.7}
            spacing={1}
            w="full"
            justifyContent="end"
          >
            {/* Strategy Address */}
            <Skeleton isLoaded={isDataLoaded} h="22px" w="90px">
              <Box w="90px">
                <Link
                  href={chainConfig?.getExplorerAddressLink(vaultAddress)}
                  isExternal
                >
                  <HStack spacing="4px">
                    <Text
                      variant="body-sm/medium"
                      color="white"
                      opacity={0.7}
                      noOfLines={1}
                    >
                      {shortenAddress(vaultAddress, {
                        charsFront: 2,
                        charsBack: 2,
                      })}
                    </Text>
                    <ArrowUpRight color="white" opacity={0.5} h={4} />
                  </HStack>
                </Link>
              </Box>
            </Skeleton>

            <Circle bg="white" opacity={0.7} size="2px" />

            {/* Chain Name */}
            <Skeleton isLoaded={isDataLoaded} h="22px" w="80px">
              <HStack spacing="4px" w="80px">
                <Text variant="body-sm/medium" noOfLines={1}>
                  {chainConfig?.chainName}
                </Text>
                <Image src={networkIcon} w="16px" h="16px" opacity={0.7} />
              </HStack>
            </Skeleton>

            <Circle bg="white" opacity={0.7} size="2px" />

            {/* Vault Type */}
            <Skeleton isLoaded={isDataLoaded} h="22px" w="85px">
              <HStack spacing="4px" w="85px">
                <Text variant="body-sm/medium" noOfLines={1}>
                  {vaultType}
                </Text>
                <Image src={yearnLogo} w="16px" h="16px" opacity={0.7} />
              </HStack>
            </Skeleton>
          </HStack>
        </VStack>
        <Skeleton
          isLoaded={isDataLoaded}
          h="full"
          borderTopLeftRadius="0"
          borderBottomLeftRadius="0"
        >
          <VaultTokenIcon symbol={vaultAssetCurrency.symbol} />
        </Skeleton>
      </HStack>
    </Flex>
  )
}
