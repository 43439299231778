import { Image, ImageProps } from '@chakra-ui/react'
import { useKolReferrerIcon } from '../../../data/hooks/use-kol-referrer-icon'

export default function KolReferrerIcon({
  kol,
  boxSize = '16px',
  imageProps,
}: {
  kol: string
  boxSize?: string
  imageProps?: ImageProps
}) {
  const src = useKolReferrerIcon(kol)
  return <Image boxSize={boxSize} src={src} alt={kol} {...imageProps} />
}
