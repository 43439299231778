import { Box, Text } from '@chakra-ui/react'
import {
  Table,
  TableBody,
  TableHead,
  Td,
  Th,
  Tr,
} from '../../../../components/elements/Table'
import Chip from '../../../../components/elements/Chip'
import { FixedNumber } from 'ethers'
import { ChevronDownIcon } from '@chakra-ui/icons'
import VaultHoldingsRow from './VaultHoldingsRow'
import { AssetVault, VaultHolding } from '../../../../models/vault'
import { useChainConfigs } from '../../../../providers/config'

type Props = {
  assetVault: AssetVault
  holdings: VaultHolding[]
  openAuctionOffers: VaultHolding[]
  idleVaultRate?: string
  vaultAssetToken: string
  vaultReceiptToken: string
  totalLiquidBalance: FixedNumber
  totalLiquidBalanceDistribution: FixedNumber
}

const SortArrow = ({ selected }: { selected?: boolean }) => (
  <ChevronDownIcon
    color={selected ? 'gray.6' : 'gray.4'}
    ml="4px"
    h="16px"
    w="16px"
  />
)

export const columnWidths = {
  asset: 218,
  maturity: 116,
  collateral: 120,
  redeemIn: 105,
  faceValue: 155,
  presentValue: 185,
  interest: 100,
  incentives: 100,
  distribution: 100,
}

export default function VaultHoldings({
  assetVault,
  holdings,
  openAuctionOffers,
  idleVaultRate,
  vaultAssetToken,
  vaultReceiptToken,
  totalLiquidBalance,
  totalLiquidBalanceDistribution,
}: Props) {
  const chainConfigs = useChainConfigs()
  return (
    <Box>
      <Table variant="striped">
        <TableHead>
          <Tr>
            <Th w={`${columnWidths.asset}px`}>
              Asset
              <SortArrow />
            </Th>
            <Th w={`${columnWidths.maturity}px`}>
              Maturity
              <SortArrow selected />
            </Th>
            <Th w={`${columnWidths.collateral}px`}>
              Collateral
              <SortArrow />
            </Th>
            <Th w={`${columnWidths.redeemIn}px`}>
              Redeem In
              <SortArrow />
            </Th>
            <Th justifyChildren="end" w={`${columnWidths.faceValue}px`}>
              Face Value
              <Box as="span" ml="4px">
                <Chip>{vaultAssetToken}</Chip>
              </Box>
              <SortArrow />
            </Th>
            <Th justifyChildren="end" w={`${columnWidths.presentValue}px`}>
              Present Value
              <SortArrow />
            </Th>
            <Th justifyChildren="end" w={`${columnWidths.interest}px`}>
              Interest
              <SortArrow />
            </Th>
            <Th justifyChildren="end" w={`${columnWidths.incentives}px`}>
              Incentives
              <SortArrow />
            </Th>
            <Th justifyChildren="end" w={`${columnWidths.distribution}px`}>
              Distribution
              <SortArrow />
            </Th>
          </Tr>
        </TableHead>
        <TableBody>
          {/* Liquid Row */}
          <VaultHoldingsRow
            key="liquid"
            isLiquid
            tokenSymbol={assetVault.mapping.tokenIcon}
            tokenLabel={
              assetVault.mapping.tokenSymbol ?? assetVault.currency.symbol
            }
            collateralOrLiquidSymbol={
              assetVault.mapping.protocolIcon ?? 'yearn'
            }
            faceValue={totalLiquidBalance}
            presentValue={totalLiquidBalance}
            interest={FixedNumber.fromString('0')}
            distributionRatio={totalLiquidBalanceDistribution}
            idleVaultRate={idleVaultRate}
            idleVaultUrl={assetVault.mapping.link}
          />
          {/* Open Auction Offers */}
          {openAuctionOffers.length > 0 &&
            openAuctionOffers.map((offer, idx) => {
              return (
                <VaultHoldingsRow
                  key={idx}
                  isOpenAuctionOffer
                  tokenSymbol={'TERM'}
                  tokenLabel={offer.repoTokenCurrency.symbol}
                  collateralOrLiquidSymbol={
                    offer.collateralTokens?.[0]?.symbol ?? ''
                  }
                  maturityTimestamp={offer.repurchaseTimestamp}
                  redemptionTimestamp={offer.redemptionTimestamp}
                  faceValue={offer.faceValue}
                  presentValue={offer.presentValue}
                  interest={FixedNumber.fromString('0')}
                  distributionRatio={offer.distributionRatio}
                  holdingUrl={
                    offer?.auction
                      ? `/auctions/${offer?.auction}/${offer.chainId}`
                      : undefined
                  }
                />
              )
            })}
          {/* Vault Repo Token Holdings */}
          {holdings.length > 0 ? (
            holdings.map((holding, idx) => {
              const url = chainConfigs
                ?.find((c) => c.chainId === Number(holding.chainId))
                ?.getExplorerAddressLink(holding.repoToken)
              return (
                <VaultHoldingsRow
                  key={idx}
                  tokenSymbol={'TERM'}
                  tokenLabel={holding.repoTokenCurrency.symbol}
                  collateralOrLiquidSymbol={
                    holding.collateralTokens?.[0]?.symbol ?? ''
                  }
                  maturityTimestamp={holding.repurchaseTimestamp}
                  redemptionTimestamp={holding.redemptionTimestamp}
                  faceValue={holding.faceValue}
                  presentValue={holding.presentValue}
                  interest={holding.auctionClearingPrice}
                  distributionRatio={holding.distributionRatio}
                  holdingUrl={url}
                />
              )
            })
          ) : (
            <Tr>
              <Td colSpan={9} textAlign="center">
                <Text variant="body-sm/normal">No repo token holdings</Text>
              </Td>
            </Tr>
          )}
        </TableBody>
      </Table>
    </Box>
  )
}
